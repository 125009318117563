import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from "connected-react-router";
import { fromJS } from 'immutable';
import thunk from 'redux-thunk';
import createReducer from './reducers';
import { ENVIRONMENT } from "../constants/common-constant";

export default function configureStore(initialState = {}, history) {
  let composeEnhancers = compose;

  if (ENVIRONMENT !== 'PROD' && typeof window === 'object') {
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
  }

  const middleware = [thunk, routerMiddleware(history)];
  const enhancers = [applyMiddleware(...middleware)];
  const store = createStore(
    createReducer(),
    fromJS(initialState),
    composeEnhancers(...enhancers)
  );

  store.injectedReducers = {};
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers));
    });
  }

  return store;
}
