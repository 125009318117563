import { apiMethod } from "../index";
import { HTTP_METHOD } from "../../../constants/common-constant";
import { CREATE_PROJECT, GET_PROJECTS, GET_SINGLE_PROJECT, UPDATE_PROJECT, DELETE_PROJECT, ADD_SYNONYMS_TO_PROJECT, REMOVE_SYNONYMS_FROM_PROJECT,
  GET_SYNONYMS_OF_PROJECT, GET_ALL_PROJECTS
} from "../../endpoints/nlu-endpoint";

const { GET, POST, PUT, DELETE } = HTTP_METHOD;

export const createProject = data => apiMethod(POST, CREATE_PROJECT, data);

export const fetchProjects = data => apiMethod(POST, GET_PROJECTS, data);

export const fetchAllProjects = () => apiMethod(GET, GET_ALL_PROJECTS);

export const fetchOneProject = data => apiMethod(GET, GET_SINGLE_PROJECT, {
  params:{
    projectId: data.projectId,
    language: data.language
  }
});

export const updateProject = data => apiMethod(PUT, UPDATE_PROJECT, data);

export const deleteProject = data => apiMethod(DELETE, DELETE_PROJECT, {data});

export const addSynonymToProject = data => apiMethod(POST, ADD_SYNONYMS_TO_PROJECT, data);

export const fetchProjectSynonyms = data => apiMethod(POST, GET_SYNONYMS_OF_PROJECT, data);

export const removeSynonymFromProject = data => apiMethod(POST, REMOVE_SYNONYMS_FROM_PROJECT, data);