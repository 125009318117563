export const reducerName = "transliterationProject";
export const transliterationProjectState = {
  loading: false,
  singleProjectLoading: false,
  oneProject: {},
  oneProjectId: "",
  pageNumber: 1,
  pageSize: 15,
  search: "",
  sortBy:{
    key: "createdAt",
    value: "asc"
  },
  sortByKeys:{
    createdAt: "asc",
    noOfExamples: "asc",
    noOfIntents: "asc",
    noOfModels: "asc"
  },
  languages: [],
  selectedLanguage: "",
  projects: [],
  count: 0,
  allProjects: [],

  //Project synonyms
  projectSynonymLoading: false,
  projectSynonyms: [],
  projectSynonymCount: 0,
  error: null
}

export const PROJECT_LOADING = {
  type: "TRANSLITERATION_PROJECT_LOADING",
  name: "transliterationprojectLoading"
};

export const PROJECT_PARAMS_CHANGE = {
  type: "TRANSLITERATION_PROJECT_PARAMS_CHANGE",
  name: "transliterationprojectParamsChange"
}

export const PROJECT_PARAMS_CHANGE_WITHOUT_ASYNC = {
  type: "TRANSLITERATION_PROJECT_PARAMS_CHANGE_WITHOUT_ASYNC",
  name: "transliterationprojectParamsChangeWithoutAsync"
}

export const SORT_PROJECTS = {
  type: "TRANSLITERATION_SORT_PROJECTS",
  name: "transliterationsortProjects"
}

export const CREATE_PROJECT = {
  type: "TRANSLITERATION_CREATE_PROJECT",
  name: "transliterationcreateProject"
};

export const FETCH_PROJECTS = {
  type: "TRANSLITERATION_FETCH_PROJECTS",
  name: "transliterationfetchProjects"
};

export const FETCH_ALL_PROJECTS = {
  type: "TRANSLITERATION_FETCH_ALL_PROJECTS",
  name: "transliterationfetchAllProjects"
}

export const SINGLE_PROJECT = {
  type: "TRANSLITERATION_SINGLE_PROJECT",
  name: "transliterationsingleProject"
};

export const UPDATE_PROJECT = {
  type: "TRANSLITERATION_UPDATE_PROJECT",
  name: "transliterationupdateProject"
};

export const DELETE_PROJECT = {
  type: "TRANSLITERATION_DELETE_PROJECT",
  name: "transliterationdeleteProject"
};

export const ADD_SYNONYM_TO_PROJECT = {
  type: "TRANSLITERATION_ADD_SYNONYM_TO_PROJECT",
  name: "transliterationaddSynonymToProject"
};

export const FETCH_PROJECT_SYNONYMS = {
  type: "TRANSLITERATION_FETCH_PROJECT_SYNONYMS",
  name: "transliterationfetchProjectSynonyms"
};

export const REMOVE_SYNONYM_FROM_PROJECT = {
  type: "TRANSLITERATION_REMOVE_SYNONYM_FROM_PROJECT",
  name: "transliterationremoveSynonymFromProject"
};
