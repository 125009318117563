export const reducerName = "nluDashboard";
export const nluDashboardState = {
  nluDashboardGarphLoading: false,
  nluDashboardCountsLoading: false,
  nluDashboardDeployedModelsLoading: false,
  nluStatsLoading: false,
  nluExamplesPerProjectLoading: false,
  nluCountData:{},
  nluAppUsage: {},
  count: 0,
  apiCalls: [],
  deployedModels: [],
  nluStats: [],
  examplesPerProjectHistogram: []
}

export const NLU_DASHBOARD_PARAMS_CHANGE = {
  type: "NLU_DASHBOARD_PARAMS_CHANGE",
  name: "nluDashboardParamsChange"
}

export const NLU_DASHBOARD_DATA_CHANGE = {
  type: "NLU_DASHBOARD_DATA_CHANGE",
  name: "nluDashboardDataChange"
}

export const GET_NLU_DASHBOARD = {
  type: "GET_NLU_DASHBOARD",
  name: "getNluDashboard"
}

export const GET_NLU_DASHBOARD_API_COUNT = {
  type: "GET_NLU_DASHBOARD_API_COUNT",
  name: "getNluDashboardApiCount"
}

export const GET_NLU_DASHBOARD_DEPLOYED_MODELS = {
  type: "GET_NLU_DASHBOARD_DEPLOYED_MODELS",
  name: "getNluDashboardDeployedModels"
}

export const GET_NLU_DASHBOARD_STATS = {
  type: "GET_NLU_DASHBOARD_STATS",
  name: "getNluDashboardStats"
}

export const GET_NLU_DASHBOARD_EXAMPLES_PER_DATASET_HISTOGRAM = {
  type: "GET_NLU_DASHBOARD_EXAMPLES_PER_DATASET_HISTOGRAM",
  name: "getExamplesPerProjectHistogram"
}