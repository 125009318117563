import { 
  NAV_STYLE_MINI_SIDEBAR,
  LAYOUT_TYPE_FULL,
  // THEME_TYPE_SEMI_DARK,
  THEME_TYPE_LIGHT
} from "../../constants/theme-setting-constants";

export const reducerName = "settings";
export const settingsState = {
  loading: false,
  settings: {},
  globalSettings:{},
  error: null,
  isDirectionRTL: false,
  navStyle: NAV_STYLE_MINI_SIDEBAR,
  layoutType: LAYOUT_TYPE_FULL,
  themeType: THEME_TYPE_LIGHT,
  themeColor: '',
  navCollapsed: false,
  windowWidth: window.innerWidth,
  locale: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us'
  }
}

export const SETTINGS_PARAMS_CHANGE = {
  type: "SETTINGS_PARAMS_CHANGE",
  name: "dashboardParamsChange"
}

export const SETTINGS_LOADING = {
  type: "SETTINGS_LOADING",
  name: "settingsLoading"
}

export const FETCH_SETTINGS = {
  type: "FETCH_SETTINGS",
  name: "fetchSettings"
}

export const SET_WINDOW_WIDTH={
  type: "SET_WINDOW_WIDTH",
  name: "setWindowWidth"
}

export const SET_NAVCOLLAPSED={
  type: "SET_NAVCOLLAPSED",
  name: "setNavCollpased"
}