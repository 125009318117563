export const reducerName = "transliterationExample";
export const transliterationExampleState = {
  loading: false,
  exampleListLoading: false,
  oneExample: {},
  pageNumber: 1,
  pageSize: 15,
  search: "",
  examples: [],
  count: 0,
  selectedExamples: [],
  exampleIntentDistribution: [],
  selectedLanguage: "",
  selectedEntityType: "all",
  selectedEntity: "",
  exampleType: "Train",
  autoDetectEntities: false,
  phoneticTyping: false,
  //Filter states

  filterPrepared: "all",
  filterEntityType: "all",
  filterExampleType: "all",
  filterEntity: "All",
  filterIntent: "All",

  //basket item states
  basketLoading: false,
  basketEntities: [],
  basketEntitiesCount: 0,

  //create example states
  newExample: {
    text: "",
    value: ""
  },

  error: null
}

export const EXAMPLE_LOADING = {
  type: "TRANSLITERATION_EXAMPLE_LOADING",
  name: "transliterationexampleLoading"
};

export const EXAMPLE_PARAMS_CHANGE = {
  type: "TRANSLITERATION_EXAMPLE_PARAMS_CHANGE",
  name: "transliterationexampleParamsChange"
};

export const EXAMPLE_DATA_CHANGE = {
  type: "TRANSLITERATION_EXAMPLE_DATA_CHANGE",
  name: "transliterationexampleDataChange"
};

export const CREATE_EXAMPLE = {
  type: "TRANSLITERATION_CREATE_EXAMPLE",
  name: "transliterationcreateExample"
};

export const FETCH_EXAMPLES = {
  type: "TRANSLITERATION_FETCH_EXAMPLES",
  name: "transliterationfetchExamples"
};

export const SINGLE_EXAMPLE = {
  type: "TRANSLITERATION_SINGLE_EXAMPLE",
  name: "transliterationsingleExample"
};

export const UPDATE_EXAMPLE = {
  type: "TRANSLITERATION_UPDATE_EXAMPLE",
  name: "transliterationupdateExample"
};

export const UPDATE_MULTIPLE_EXAMPLE = {
  type: "TRANSLITERATION_UPDATE_MULTIPLE_EXAMPLE",
  name: "transliterationUpdateMultipleExample"
}

export const DELETE_EXAMPLE = {
  type: "TRANSLITERATION_DELETE_EXAMPLE",
  name: "transliterationDeleteExample"
}

export const DELETE_SINGLE_EXAMPLE = {
  type: "TRANSLITERATION_DELETE_SINGLE_EXAMPLE",
  name: "transliterationdeleteSingleExample"
};

export const DELETE_EXAMPLE_FEEDBACK = {
  type: "TRANSLITERATION_DELETE_EXAMPLE_FEEDBACK",
  name: "transliterationdeleteExampleFeedback"
};

export const ADD_EXAMPLE_TO_DATASET = {
  type: "TRANSLITERATION_ADD_EXAMPLE_TO_DATASET",
  name: "transliterationaddExampleToDataset"
};

