import _ from "lodash";

import { nerDashboardState, reducerName, NER_DASHBOARD_PARAMS_CHANGE, GET_NER_DASHBOARD, GET_NER_DASHBOARD_API_COUNT,
  GET_NER_DASHBOARD_DEPLOYED_MODELS,
  GET_NER_DASHBOARD_STATS,
  GET_NER_DASHBOARD_EXAMPLES_PER_DATASET_HISTOGRAM,
  NER_DASHBOARD_DATA_CHANGE
} from "../../states/ner/ner-dashboard-state";
import * as NerDashboardApi from "../../apis/ner/ner-dashboard-api";
import ReducerFactory from "../../../utils/reducer-factory";


const initialState = {...nerDashboardState};
const reducerFactory = new ReducerFactory(reducerName, initialState);

reducerFactory.addAction(NER_DASHBOARD_PARAMS_CHANGE.type, NER_DASHBOARD_PARAMS_CHANGE.name,
  async data=> data,(state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.length > 0){
      action.data.forEach(d=>{
        const { key, value } = d;
        newState[key] = value;
      });
    }  
    return newState;
  } 
);

reducerFactory.addAction(NER_DASHBOARD_DATA_CHANGE.type, NER_DASHBOARD_DATA_CHANGE.name,
  data=> data,(state,action)=>{
    const newState = _.cloneDeep(state);
    const { key, value } = action.data;
    newState[key] = value;
    return newState;
  } 
);

reducerFactory.addAction(GET_NER_DASHBOARD.type, GET_NER_DASHBOARD.name,
  async ()=>{
    reducerFactory.action(NER_DASHBOARD_PARAMS_CHANGE.name, [{key: "nerDashboardCountsLoading", value: true}]);
    const response = await NerDashboardApi.getNerDashboard();
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.nerCountData = action.data.data.counts;
      newState.nerAppUsage = action.data.data.appUsage;
    }
    newState.nerDashboardCountsLoading = false;
    return newState;
  }
);

reducerFactory.addAction(GET_NER_DASHBOARD_API_COUNT.type, GET_NER_DASHBOARD_API_COUNT.name,
  async (data)=>{
    reducerFactory.action(NER_DASHBOARD_PARAMS_CHANGE.name, [{key: "nerDashboardGarphLoading", value: true}]);
    const response = await NerDashboardApi.getNerDashboardApiCount(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.count = action.data.data.count;
      newState.apiCalls = action.data.data.apiCalls;
    }
    newState.nerDashboardGarphLoading = false;
    return newState;
  }
);

reducerFactory.addAction(GET_NER_DASHBOARD_DEPLOYED_MODELS.type, GET_NER_DASHBOARD_DEPLOYED_MODELS.name,
  async ()=>{
    reducerFactory.action(NER_DASHBOARD_PARAMS_CHANGE.name, [{key: "nerDashboardDeployedModelsLoading", value: true}]);
    const response = await NerDashboardApi.getNerDeployedModels();
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.deployedModels = action.data.data;
    }
    newState.nerDashboardDeployedModelsLoading = false;
    return newState;
  }
);

reducerFactory.addAction(GET_NER_DASHBOARD_STATS.type, GET_NER_DASHBOARD_STATS.name,
  async (data)=>{
    reducerFactory.action(NER_DASHBOARD_PARAMS_CHANGE.name, [{key: "nerStatsLoading", value: true}]);
    const response = await NerDashboardApi.getNerDashboardStats(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.nerStats = action.data.data || [];
    }
    newState.nerStatsLoading = false;
    return newState;
  }
);

reducerFactory.addAction(GET_NER_DASHBOARD_EXAMPLES_PER_DATASET_HISTOGRAM.type, GET_NER_DASHBOARD_EXAMPLES_PER_DATASET_HISTOGRAM.name,
  async ()=>{
    reducerFactory.action(NER_DASHBOARD_PARAMS_CHANGE.name, [{key: "nerExamplesPerProjectLoading", value: true}]);
    const response = await NerDashboardApi.getExamplesPerProjectHistogram();
    return response.data;
  }, (state, action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.examplesPerProjectHistogram = action.data.data || [];
    }
    newState.nerExamplesPerProjectLoading = false;
    return newState;
  }
);

export default reducerFactory;