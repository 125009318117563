import _ from "lodash";
import toastr from "toastr";
import { nluExampleState, reducerName, EXAMPLE_LOADING, EXAMPLE_PARAMS_CHANGE, INTENT_EXAMPLES, 
  FETCH_EXAMPLES, CREATE_EXAMPLE, DELETE_EXAMPLE, UPDATE_EXAMPLE, UPDATE_INTENT_IN_EXAMPLE, UPDATE_ENTITY_IN_EXAMPLE,
  CREATE_NEW_ENTITY_FROM_EXAMPLE, FETCH_BASKET_ENTITIES, DELETE_ENTITY_FROM_BASKET, UPDATE_TEXT_IN_EXAMPLE,
  DELETE_SINGLE_EXAMPLE, CREATE_NEW_INTENT_FROM_EXAMPLE, ADD_EXAMPLE_TO_DATASET, UPDATE_TYPE_IN_EXAMPLE,
  EXAMPLE_DATA_CHANGE, DELETE_EXAMPLE_FEEDBACK, UPDATE_EXAMPLE_FROM_SOCKET,
  UPDATE_MULTIPLE_EXAMPLE
} from "../../states/nlu/nlu-example-state";
import * as NluExampleApi from "../../apis/nlu/nlu-example-api";
import ReducerFactory from "../../../utils/reducer-factory";
import { getConvertExampleData } from "../../../helpers/nlu/nlu-project-helper";

const initialState = {...nluExampleState};
const reducerFactory = new ReducerFactory(reducerName, initialState);

reducerFactory.addAction(EXAMPLE_LOADING.type, EXAMPLE_LOADING.name,
  status => status, (state,action)=>{
		const newState = _.cloneDeep(state);
    newState.loading = action.data;
    return newState;
  }
);

reducerFactory.addAction(EXAMPLE_PARAMS_CHANGE.type, EXAMPLE_PARAMS_CHANGE.name,
  async data=> data,(state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.length > 0){
      action.data.forEach(d=>{
        const { key, value } = d;
        newState[key] = value;
      })
    }  
    return newState;
  } 
);

reducerFactory.addAction(EXAMPLE_DATA_CHANGE.type, EXAMPLE_DATA_CHANGE.name,
  data=> data,(state,action)=>{
    const newState = _.cloneDeep(state);
    const { key, value } = action.data;  
    newState[key] = value;
    return newState;
  } 
);

reducerFactory.addAction(UPDATE_EXAMPLE_FROM_SOCKET.type, UPDATE_EXAMPLE_FROM_SOCKET.name,
  data=> data, (state,action)=>{
    const newState = _.cloneDeep(state);
    const { exampleId } = action.data;
    const index = newState.examples.findIndex(ex=>ex.exampleId === exampleId);
    if(index !== -1){
      newState.examples[index]["prepared"] = true; 
    }
    if(exampleId === newState.oneExample.exampleId){
      newState.oneExample.prepared = true;
    }
    return newState;
  }  
);

reducerFactory.addAction(CREATE_EXAMPLE.type, CREATE_EXAMPLE.name,
  async data=>{
    const modeData = data.mode;
    delete data.mode;
    const response = await NluExampleApi.createExample(data);
    response.data["mode"] = modeData;
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.examples.unshift(action.data.data);
      action.data.data["styledExample"] = getConvertExampleData(action.data.data);
      const newExampleList = newState.examples.splice(0,newState.pageSize);
      newState.examples = newExampleList;
      newState.count++;
    }
    if(action && action.data){
      if(action.data.mode === "test"){
        toastr[action.data.success ? "success" : "warning"](action.data.message);
      }
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction(INTENT_EXAMPLES.type, INTENT_EXAMPLES.name,
  async(data)=>{
    reducerFactory.action(EXAMPLE_PARAMS_CHANGE.name, [{key: "exampleIntentDistLoading", value: true}]);
    const response = await NluExampleApi.intentExamples(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.exampleIntentDistribution = action.data.data;
    }
    newState.exampleIntentDistLoading = false;
    return newState;
  }	 
);

reducerFactory.addAction(FETCH_EXAMPLES.type, FETCH_EXAMPLES.name,
  async(data)=>{
    if(data.loadFlag){
      reducerFactory.action(EXAMPLE_PARAMS_CHANGE.name, [{key: "exampleListLoading", value: true}]);
    }
    delete data.loadFlag;
    const response = await NluExampleApi.fetchExamples(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      let {examples} = action.data.data;
      if(examples){
        for(let i=0;i<examples.length;i++){
          examples[i]["styledExample"] = getConvertExampleData(examples[i])
        }
        newState.examples = action.data.data.examples;
        newState.count = action.data.data.count;
        newState.unpreparedCount = action.data.data.unpreparedCount;
      }
    }
    newState.exampleListLoading = false;
    return newState;
  }
);

reducerFactory.addAction(UPDATE_EXAMPLE.type, UPDATE_EXAMPLE.name,
  async data=>{
    const response = await NluExampleApi.updateExample(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      const { example } = action.data.data;
      const findExampleIndex = newState.examples.findIndex(ex=>ex.exampleId === example.exampleId);
      example["styledExample"] = getConvertExampleData(example);
      if(findExampleIndex !== -1){
        newState.examples[findExampleIndex] = example;
      }
      if(newState.oneExample.exampleId === example.exampleId){
        newState.oneExample = example;
      }
    }
    return newState;
  }
);

reducerFactory.addAction(UPDATE_MULTIPLE_EXAMPLE.type, UPDATE_MULTIPLE_EXAMPLE.name,
  async data=>{
    const response = await NluExampleApi.updateMultipleExamples(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data){
      action.data.success ? toastr.success(action.data.message) : toastr.error(action.data.message);
    }
    return newState;
  }
);

reducerFactory.addAction(UPDATE_TEXT_IN_EXAMPLE.type, UPDATE_TEXT_IN_EXAMPLE.name,
  async data => {
    const response = await NluExampleApi.updateTextInExample(data);
    return response.data;
  }, (state,action) => {
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      const { exampleId, text, prepared } = action.data.data;
      const findExampleIndex = newState.examples.findIndex(ex=>ex.exampleId === exampleId);
      if(findExampleIndex !== -1){
        newState.examples[findExampleIndex]["text"] = text;
        newState.examples[findExampleIndex]["prepared"] = prepared;
        newState.examples[findExampleIndex]["styledExample"] = getConvertExampleData(newState.examples[findExampleIndex]);
      }
      if(newState.oneExample.exampleId === exampleId){
        newState.oneExample["text"] = text;
        newState.oneExample["prepared"] = prepared;
        newState.oneExample["styledExample"] = getConvertExampleData(newState.oneExample);
      }
    }
    return newState;
  }
);

reducerFactory.addAction(UPDATE_TYPE_IN_EXAMPLE.type, UPDATE_TYPE_IN_EXAMPLE.name,
  async data=>{
    const response = await NluExampleApi.updateTypeinExmaple(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      const { exampleId, type } = action.data.data;
      const findExampleIndex = newState.examples.findIndex(ex=>ex.exampleId === exampleId);
      if(findExampleIndex !== -1){
        newState.examples[findExampleIndex]["type"] = type;
      }
      if(newState.oneExample.exampleId === exampleId){
        newState.examples[findExampleIndex]["type"] = type;
      }
    }
    return newState;
  }
);

reducerFactory.addAction(UPDATE_INTENT_IN_EXAMPLE.type, UPDATE_INTENT_IN_EXAMPLE.name,
  async data=>{
    const response = await NluExampleApi.updateIntentinExample(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      const { exampleId, intent, intentId } = action.data.data;
      const findExampleIndex = newState.examples.findIndex(ex=>ex.exampleId === exampleId);
      if(findExampleIndex !== -1){
        newState.examples[findExampleIndex]["intent"] = intent;
        newState.examples[findExampleIndex]["intentId"] = intentId;
      }
      if(newState.oneExample.exampleId === exampleId){
        newState.oneExample["intent"] = intent;
        newState.oneExample["intentId"] = intentId;
      }
    }
    return newState;
  }
);

reducerFactory.addAction(CREATE_NEW_INTENT_FROM_EXAMPLE.type, CREATE_NEW_INTENT_FROM_EXAMPLE.name,
  async data =>{
    const response = await NluExampleApi.createNewIntentFromExample(data);
    return response.data;
  } , (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      const { exampleId, intent, intentId } = action.data.data;
      const findExampleIndex = newState.examples.findIndex(ex=>ex.exampleId === exampleId);
      if(findExampleIndex !== -1){
        newState.examples[findExampleIndex]["intent"] = intent;
        newState.examples[findExampleIndex]["intentId"] = intentId;
      }
      if(newState.oneExample.exampleId === exampleId){
        newState.oneExample["intent"] = intent;
        newState.oneExample["intentId"] = intentId;
      }
    }
    return newState;
  }
)

reducerFactory.addAction(UPDATE_ENTITY_IN_EXAMPLE.type, UPDATE_ENTITY_IN_EXAMPLE.name,
  async data=>{
    const response = await NluExampleApi.updateEntityinExample(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      const index = newState.examples.findIndex(ex=>ex.exampleId === action.data.data.exampleId);
      const styledExample = getConvertExampleData(action.data.data);
      if(index !== -1){
        newState.examples[index] = action.data.data;
        newState.examples[index]["styledExample"] = styledExample;
      }
      if(newState.oneExample.exampleId === action.data.data.exampleId){
        newState.oneExample = action.data.data;
        newState.oneExample["styledExample"] = styledExample;
      }
    }
    return newState;
  }
);

reducerFactory.addAction(CREATE_NEW_ENTITY_FROM_EXAMPLE.type, CREATE_NEW_ENTITY_FROM_EXAMPLE.name,
  async data=>{
    const response = await NluExampleApi.createNewEntityFromExample(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      const { example } = action.data.data;
      const index = newState.examples.findIndex(ex=>ex.exampleId === example.exampleId);
      const styledExample = getConvertExampleData(example);
      if(index !== -1){
        newState.examples[index] = example;
        newState.examples[index]["styledExample"] = styledExample;
      }
      if(newState.oneExample.exampleId === example.exampleId){
        newState.oneExample = example;
        newState.oneExample["styledExample"] = styledExample;
      }
    }
    return newState;
  }
);

reducerFactory.addAction(ADD_EXAMPLE_TO_DATASET.type, ADD_EXAMPLE_TO_DATASET.name,
  async data=>{
    const response = await NluExampleApi.addExampleToDataset(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    return newState;
  } 
)

reducerFactory.addAction(DELETE_EXAMPLE.type, DELETE_EXAMPLE.name,
  async data=>{
    const response = await NluExampleApi.deleteExample(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    return newState;
  }
);

reducerFactory.addAction(DELETE_SINGLE_EXAMPLE.type, DELETE_SINGLE_EXAMPLE.name,
  async data => {
    const response = await NluExampleApi.deleteSingleExample(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    return newState;
  }
);

reducerFactory.addAction(DELETE_EXAMPLE_FEEDBACK.type, DELETE_EXAMPLE_FEEDBACK.name,
  async data => {
    const response = await NluExampleApi.deleteFeedbackExample(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    return newState;
  }
);

reducerFactory.addAction(FETCH_BASKET_ENTITIES.type, FETCH_BASKET_ENTITIES.name,
  async data =>{
    reducerFactory.action(EXAMPLE_PARAMS_CHANGE.name, [{key: "basketLoading", value: true}]);
    const response = await NluExampleApi.fetchBasketEntities(data);
    return response.data;
  },(state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.basketEntities = action.data.data.entities;
      newState.basketEntitiesCount = action.data.data.count;
    }
    newState.basketLoading = false;
    return newState;
  }
);

reducerFactory.addAction(DELETE_ENTITY_FROM_BASKET.type, DELETE_ENTITY_FROM_BASKET.name,
  async data =>{
    reducerFactory.action(EXAMPLE_PARAMS_CHANGE.name, [{key: "basketLoading", value: true}]);
    const response = await NluExampleApi.deleteBasketEntity(data);
    return response.data;
  },(state,action)=>{
    const newState = _.cloneDeep(state);
    newState.basketLoading = false;
    return newState;
  }
);

export default reducerFactory;

