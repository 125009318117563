import _ from "lodash";

import { fileState, reducerName, UPLOAD_FILE, FILE_LOADING } from "../states/file-state";
import ReducerFactory from "../../utils/reducer-factory";
import * as FileApi from "../apis/file-api";

const initialState = {...fileState};
const reducerFactory = new ReducerFactory(reducerName, initialState);

reducerFactory.addAction(FILE_LOADING.type, FILE_LOADING.name,
  status => status, (state,action)=>{
		const newState = _.cloneDeep(state);
    newState.loading = action.data;
    return newState;
  }
);

reducerFactory.addAction(UPLOAD_FILE.type, UPLOAD_FILE.name,
	async data =>{
		reducerFactory.action(FILE_LOADING.name, true);
		const response = await FileApi.uploadFile(data);
		return response.data;
	}, (state, action) =>{
		const newState = _.cloneDeep(state);
		newState.loading = false;
		return newState;
	}
);


export default reducerFactory;
