import NLUImage from "../assets/images/apps/nlu.svg";
import TranslationImage from "../assets/images/apps/Translation.svg";
import TransliterationImage from "../assets/images/apps/Transliteration.svg";
import LDImage from "../assets/images/apps/ld.svg";
import AUGImage from "../assets/images/apps/aug.svg";
import NERImage from "../assets/images/apps/ner.svg";
import NLUBannerImage from "../assets/images/apps/nlu_banner.svg";
import TranslationBannerImage from "../assets/images/apps/stt_banner.svg";
import NERBannerImage from "../assets/images/apps/ner_banner.svg";
import TransliterationBannerImage from "../assets/images/apps/transliteration_banner.svg";
import LDBannerImage from "../assets/images/apps/ld_banner.svg";
import AugBannerImage from "../assets/images/apps/tts_banner.svg";
import {NLU, NER, TRANSLATION, TRANSLITERATION, LANGUAGE_DETECTION, AUGMENTATION,
NLU_NAME, NER_NAME, TRANSLATION_NAME, TRANSLITERATION_NAME, AUGMENTATION_NAME, LANGUAGE_DETECTION_NAME,
NLU_VIDEO, NER_VIDEO, TRANSLATION_VIDEO, TRANSLITERATION_VIDEO, AUGMENTATION_VIDEO, LANGUAGE_DETECTION_VIDEO, STT,
STT_NAME, SPEAKER_IDENTIFICATION, SPEAKER_IDENTIFICATION_NAME, VOICE_EXTRACTOR, VOICE_EXTRACTOR_NAME, TTS, TTS_NAME
} from "../constants/common-constant";
import { docsLink, escapeRegex, getLanguageInfo } from "./common-helper";
import { NLU_PROJECTS, NLU_ROUTE } from "../routes/nlu";
import { TRANSLITERATION_PROJECTS, TRANSLITERATION_ROUTE } from "../routes/transliteration";
import { NER_PROJECTS, NER_ROUTE } from "../routes/ner";
import { STT_ROUTE } from "../routes/stt";
import { AUGMENTATION_ROUTE } from "../routes/augmentation";
import { LANGUAGE_DETECTION_ROUTE } from "../routes/language-detection";
import { TRANSLATION_ROUTE } from "../routes/translation";

export const AppsList = [
  {
    appType: NLU,
    displayName: NLU_NAME,
    icon: "icon icon-nlu",
    shortDescription: "For short text classification and entity recognition",
    video: NLU_VIDEO,
    docs: docsLink[NLU],
    languageSupport: getLanguageInfo[NLU].btnLabel,
    banner: NLUBannerImage,
    image: NLUImage,
    largeDescription: "Whether you are using chatbots, voicebots, or process automation engines, they are all powered by Natural Language Understanding (NLU). It's main purpose is to understand the intent of the user, and extract relevant information (entities) from what they said (speech) or wrote (text) to perform a relevant action. Entities can be anything from names, addresses, account numbers to very domain specific terms like names of chemicals, medicines, etc. Sometimes it also predicts the sentiment of the user which helps the bot respond to the user in a more empathetic tone."
  },
  {
    appType: NER,
    displayName: NER_NAME,
    icon: "icon icon-ner",
    shortDescription: "Extract information like names, addresses, etc. from text",
    video: NER_VIDEO,
    docs: docsLink[NER],
    languageSupport: getLanguageInfo[NER].btnLabel,
    banner: NERBannerImage,
    image: NERImage,
    largeDescription: "Entities play a major role in language understanding. To perform an action on a certain user query you not only need to understand the intent behind it but also the entities present in it. E.g., if someone says <b>'flights from Berlin to London'</b>, the intent here is <code>flight-search</code> and entities are <code>Berlin</code> and <code>London</code>, which are of type <code>city</code>."
  },
  {
    appType: TRANSLATION,
    displayName: TRANSLATION_NAME,
    icon: "icon icon-translation",
    shortDescription: "Translate text from any language to any other.",
    video: TRANSLATION_VIDEO,
    docs: docsLink[TRANSLATION],
    languageSupport: getLanguageInfo[TRANSLATION].btnLabel,
    banner: TranslationBannerImage,
    image: TranslationImage,
    largeDescription: "Whether we are talking about subtitles, government documents or question papers for exams, all of them need to be translated into multiple languages. Manually translating documents at such a scale is not only expensive but also an extremely time consuming process. With the help of Neural Machine Translation (NMT) you can 2x the amount of time it takes for manual translation of documents. Our translation models are all state-of-the-art Artificial Neural Networks which can translate text in over 50 languages."
  },
  {
    appType: TRANSLITERATION,
    displayName: TRANSLITERATION_NAME,
    icon: "icon icon-transliteration",
    shortDescription: "Convert words between alphabets while maintaining their pronunciation.",
    video: TRANSLITERATION_VIDEO,
    docs: docsLink[TRANSLITERATION],
    languageSupport: getLanguageInfo[TRANSLITERATION].btnLabel,
    banner: TransliterationBannerImage,
    image: TransliterationImage,
    largeDescription: "For languages that don't use the latin script, e.g., Arabic, Hindi, Punjabi, Sinhala and many other spoken around the world, typing can be challenging as keyboards/keypads mostly default to latin characters. That makes creating content in vernacular languages difficult. With transliteration you can create content in these languages using your latin keypad."
  },
  {
    appType: AUGMENTATION,
    displayName: AUGMENTATION_NAME,
    icon: "icon icon-aug",
    shortDescription: "10x your dataset size automatically.",
    video: AUGMENTATION_VIDEO,
    docs: docsLink[AUGMENTATION],
    languageSupport: getLanguageInfo[AUGMENTATION].btnLabel,
    image: AUGImage,
    banner: AugBannerImage,
    largeDescription: "Any language processing task requires data, and we all wish we could generate data magically. That was exactly the idea behind building Augmentation. Given a sentence Augmentation can generate up to ten sentences keeping the intent of the original sentence intact. It can help in creating datasets faster and make language processing models more robust."
  },
  {
    appType: LANGUAGE_DETECTION,
    displayName: LANGUAGE_DETECTION_NAME,
    icon: "icon icon-ld",
    shortDescription: "Detect the language used in any given text",
    video: LANGUAGE_DETECTION_VIDEO,
    docs: docsLink[LANGUAGE_DETECTION],
    languageSupport: getLanguageInfo[LANGUAGE_DETECTION].btnLabel,
    banner: LDBannerImage,
    image: LDImage,
    largeDescription: "Dealing with different languages poses a fundamental problem of first detecting what language you are looking at. Language-detection service will help you to detect the language based on your input text with given confidence score. Use it with other APIs like transliteration or translation and make your NLP application cater to any language you want."
  },
  {
    appType: STT,
    displayName: STT_NAME,
    icon: "icon icon-mic",
    shortDescription: "Transcription for File and Streaming",
    video: null,
    docs: docsLink[STT],
    languageSupport: getLanguageInfo[STT].btnLabel,
    banner: LDBannerImage,
    image: LDImage,
    largeDescription: "Transcription for File and Streaming"
  },
  {
    appType: SPEAKER_IDENTIFICATION,
    displayName: SPEAKER_IDENTIFICATION_NAME,
    icon: "icon icon-mic",
    shortDescription: "Identify speakers in an audio file",
    video: null,
    docs: docsLink[SPEAKER_IDENTIFICATION],
    languageSupport: "",
    banner: LDBannerImage,
    image: LDImage,
    largeDescription: "The service allows for speaker-based transcriptions of audio files with multiple speakers, identifying each speaker's speech and their presence in the audio. It is particularly useful for transcribing phone calls, meetings, and interviews."
  },
  {
    appType: VOICE_EXTRACTOR,
    displayName: VOICE_EXTRACTOR_NAME,
    icon: "icon icon-mic",
    shortDescription: "Extract voice from any audio file",
    video: null,
    docs: docsLink[VOICE_EXTRACTOR],
    languageSupport: "",
    banner: LDBannerImage,
    image: LDImage,
    largeDescription: "The service helps separate voice audio from background audio in NLP tasks, useful for improving speech to text quality, video localization apps, and building Karaoke apps."
  },
  {
    appType: TTS,
    displayName: TTS_NAME,
    icon: "icon icon-mic",
    shortDescription: "Generate speech from text",
    video: null,
    docs: docsLink[TTS],
    languageSupport: "",
    banner: LDBannerImage,
    image: LDImage,
    largeDescription: "Text To Speech, also known as voice synthesis or Text To Voice, is a technology used to create real-time voice in order to dictate configurated text. Using NeuralSpace's Text To Speech, these synthetic voices can be selected according to your desired language."
  }
];

export const getApps = search => {
  search = search ? escapeRegex(search) : ".";
  let searchRegex = new RegExp(search, "i");
  const appsData = AppsList.filter(app=>searchRegex.test(app.displayName));
  return appsData
}

export const getServiceSelectedMenu = pathname => {
  let selected = pathname;
  let openSelectedMenu = selected;
  if(selected.includes(AUGMENTATION_ROUTE)){
    selected = AUGMENTATION_ROUTE;
  }else if(selected.includes(LANGUAGE_DETECTION_ROUTE)){
    selected = LANGUAGE_DETECTION_ROUTE;
  }else if(selected.includes(TRANSLATION_ROUTE)){
    selected = TRANSLATION_ROUTE;
  }else if(selected.includes(STT_ROUTE)){
    selected = STT_ROUTE;
  }else if(selected.includes(NLU_ROUTE)){
    openSelectedMenu = NLU_ROUTE;
    if(selected.includes(NLU_PROJECTS)){
      selected = NLU_PROJECTS;
    }
  }else if(selected.includes(NER_ROUTE)){
    openSelectedMenu = NER_ROUTE;
    if(selected.includes(NER_PROJECTS)){
      selected = NER_PROJECTS;
    }
  }else if(selected.includes(TRANSLITERATION_ROUTE)){
    openSelectedMenu = TRANSLITERATION_ROUTE;
    if(selected.includes(TRANSLITERATION_PROJECTS)){
      selected = TRANSLITERATION_PROJECTS;
    }
  }
  return {selected, openSelectedMenu}
}