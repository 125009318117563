import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

class ButtonLoading extends React.Component{
  render(){
    return(
      <Spin 
        indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />}
        className="ns-btn-spin-loading"
      />
    )
  }
}

export default ButtonLoading