import { NLU, NLU_NAME } from "../constants/common-constant";
import { encodeUriValueConvertor } from "../helpers/common-helper";

export const NLU_ROUTE = `/platform/${NLU}`;
export const NLU_DASHBOARD = `/platform/${NLU}/dashboard`;
export const NLU_PROJECTS = `/platform/${NLU}/projects`;
export const NLU_ENTITIES = `/platform/${NLU}/entities`;
export const NLU_UPLOAD_DATASET = `/platform/${NLU}/upload_dataset`;
export const NLU_IMPORT_DATASET = `/platform/${NLU}/import_dataset`;
export const NLU_PROJECT_DETAILS = `/platform/${NLU}/projects/:projectId/:language/details`;
export const NLU_DATA_STUDIO = `/platform/${NLU}/projects/:projectId/:language/data_studio`;
export const NLU_MODEL_FEEDBACK_PAGE = `/platform/${NLU}/projects/:projectId/:language/:modelId/feedback`;
export const NLU_MODEL_ANALYTICS_PAGE = `/platform/${NLU}/projects/:projectId/:language/:modelId/analytics`;

export const getNluDashboardRoute = (modelId) => `${NLU_DASHBOARD}?model=${modelId}`;

export const getNluProjectRoute = (language, pageNumber, pageSize) => 
  `${NLU_PROJECTS}?languages=${language}&page=${pageNumber}&limit=${pageSize}`;

export const getNluProjectDetailsRoute = (projectDetailsURI, trainingStatus, deployedStatus, pageNumber, pageSize, modelId) =>
  `${projectDetailsURI}?trainingStatus=${trainingStatus}&deployedStatus=${deployedStatus}&page=${pageNumber}&limit=${pageSize}&model=${modelId}`;

export const getNluDataStudioRoute = (dataStudioUri, exampleStatus, exampleType, entityType, entity, intent, pageNumber, pageSize) =>{
  entity = encodeUriValueConvertor(entity);
  intent = encodeUriValueConvertor(intent);
  return `${dataStudioUri}?${encodeURIComponent(`status=${exampleStatus}&type=${exampleType}&entityType=${entityType}&entity=${entity}&intent=${intent}&page=${pageNumber}&limit=${pageSize}`)}`
};

export const getNluModelFeedbackRoute = (feedbackUri, entity, intent, intentRange, entityRange, pageNumber, pageSize) => {
  entity = encodeUriValueConvertor(entity);
  intent = encodeUriValueConvertor(intent);
  return `${feedbackUri}?${encodeURIComponent(`entity=${entity}&intent=${intent}&intentRange=${intentRange}&entityRange=${entityRange}&page=${pageNumber}&limit=${pageSize}`)}`;
}

export const getNluModelAnalyticsRoute = (analyticsUri, type, pageNumber, pageSize) =>
  `${analyticsUri}?type=${type}&page=${pageNumber}&limit=${pageSize}`;

export const getNluEntityRoute = (type, language, pageNumber, pageSize) => 
  `${NLU_ENTITIES}?filter=${type}&language=${language}&page=${pageNumber}&limit=${pageSize}`;

export const getNluUploadDatasetRoute = (pageNumber) =>
  `${NLU_UPLOAD_DATASET}?page=${pageNumber}`;

export const getNluImportDatasetRoute = (language, planType, search, domain, intent, entity, entityRange, intentRange, pageNumber, pageSize, importPageNumber) =>
  `${NLU_IMPORT_DATASET}?language=${language}&plan=${planType}&search=${search}&domain=${domain}&intent=${intent}&entity=${entity}&entityRange=${entityRange}&intentRange=${intentRange}&page=${pageNumber}&limit=${pageSize}&importPage=${importPageNumber}`;

export const nluAppRoutes = [
  {
    key: NLU_PROJECTS,
    name: "Projects",
    path: getNluProjectRoute("", 1, 12),
    icon: "icon icon-feedback ns-mr-2 ns-vertical-align-custom ns-fs-lg",
    i18Message: "sidebar.projects"
  },
  {
    key: NLU_ENTITIES,
    name: "Entities",
    path: getNluEntityRoute("All", "English", 1, 12),
    icon: "icon icon-ner ns-mr-2 ns-vertical-align-custom ns-fs-lg",
    i18Message: "sidebar.entities"
  },
  {
    key: NLU_UPLOAD_DATASET,
    name: "Upload Datasets",
    path: getNluUploadDatasetRoute(1),
    icon: "icon icon-upload ns-mr-2 ns-vertical-align-custom ns-fs-lg",
    i18Message: "sidebar.upload_dataset"
  },
  {
    key: NLU_IMPORT_DATASET,
    name: "Import Datasets",
    path: getNluImportDatasetRoute("All","All","","","All","All","","",1,12,1),
    icon: "icon icon-folder-o ns-mr-2 ns-vertical-align-custom ns-fs-lg",
    i18Message: "sidebar.import_dataset"
  },
  {
    key: NLU_DASHBOARD,
    name: NLU_NAME,
    path: NLU_DASHBOARD,
    icon: "icon icon-chart-line ns-mr-2 ns-vertical-align-custom ns-fs-lg",
    i18Message: "sidebar.dashboard"
  },
];