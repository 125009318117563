import React from "react";
import toastr from "toastr";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Input, Row, Col, Modal, Tag } from "antd";


import { getApps } from "../../../helpers/app-helper";
import ButtonLoading from "../../../components/loading/button-loading";
import * as UsageState from "../../../redux/states/usage-state";
import * as AuthState from "../../../redux/states/auth-state";
import UsageReducer from "../../../redux/modules/usage-module";
import AuthReducer from "../../../redux/modules/auth-module";
import { BRAND_NAME } from "../../../constants/brand-constant";

const $ = window.$;
class AppLists extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      search: "",
      selectedApp: []
    }
  }

  onSearchChange = (e) => {
    this.setState({ search: e.target.value })
  }

  onActivateShortcut = () => {
    $(window).on("keydown", e => {
      if (e.ctrlKey) {
        if (e.key.toLowerCase() === "o") {
          const { onToggleApps } = this.props;
          e.preventDefault();
          e.stopPropagation();
          onToggleApps();
        }
      }
    });
  }

  onNavigateApp = (app) => {
    const { onToggleApps } = this.props;
    this.props.history.push(`/platform/${app.appType}`);
    onToggleApps();
  }

  onInstallApp = (app) => {
    const { usageReducer, authReducer } = this.props;
    this.setState({ selectedApp: [...this.state.selectedApp, app.appType] }, () => {
      usageReducer[UsageState.INSTALL_APP.name]({ appType: app.appType }).then(res => {
        if (res && res.success) {
          authReducer[AuthState.CHECK_AUTH.name]();
        }
        let selected = [...this.state.selectedApp];
        const index = selected.indexOf(app.appType);
        if (index > -1)
          selected.splice(index, 1);
        this.setState({ selectedApp: selected });
      });
    });
  }

  onCopyAppCode = (app) => {
    navigator.clipboard.writeText(app.appType);
    toastr.success("Service code copied");
  }

  onNavigateDocs = docUrl => {
    window.open(docUrl, "_blank")
  }

  render() {
    const { apps, drawer, onToggleApps } = this.props;
    const { search, selectedApp } = this.state;

    return (
      <div>
        <Modal
          title={`${BRAND_NAME} Services`}
          centered
          open={drawer}
          maskClosable={false}
          onCancel={onToggleApps}
          width={1200}
          footer={false}
        >
          <div>
            <div className="ns-p-md-3 ns-app-list-search-div">
              <Input autoFocus placeholder="Search Services..." allowClear onChange={this.onSearchChange} />
            </div>
            <div className="ns-app-list-div ns-p-md-3">
              <Row gutter={[24, 24]}>
                {getApps(search).map(app => {
                  const ifInstalled = apps.indexOf(app.appType) > -1;
                  let iconDivClass = "ns-app-list-not-installed-icons ns-mr-md-2 ns-p-2";
                  let tagClass = "ns-app-list-not-installed-tags";
                  if (ifInstalled) {
                    iconDivClass = "ns-app-list-installed-icons ns-mr-md-2 ns-p-2";
                    tagClass = "ns-app-list-installed-tags";
                  }
                  return (
                    <Col key={app.appType} xl={12} lg={12} md={24} sm={24} xs={24}>
                      <div className="ns-app-list-container">
                        <div className="ns-d-flex ns-justify-content-between ns-p-3">
                          <div className="ns-d-flex ns-justify-content-between">
                            <div className={iconDivClass}>
                              <i className={`${app.icon} ns-text-white`} />
                            </div>
                            <div>
                              <h6 className="ns-m-0">{app.displayName}</h6>
                              <p className="ns-fs-sm">{app.shortDescription}</p>
                            </div>
                          </div>
                          <div>
                            <Button size="small" type="primary" ghost onClick={() => this.onCopyAppCode(app)}>Copy Service code</Button>
                          </div>
                        </div>
                        {app.video ? (
                          <iframe
                            width="100%"
                            src={app.video}
                            title={app.displayName}
                            className="ns-app-list-video-iframe"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          />
                        ):(
                          <img 
                            src={app.banner}
                            className="ns-app-list-video-iframe"
                            alt="No Video"
                          />
                        )}
                        <div className="ns-d-flex ns-justify-content-between ns-p-3">
                          <div className="ns-d-flex ns-jutify-content-center ns-align-items-center">
                            <Tag className={tagClass}>{ifInstalled ? "Installed" : "Not installed"}</Tag>
                            <Tag className={tagClass}>{app.languageSupport}</Tag>
                          </div>
                          <div>
                            <Button type="primary" className="ns-mr-md-2" ghost onClick={() => this.onNavigateDocs(app.docs)}>Docs</Button>
                            {ifInstalled ? (
                              <Button type="primary" onClick={() => this.onNavigateApp(app)}>Dashboard</Button>
                            ) : (
                              <Button onClick={() => this.onInstallApp(app)} className="ns-btn-secondary" disabled={selectedApp.indexOf(app.appType) > -1}>
                                {selectedApp.indexOf(app.appType) > -1 && <ButtonLoading />}
                                Install
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>
                  )
                })}
              </Row>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

AppLists.propTypes = {
  user: PropTypes.object.isRequired,
  apps: PropTypes.array.isRequired,
  onToggleApps: PropTypes.func.isRequired,
  drawer: PropTypes.bool.isRequired
}

const AppListsContainer = connect(
  state => ({
    user: state.get(AuthState.reducerName).user,
    apps: state.get(AuthState.reducerName).apps
  }),
  dispatch => ({
    usageReducer: UsageReducer.getActions(dispatch),
    authReducer: AuthReducer.getActions(dispatch)
  })
)(AppLists)

export default withRouter(AppListsContainer);