export const reducerName = "nerEntity";

export const nerEntityState={
  loading: false,
  createEntityLoading: false,
  oneEntity: {},
  search: "",
  entities: [],
  count: 0,
  language: "English",
  type: "All",
  error: null,
    
  //create entity state
  selectedLanguage: "English",

  //Test entity states
  testResult: [],

  //Example page
  searchedEntities: [],

  //Synonyms
  synonyms: []
}

export const ENTITY_LOADING = {
  type: "NER_ENTITY_LOADING",
  name: "nerEntityLoading"
};

export const ENTITY_PARAMS_CHANGE = {
  type: "NER_ENTITY_PARAMS_CHANGE",
  name: "nerEntityParamsChange"
};

export const TEST_SYSTEM_ENTITY={
  type: "NER_TEST_SYSTEM_ENTITY",
  name: "nerTestSystemEntity"
};

export const SEARCH_ENTITIES_IN_EXAMPLE = {
  type: "NER_SEARCH_ENTITIES_IN_EXAMPLE",
  name: "nerSearchEntitiesInExample"
};

export const CREATE_ENTITY_LOADING = {
  type: "NER_CREATE_ENTITY_LOADING",
  name: "nerCreateEntityLoading"
};

export const CREATE_ENTITY = {
  type: "NER_CREATE_ENTITY",
  name: "nerCreateEntity"
};

export const FETCH_ENTITIES = {
  type: "FETCH_ENTITIES",
  name: "fetchEntities"
};

export const SINGLE_ENTITY = {
  type: "SINGLE_ENTITY",
  name: "singleEntity"
};

export const UPDATE_EXAMPLES_IN_ENTITY = {
  type: "UPDATE_EXAMPLES_IN_ENTITY",
  name: "addExampleInEntity"
};

export const DELETE_ENTITY = {
  type: "DELETE_ENTITY",
  name: "deleteEntity"
};