export const reducerName = "nluModel";
export const nluModelState = {
  loading: false,
  modelListLoading: false,
  singleModelLoading: false,
  deleteModelId: [],
  deployModelId: [],
  pageNumber: 1,
  pageSize: 15,
  search: "",
  count: 0,
  models: [],
  lastModel: {},
  trainingStatus: [],
  deployStatus: "All",
  parsedResponse: {},
  dialogflowResponse: {},
  witResponse: {},
  luisResponse: {},
  feedbackData:[],
  feedbackDataCount: 0,
  feedbackDataIntentConfidence: [],
  feedbackDataEntityConfidence: [],
  modelAnalyticsLoading: false,
  modelAnalytics: {},
  error: null
}

export const MODEL_LOADING = {
  type: "MODEL_LOADING",
  name: "modelLoading"
};

export const MODEL_PARAMS_CHANGE = {
  type: "MODEL_PARAMS_CHANGE",
  name: "modelParamsChange"
};

export const MODEL_ACTION_PARAMS_CHANGE = {
  type: "MODEL_ACTION_PARAMS_CHANGE",
  name: "modelActionParamsChange"
}

export const MODEL_DATA_CHANGE = {
  type: "MODEL_DATA_CHANGE",
  name: "modelDataChange"
}

export const TRAIN_MODEL = {
  type: "TRAIN_MODEL",
  name: "trainModel"
};

export const RE_TRAIN_MODEL = {
  type: "RE_TRAIN_MODEL",
  name: "retrainModel"
};

export const FETCH_MODELS = {
  type: "FETCH_MODELS",
  name: "fetchModels"
};

export const SINGLE_MODEL = {
  type: "SINGLE_MODEL",
  name: "singleModel"
};

export const UPDATE_MODEL = {
  type: "UPDATE_MODEL",
  name: "updateModel"
};

export const DELETE_MODEL={
  type: "DELETE_MODEL",
  name: "deleteModel"
};

export const DEPLOY_MODEL={
  type: "DEPLOY_MODEL",
  name: "deployModel"
};

export const PARSE_MODEL={
  type: "PARSE_MODEL",
  name: "parseModel"
};

export const GET_FEEDBACK_DATA={
  type: "GET_FEEDBACK_DATA",
  name: "getFeedbackData"
}

export const GET_MODEL_ANALYTICS={
  type: "NLU_GET_MODEL_ANALYTICS",
  name: "nluGetModelAnalytics"
}