import _ from "lodash";
import toastr from "toastr";

import { nerDatasetState, reducerName, DATASET_PARAMS_CHANGE, DATASET_PARAMS_CHANGE_WITHOUT_ASYNC, 
FETCH_DATASETS, FETCH_UPLOAD_LISTS, UPLOAD_DATASET, IMPORT_DATASET, ONE_UPLOAD, FETCH_IMPORT_LISTS,
FETCH_DATASETS_CREATE_PROJECT
} from "../../states/ner/ner-dataset-state";
import * as NerDatasetApi from "../../apis/ner/ner-dataset-api";
import ReducerFactory from "../../../utils/reducer-factory";

const initialState = {...nerDatasetState};
const reducerFactory = new ReducerFactory(reducerName, initialState);

reducerFactory.addAction(DATASET_PARAMS_CHANGE.type, DATASET_PARAMS_CHANGE.name,
  async data=> data,(state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.length > 0){
      action.data.forEach(d=>{
        const { key, value } = d;
        newState[key] = value;
      })
    }    
    return newState;
  } 
);

reducerFactory.addAction(DATASET_PARAMS_CHANGE_WITHOUT_ASYNC.type, DATASET_PARAMS_CHANGE_WITHOUT_ASYNC.name,
  data=> data,(state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.length > 0){
      action.data.forEach(d=>{
        const { key, value } = d;
        newState[key] = value;
      })
    }    
    return newState;
  } 
);

reducerFactory.addAction(FETCH_DATASETS.type, FETCH_DATASETS.name,
	async(data)=>{
    reducerFactory.action(DATASET_PARAMS_CHANGE_WITHOUT_ASYNC.name, [{key: "datasetLoading", value: true }]);
    const response = await NerDatasetApi.fetchDatasets(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.datasets = action.data.data.datasets;
      newState.count = action.data.data.count;
      newState.datasetEntities = action.data.data.entities || [];
      newState.datasetEntityCounts = action.data.data.entityCounts || [];
      newState.datasetDomains = action.data.data.domains || [];
    }
    newState.datasetLoading = false;
    return newState;
  }	
);

reducerFactory.addAction(FETCH_DATASETS_CREATE_PROJECT.type, FETCH_DATASETS_CREATE_PROJECT.name,
	async(data)=>{
    reducerFactory.action(DATASET_PARAMS_CHANGE_WITHOUT_ASYNC.name, [{key: "createProjectDatasetLoading", value: true }]);
    const response = await NerDatasetApi.fetchDatasets(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.createProjectDataset = action.data.data.datasets;
      newState.createProjectDatasetCount = action.data.data.count;
    }
    newState.createProjectDatasetLoading = false;
    return newState;
  }	
);

reducerFactory.addAction(UPLOAD_DATASET.type, UPLOAD_DATASET.name,
	async(data)=>{
    const response = await NerDatasetApi.uploadDataset(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data){
      toastr[action.data.success ? "success" : "error"](action.data.message);
    }
    return newState;
  }	
);

reducerFactory.addAction(ONE_UPLOAD.type, ONE_UPLOAD.name,
	async(data)=>{
    const response = await NerDatasetApi.fetchOneUpload(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.data){
      newState.oneUpload = action.data.data;
    }
    return newState;
  }	
);

reducerFactory.addAction(IMPORT_DATASET.type, IMPORT_DATASET.name,
	async(data)=>{
    const response = await NerDatasetApi.importDataset(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data){
      toastr[action.data.success ? "success" : "error"](action.data.message);
    }
    newState.loading = false;
    return newState;
  }	
);

reducerFactory.addAction(FETCH_UPLOAD_LISTS.type, FETCH_UPLOAD_LISTS.name,
	async(data)=>{
    reducerFactory.action(DATASET_PARAMS_CHANGE_WITHOUT_ASYNC.name, [{key: "uploadsListLoading", value: true }]);
    const response = await NerDatasetApi.fetchUploadLists(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.uploadCount = action.data.data.count;
      newState.uploads = action.data.data.uploads;
    }
    newState.uploadsListLoading = false;
    return newState;
  }	
);

reducerFactory.addAction(FETCH_IMPORT_LISTS.type, FETCH_IMPORT_LISTS.name,
  async(data)=>{
    reducerFactory.action(DATASET_PARAMS_CHANGE_WITHOUT_ASYNC.name, [{key: "importsListLoading", value: true }]);
    const response = await NerDatasetApi.fetchUploadLists(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.importCount = action.data.data.count;
      newState.imports = action.data.data.uploads;
    }
    newState.importsListLoading = false;
    return newState;
  }	
)

export default reducerFactory;