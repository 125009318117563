import { API_URL, USER_ACCESS_TYPE } from "../../constants/common-constant";
import axios from "axios";
import { getAccessToken } from "../../helpers/common-helper";

axios.defaults.baseURL = API_URL;
axios.interceptors.request.use((axiosConfig) => {
    axiosConfig.headers.accesstype = USER_ACCESS_TYPE;
    const accessSecret = getAccessToken();
    if (accessSecret) {
        axiosConfig.headers.authorization = accessSecret;
        return axiosConfig;
    }
    return axiosConfig;
});

const apiMethod = async (method, endpoint, data) => {
    try {
        const response = await axios[method](endpoint, data);
        return response;
    } catch (error) {
        if(error.response.status === 420){
            localStorage.setItem("status", "block");
        }
        return error.response;
    }
}

export {axios, apiMethod};

