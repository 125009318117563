import { DeploymentUnitOutlined, InfoCircleOutlined, CheckCircleOutlined, LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import TrainingImage from "../../assets/images/apps/training.svg";
import FailedImage from "../../assets/images/apps/failed.svg";
import { BRAND_NAME } from "../../constants/brand-constant";
import { decodeUriValueConvertor, validArray } from '../common-helper';
const $ = window.$;
const statusMap = {
	"Initiated": "geekblue",
	"Queued": "blue",
	"Pipeline Building": "volcano",
	"Pipeline Built": "gold",
	"Preparing Data": "lime",
	"Data Prepared": "orange",
	"Training": "purple",
	"Trained": "geekblue",
	"Saved": "cyan",
	"Completed": "green",
	"Failed": "red",
	"Timed Out": "magenta",
	"Dead": ""
}

const statusText = {
	"Initiated": {text: "Woo! Your model has been initiated for training", color: "#1d39c4"},
	"Queued": {text: "Wow! Your model got queued", color: "#096dd9"},
	"Pipeline Building": {text: "Model is building it's pipeline", color: "#d4380d"},
	"Pipeline Built": {text: "Model has built it's pipeline", color: "#d48806"},
	"Preparing Data": {text: "Great! Model is preparing it's data", color: "#7cb305"},
	"Data Prepared": {text: "Model has prepared it's data", color: "#d46b08"},
	"Training": {text: "Now model is in training state, It will be ready in a few seconds", color: "#531dab"},
	"Trained": {text: "Model has been trained successfully", color: "#1d39c4"},
	"Saved": {text: "Your model has been saved", color: "#08979c"},
	"Completed": {text: "Congratulations! Your model training has been completed, Navigating to the Deployment step...", color: "#389e0d"},
	"Failed": {text: "Something went wrong in the model. Training failed", color: "#cf1322"},
	"Timed Out": {text: "Model took so much time to train, so it got timed out", color: "#c41d7f"},
	"Dead": {text: "Ahh! Your model is dead", color: ""},
}

export const getPredefinedParseTexts = {
	"54ca3e57-48fd-48fd-a0ec-40b2fa267c15": [
		"nevermind.... you’re not human ... I need to talk to a live person",
		"Change 5pm bus station from Green Park to Park Lane",
		"Are there train tickets available from Delhi to Mumbai for tomorrow?",
		"i am currently at kalkaji metro station. where is the nearest police station"
	],
	"0bcbffc5-3c5c-4f85-9674-e87241c2ef90": [
		"How to arrive in Kashmir Valley",
		"Make a trip package booking to the himalayas",
		"I want to book a bus from dehradun"
	]
}

export const INITIATED = "Initiated";
export const QUEUED = "Queued";
export const PICKED = "Picked";
export const PREPARING_DATA = "Preparing Data";
export const START_MODEL_TRAINING = "Training";
export const END_MODEL_TRAINING = "Trained";
export const SAVE_MODEL = "Saved";
export const COMPLETED = "Completed";
export const FAILED_TRAINING = "Failed";
export const TIMEDOUT = "Timed Out";
export const DEAD = "Dead";

export const showDeploy = model => model && model.trainingProgress && model.trainingProgress.includes(SAVE_MODEL);
export const showTestAndFeedback = model => model && model.replicas > 0;
export const showMessage = model => {
	let message = null;
	if (model && model.deploymentMessage) {
		message = model.deploymentMessage;
	}
	return message;
}

export const showAccuracy = (model) => {
	let data = { show: false, strictEntity: "0%", partialEntity: "0%", intentValue: "0%", zeroFlag: false };
	let intentVal = 0, strictEntityVal = 0, partialEntityVal = 0;
	if (model) {
		if (model.trainingStatus === COMPLETED) {
			data.show = true;
			if (model.metrics && model.metrics.intentClassifierPerformance && model.metrics.intentClassifierPerformance.i_acc) {
				data.intentValue = `${(model.metrics.intentClassifierPerformance.i_acc * 100).toFixed(2)}%`;
				intentVal = model.metrics.intentClassifierPerformance.i_acc * 100;
			}
			if (model.metrics && model.metrics.nerPerformance && model.metrics.nerPerformance.e_f1_strict) {
				data.strictEntity = `${(model.metrics.nerPerformance.e_f1_strict * 100).toFixed(2)}%`;
				strictEntityVal = model.metrics.nerPerformance.e_f1_strict * 100;
			}
			if (model.metrics && model.metrics.nerPerformance && model.metrics.nerPerformance.e_f1_partial) {
				data.partialEntity = `${(model.metrics.nerPerformance.e_f1_partial * 100).toFixed(2)}%`;
				partialEntityVal = model.metrics.nerPerformance.e_f1_partial * 100;
			}
			if(intentVal <= 0 && strictEntityVal <= 0 && partialEntityVal <= 0){
				data.zeroFlag = true;
			}
		}
	}
	return data;
}
export const entityTooltip = (
	<div className="ns-popover-max-width ns-p-2">
		<p className="ns-fs-sm ns-mb-2">When running machine learning models for named entity recognition,
			it is common to report metrics (precision, recall and f1-score) at the individual token level.</p>
		<p className="ns-fs-sm ns-mb-2">This may not be the best approach, as a named entity can be made up of multiple tokens. </p>
		<p className="ns-fs-sm ns-mb-2">At the same time, regular NER scheme tend to ignore the possibility of partial matches which are scenarios when the entity recognition system gets the named-entity surface string correct but the type wrong.</p>
		<p className="ns-fs-sm ns-mb-2">
			For the reason, we present two metrics to evaluate the entity recognition system as follows: <br />
			<b>1. Strict F1 Score: Here we consider exact boundary surface string match and entity type</b><br />
			<b>2. Partial F1 Score: Here we consider partial boundary match over the surface string, regardless of the type;</b>
		</p>
		<p className="ns-fs-sm ns-mb-2">
			Consider the following examples:<br />
			Example Sentence 1: <br />
			(with Gold Labels): Unless (O)     Karl (B-PER) Smith (I-PER) resign (O) <br />
			(predicted Labels): Unless (B-PER) Karl (B-PER) Smith (I-PER) resign (O) <br /><br />

			Strict F1 Score will be: 0.66<br />
			Partial F1 Score will be: 0.66<br /><br />

			Example Sentence 2: <br />
			(with Gold Labels): I (O) live (0) in (0) Palo (B-LOC) Alto (I-LOC) <br />
			(predicted Labels): I (O) live (0) in (0) Palo (B-ORG) Alto (I-ORG) <br /><br />

			Strict F1 Score will be: 0.0<br />
			Partial F1 Score will be: 1.0
		</p>
	</div>
);

export const intentScore = analytics => {
	const { intentClassifierAnalysis } = analytics;
	let precision = 0, recall = 0, f1Score = 0;
	if(validArray(intentClassifierAnalysis)){
		intentClassifierAnalysis.forEach(intent=>{
			if(intent.precision) precision += intent.precision;
			if(intent.recall) recall += intent.recall;
			if(intent["f1-score"]) f1Score += intent["f1-score"];
		});
		precision = ((precision / intentClassifierAnalysis.length) * 100) > 100 ? 100 : ((precision / intentClassifierAnalysis.length) * 100);
		recall = ((recall / intentClassifierAnalysis.length) * 100) > 100 ? 100 : ((recall / intentClassifierAnalysis.length) * 100);
		f1Score = ((f1Score / intentClassifierAnalysis.length) * 100) > 100 ? 100 : ((f1Score / intentClassifierAnalysis.length) * 100);
	}
	return { precision, recall, f1Score };
}

export const entityScore = analytics => {
	const { entityPerformanceAnalysis } = analytics;
	let precision = 0, recall = 0, f1Score = 0;
	if(validArray(entityPerformanceAnalysis)){
		entityPerformanceAnalysis.forEach(entity=>{
			if(entity.precision) precision += entity.precision;
			if(entity.recall) recall += entity.recall;
			if(entity["f1-score"]) f1Score += entity["f1-score"];
		});
		precision = ((precision / entityPerformanceAnalysis.length) * 100) > 100 ? 100 : ((precision / entityPerformanceAnalysis.length) * 100);
		recall = ((recall / entityPerformanceAnalysis.length) * 100) > 100 ? 100 : ((recall / entityPerformanceAnalysis.length) * 100);
		f1Score = ((f1Score / entityPerformanceAnalysis.length) * 100) > 100 ? 100 : ((f1Score / entityPerformanceAnalysis.length) * 100);
	}
	return { precision, recall, f1Score };
}


export const showModelStatusData = (model) => {
	let data = { status: model.trainingStatus, image: TrainingImage };
	if (model && (model.trainingStatus === FAILED_TRAINING || model.trainingStatus === DEAD)) {
		data.image = FailedImage;
	}
	return data;
}

export const showDelete = model => {
	let show = false;
	if(model && [COMPLETED, FAILED_TRAINING, TIMEDOUT, DEAD].indexOf(model.trainingStatus) > -1){
		show = true;
	}
	if(model && model.deploymentStatus === "Deploying"){
		show = false;
	}
	return show;
}

export const modelStatus = status => statusMap[status];
export const modelStatusText = status => statusText[status];

export const enableParseBtn = (id) => {
	let text = $(`#${id}`).clone().find(".ne-c-inner").remove().end().text();
	if(text) text = text.trim();
	if (!text) return true;
	else return false;
}

export const getModelStatus = (model, deployedModelIds) =>{
	let obj = {
		deployBtn: "Deploy with AutoMLOps",
		loading: false,
		deployStatus: "Not Deployed",
		tooltip: "Model is not deployed",
		icon: <InfoCircleOutlined className="deployment-icon deploying-inactive-color ns-vertical-align-middle"/>
	}
	if(model){
		if((model.deploymentStatus === "Deploying" || deployedModelIds.includes(model.modelId))&& model.deploymentStatus !== "Deployed"){
			obj = {
				deployBtn: "Deploying",
				loading: true,
				deployStatus: "Deploying",
				tooltip: "Model is deploying",
				icon: <DeploymentUnitOutlined className="deployment-icon ns-primary-element ns-vertical-align-middle" spin/>
			}			
		}else if(model.deploymentStatus === "Failed"){
			obj = {
				deployBtn: "Deploy with AutoMLOps",
				loading: false,
				deployStatus: "Deploy",
				tooltip: model.deploymentMessage,
				icon: <InfoCircleOutlined className="deployment-icon deployment-failed-color ns-vertical-align-middle"/>
			}
		}else if(model.deploymentStatus === "Deployed" && model.replicas > 0){
			obj = {
				deployBtn: "Deploy with AutoMLOps",
				loading: false,
				deployStatus: "Deployed",
				tooltip: model.deploymentMessage,
				icon: <CheckCircleOutlined className="deployment-icon deployment-success-color ns-vertical-align-middle"/>
			}
		}
	}
	return obj;
}
const inactiveStatus = [FAILED_TRAINING, TIMEDOUT]
export const getModelTrainingStatus = (model) => {
	let obj = {
		icon: "",
		tooltip: ""
	};
	if(inactiveStatus.indexOf(model.trainingStatus) > -1){
		obj.icon = <InfoCircleOutlined className="deployment-icon deployment-failed-color ns-vertical-align-middle"/>;
		obj.tooltip = model.message;
	}else if(model.trainingStatus === DEAD){
		obj={
			icon: <QuestionCircleOutlined className="deployment-icon deployment-inactive-color ns-vertical-align-middle"/>,
			tooltip: model.message
		}
	}
	else if(model.trainingStatus === COMPLETED){
		obj={
			icon: <CheckCircleOutlined className="deployment-icon deployment-success-color ns-vertical-align-middle"/>,
			tooltip: model.message
		}
	}else{
		obj={
			icon: <LoadingOutlined className="deployment-icon ns-primary-element ns-vertical-align-middle" spin/>,
			tooltip: `Model is in ${model.trainingStatus} state`
		}
	}
	return obj;
}

export const modelParseOptions = [
	{
		key: "response",
		value: "response",
		label: "Output Format",
		icon: "icon icon-transfer ns-btn-icon"
	},
	{
		key: "code",
		value: "code",
		label: "Code",
		icon: "icon icon-code ns-btn-icon"
	}
]

export const modelParseResponseOptions = [
	{
		key: "neuralspace",
		value: "neuralspace",
		label: BRAND_NAME,
	},
	{
		key: "dialogflow",
		value: "dialogflow",
		label: "Dialogflow",
	},
	{
		key: "wit",
		value: "wit",
		label: "Wit",
	},
	{
		key: "luis",
		value: "luis",
		label: "Luis",
	}
]

export const getModelFeedbackParams = search => {
	let params={
		entity: "All",
		intent: "All",
		intentRange: [],
		entityRange: [],
		pageNumber: "1",
		pageSize: "15"
	};
	const decodeSearch = decodeURIComponent(search);
  	const query = new URLSearchParams(decodeSearch);
	if (query.get("entity")) params.entity = decodeUriValueConvertor(query.get("entity"));
	if (query.get("intent")) params.intent = decodeUriValueConvertor(query.get("intent"));
	if(query.get("intentRange")) params.intentRange = query.get("intentRange").split("-");
	if(query.get("entityRange")) params.entityRange = query.get("entityRange").split("-");
	if(query.get("page")) params.pageNumber = parseInt(query.get("page"));
	if(query.get("limit")) params.pageSize = parseInt(query.get("limit"));
	return params;
}

export const getModelAnalyticsParams = search => {
	let params = {
		type: "Intent",
		pageNumber: "1",
		pageSize: "15"
	}
	const query = new URLSearchParams(search);
	if(query.get("type")) params.type = query.get("type");
	if(query.get("page")) params.pageNumber = parseInt(query.get("page"));
	if(query.get("limit")) params.pageSize = parseInt(query.get("limit"));
	return params;
}

