export const reducerName = "nerDatasets";
export const nerDatasetState = {
  loading: false,
  count: 0,
  datasets: [],
  datasetEntities: [],
  datasetEntityCounts: [],
  datasetDomains: [],
  datasetLoading: false,
  uploadCount: 0,
  uploads: [],
  importCount: 0,
  imports: [],
  importsListLoading: false,
  uploadsListLoading: false,
  oneUpload: {},
  createProjectDatasetLoading: false,
  createProjectDatasetCount: 0,
  createProjectDataset: []
}


export const DATASET_PARAMS_CHANGE = {
  type: "NER_DATASET_PARAMS_CHANGE",
  name: "nerdatasetParamsChange"
};

export const UPLOAD_DATASET = {
  type: "NER_UPLOAD_DATASET",
  name: "neruploadDataset"
}

export const IMPORT_DATASET = {
  type: "NER_IMPORT_DATASET",
  name: "nerimportDataset"
}

export const ONE_UPLOAD = {
  type: "NER_ONE_UPLOAD",
  name: "neroneUpload"
}

export const DATASET_PARAMS_CHANGE_WITHOUT_ASYNC = {
  type: "NER_DATASET_PARAMS_CHANGE_WITHOUT_ASYNC",
  name: "nerdatasetParamsChangeWithoutAsync"
};

export const FETCH_DATASETS_CREATE_PROJECT = {
  type: "NER_FETCH_DATASETS_CREATE_PROJECT",
  name: "nerfetchDatasetsCreateProject"
};

export const FETCH_DATASETS = {
  type: "NER_FETCH_DATASETS",
  name: "nerfetchDatasets"
};

export const FETCH_UPLOAD_LISTS = {
  type: "NER_FETCH_UPLOAD_LISTS",
  name: "nerfetchUploadLists"
}

export const FETCH_IMPORT_LISTS = {
  type: "NER_FETCH_IMPORT_LISTS",
  name: "nerfetchImportList"
}