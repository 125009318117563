import { reverseLangMap } from "../ui";

// export const languagesSupported = ["en", "hi"]; // To be fetched from a constants file or server side
export const uploadFileSize = { value: 1024 * 1024 * 50 };
export const supportedFileTypes = ["3gp","aa","aac","aax","act","aiff","alac","amr","ape","au","awb","dss","dvf","flac","gsm","iklax","ivs","m4a","m4b","m4p","mmf","mp3","mpc","msv","nmf","ogg","opus","ra","raw","rf64","sln","tta","voc","vox","wav","wma","wv","webm","8svx","cda"];
export const supportedFileTypesExtensions = ".3gp, .aa, .aac, .aax, .act, .aiff, .alac, .amr, .ape, .au, .awb, .dss, .dvf, .flac, .gsm, .iklax, .ivs, .m4a, .m4b, .m4p, .mmf, .mp3, .mpc, .msv, .nmf, .ogg, .opus, .ra, .raw, .rf64, .sln, .tta, .voc, .vox, .wav, .wma, .wv, .webm, .8svx, .cda";
export const totalUploadFileSizeLimit = 1000 * 1000 * 500;

const sttLanguageDomainModelMapDictation = {
    "en": [
        {
            "modelPath": "",
            "domain": "general",
            "accent": "Multilingual",
            "suburl": "en-16",
            "sampleRate": 16000,
            "description": "Select this domain if you need transcriptions that are accurate across domains. This is the recommended default choice."
        }
    ],
    "hi": [
        {
            "modelPath": "",
            "domain": "general",
            "accent": "Multilingual",
            "suburl": "hi-16",
            "sampleRate": 16000,
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "ar": [
        {
            "modelPath": "",
            "domain": "general",
            "accent": "Multilingual",
            "suburl": "ar-16",
            "sampleRate": 16000,
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "",
            "domain": "saudi-arabic",
            "accent": "Saudi Arabic",
            "suburl": "ar-sa-16",
            "sampleRate": 16000,
            "description": "Select this domain if you need transcriptions for Saudi Arabic speech."
        }
    ]
}

const sttLanguageDomainModelMap = {
    "en": [
        {
            "modelPath": "medium",
            "sampleRate": 16000,
            "suburl": "en-wh-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio",
            "domain": "multilingual-v1"
        },
        {
            "modelPath": "en-AU:default",
            "domain": "general-v3-australia-default",
            "suburl": "en-AU-default-v3-16",
            "sampleRate": 16000,
            "accent": "Australia",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "en-AU:latest_long",
            "domain": "general-v3-australia-latest_long",
            "suburl": "en-AU-latest_long-v3-16",
            "sampleRate": 16000,
            "accent": "Australia",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "en-CA:default",
            "domain": "general-v3-canada-default",
            "suburl": "en-CA-default-v3-16",
            "sampleRate": 16000,
            "accent": "Canada",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "en-GH:default",
            "domain": "general-v3-ghana-default",
            "suburl": "en-GH-default-v3-16",
            "sampleRate": 16000,
            "accent": "Ghana",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "en-HK:default",
            "domain": "general-v3-hong_kong-default",
            "suburl": "en-HK-default-v3-16",
            "sampleRate": 16000,
            "accent": "Hong Kong",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "en-IN:default",
            "domain": "general-v3-india-default",
            "suburl": "en-IN-default-v3-16",
            "sampleRate": 16000,
            "accent": "India",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "en-IN:latest_long",
            "domain": "general-v3-india-latest_long",
            "suburl": "en-IN-latest_long-v3-16",
            "sampleRate": 16000,
            "accent": "India",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "en-IE:default",
            "domain": "general-v3-ireland-default",
            "suburl": "en-IE-default-v3-16",
            "sampleRate": 16000,
            "accent": "Ireland",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "en-KE:default",
            "domain": "general-v3-kenya-default",
            "suburl": "en-KE-default-v3-16",
            "sampleRate": 16000,
            "accent": "Kenya",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "en-NZ:default",
            "domain": "general-v3-new_zealand-default",
            "suburl": "en-NZ-default-v3-16",
            "sampleRate": 16000,
            "accent": "New Zealand",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "en-NG:default",
            "domain": "general-v3-nigeria-default",
            "suburl": "en-NG-default-v3-16",
            "sampleRate": 16000,
            "accent": "Nigeria",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "en-PK:default",
            "domain": "general-v3-pakistan-default",
            "suburl": "en-PK-default-v3-16",
            "sampleRate": 16000,
            "accent": "Pakistan",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "en-PH:default",
            "domain": "general-v3-philippines-default",
            "suburl": "en-PH-default-v3-16",
            "sampleRate": 16000,
            "accent": "Philippines",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "en-SG:default",
            "domain": "general-v3-singapore-default",
            "suburl": "en-SG-default-v3-16",
            "sampleRate": 16000,
            "accent": "Singapore",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "en-ZA:default",
            "domain": "general-v3-south_africa-default",
            "suburl": "en-ZA-default-v3-16",
            "sampleRate": 16000,
            "accent": "South Africa",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "en-TZ:default",
            "domain": "general-v3-tanzania-default",
            "suburl": "en-TZ-default-v3-16",
            "sampleRate": 16000,
            "accent": "Tanzania",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "en-GB:default",
            "domain": "general-v3-united_kingdom-default",
            "suburl": "en-GB-default-v3-16",
            "sampleRate": 16000,
            "accent": "United Kingdom",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "en-GB:latest_long",
            "domain": "general-v3-united_kingdom-latest_long",
            "suburl": "en-GB-latest_long-v3-16",
            "sampleRate": 16000,
            "accent": "United Kingdom",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "en-US:default",
            "domain": "general-v3-united_states-default",
            "suburl": "en-US-default-v3-16",
            "sampleRate": 16000,
            "accent": "United States",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "en-US:latest_long",
            "domain": "general-v3-united_states-latest_long",
            "suburl": "en-US-latest_long-v3-16",
            "sampleRate": 16000,
            "accent": "United States",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "hi": [
        {
            "modelPath": "medium",
            "sampleRate": 16000,
            "suburl": "hi-wh-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio",
            "domain": "multilingual-v1"
        },
        {
            "modelPath": "hi-IN:default",
            "domain": "general-v3-india-default",
            "suburl": "hi-IN-default-v3-16",
            "sampleRate": 16000,
            "accent": "India",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "hi-IN:latest_long",
            "domain": "general-v3-india-latest_long",
            "suburl": "hi-IN-latest_long-v3-16",
            "sampleRate": 16000,
            "accent": "India",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "stt_model/hi/whisper/14-03-2023-05-38-vasista22-whisper-hindi-medium.zip",
            "domain": "multilingual-v2",
            "sampleRate": 16000,
            "suburl": "hi-hf-16"
        }
    ],
    "sv": [
        {
            "modelPath": "medium",
            "sampleRate": 16000,
            "suburl": "sv-wh-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio",
            "domain": "multilingual-v1"
        },
        {
            "modelPath": "sv-SE:default",
            "domain": "general-v3-sweden-default",
            "suburl": "sv-SE-default-v3-16",
            "sampleRate": 16000,
            "accent": "Sweden",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "ar": [
        {
            "modelPath": "stt_model/04-05-2023-08-01-ns-further-finetuned-ar-gsm-robust-whisper-medium-6k-5k-hrs.zip",
            "sampleRate": 16000,
            "suburl": "ar-v3-hf-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio with our updated V3 model",
            "domain": "multilingual-v3"
        },
        {
            "modelPath": "stt_model/ar/whisper/28-03-2023-07-55-ns-finetuned-ar-general-whisper-medium-6k-hrs.zip",
            "sampleRate": 16000,
            "suburl": "ar-hf-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio with V2 model",
            "domain": "multilingual-v2"
        },
        {
            "modelPath": "stt_model/ar/whisper/28-03-2023-12-16-ns-finetuned-ar-saudi-whisper-medium-1khrs.zip",
            "sampleRate": 16000,
            "suburl": "ar-sa-hf-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio for saudi arabic accent",
            "domain": "saudi-arabic",
            "accent": "Saudi Arabic"
        },
        {
            "modelPath": "stt_model/ar/whisper/22-06-2023-10-07-whisper_ft_ar_egy_65k.zip",
            "sampleRate": 16000,
            "suburl": "ar-egy-hf-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio for Egyptian dialect",
            "domain": "Egyptian",
            "accent": "Egyptian"
        }
    ],
    "ru": [
        {
            "modelPath": "medium",
            "sampleRate": 16000,
            "suburl": "ru-wh-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio",
            "domain": "multilingual-v1"
        },
        {
            "modelPath": "ru-RU:default",
            "domain": "general-v3-russia-default",
            "suburl": "ru-RU-default-v3-16",
            "sampleRate": 16000,
            "accent": "Russia",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "ru-RU:latest_long",
            "domain": "general-v3-russia-latest_long",
            "suburl": "ru-RU-latest_long-v3-16",
            "sampleRate": 16000,
            "accent": "Russia",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "fr": [
        {
            "modelPath": "medium",
            "sampleRate": 16000,
            "suburl": "fr-wh-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio",
            "domain": "multilingual-v1"
        },
        {
            "modelPath": "fr-BE:default",
            "domain": "general-v3-belgium-default",
            "suburl": "fr-BE-default-v3-16",
            "sampleRate": 16000,
            "accent": "Belgium",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "fr-CA:default",
            "domain": "general-v3-canada-default",
            "suburl": "fr-CA-default-v3-16",
            "sampleRate": 16000,
            "accent": "Canada",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "fr-CA:latest_long",
            "domain": "general-v3-canada-latest_long",
            "suburl": "fr-CA-latest_long-v3-16",
            "sampleRate": 16000,
            "accent": "Canada",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "fr-FR:default",
            "domain": "general-v3-france-default",
            "suburl": "fr-FR-default-v3-16",
            "sampleRate": 16000,
            "accent": "France",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "fr-FR:latest_long",
            "domain": "general-v3-france-latest_long",
            "suburl": "fr-FR-latest_long-v3-16",
            "sampleRate": 16000,
            "accent": "France",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "fr-CH:default",
            "domain": "general-v3-switzerland-default",
            "suburl": "fr-CH-default-v3-16",
            "sampleRate": 16000,
            "accent": "Switzerland",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "tl": [
        {
            "modelPath": "medium",
            "sampleRate": 16000,
            "suburl": "tl-wh-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio",
            "domain": "multilingual-v1"
        },
        {
            "modelPath": "stt_model/tl/whisper/20-06-2023-12-56-tagalog-nsft.zip",
            "sampleRate": 16000,
            "suburl": "tl-v3-wh-16",
            "description": "Select this domain if you need transcriptions for Tagalog model",
            "domain": "multilingual-v3"
        } 
    ],
    "uk": [
        {
            "modelPath": "medium",
            "sampleRate": 16000,
            "suburl": "uk-wh-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio",
            "domain": "multilingual-v1"
        },
        {
            "modelPath": "uk-UA:default",
            "domain": "general-v3-ukraine-default",
            "suburl": "uk-UA-default-v3-16",
            "sampleRate": 16000,
            "accent": "Ukraine",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "uk-UA:latest_long",
            "domain": "general-v3-ukraine-latest_long",
            "suburl": "uk-UA-latest_long-v3-16",
            "sampleRate": 16000,
            "accent": "Ukraine",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "de": [
        {
            "modelPath": "medium",
            "sampleRate": 16000,
            "suburl": "de-wh-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio",
            "domain": "multilingual-v1"
        },
        {
            "modelPath": "de-AT:default",
            "domain": "general-v3-austria-default",
            "suburl": "de-AT-default-v3-16",
            "sampleRate": 16000,
            "accent": "Austria",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "de-DE:default",
            "domain": "general-v3-germany-default",
            "suburl": "de-DE-default-v3-16",
            "sampleRate": 16000,
            "accent": "Germany",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "de-DE:latest_long",
            "domain": "general-v3-germany-latest_long",
            "suburl": "de-DE-latest_long-v3-16",
            "sampleRate": 16000,
            "accent": "Germany",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "de-CH:default",
            "domain": "general-v3-switzerland-default",
            "suburl": "de-CH-default-v3-16",
            "sampleRate": 16000,
            "accent": "Switzerland",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "el": [
        {
            "modelPath": "medium",
            "sampleRate": 16000,
            "suburl": "el-wh-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio",
            "domain": "multilingual-v1"
        },
        {
            "modelPath": "el-GR:default",
            "domain": "general-v3-greece-default",
            "suburl": "el-GR-default-v3-16",
            "sampleRate": 16000,
            "accent": "Greece",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "fa": [
        {
            "modelPath": "fa-IR:default",
            "domain": "general-v3-iran-default",
            "suburl": "fa-IR-default-v3-16",
            "sampleRate": 16000,
            "accent": "Iran",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "nl": [
        {
            "modelPath": "medium",
            "sampleRate": 16000,
            "suburl": "nl-wh-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio",
            "domain": "multilingual-v1"
        },
        {
            "modelPath": "nl-BE:default",
            "domain": "general-v3-belgium-default",
            "suburl": "nl-BE-default-v3-16",
            "sampleRate": 16000,
            "accent": "Belgium",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "nl-NL:default",
            "domain": "general-v3-netherlands-default",
            "suburl": "nl-NL-default-v3-16",
            "sampleRate": 16000,
            "accent": "Netherlands",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "nl-NL:latest_long",
            "domain": "general-v3-netherlands-latest_long",
            "suburl": "nl-NL-latest_long-v3-16",
            "sampleRate": 16000,
            "accent": "Netherlands",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "pt": [
        {
            "modelPath": "medium",
            "sampleRate": 16000,
            "suburl": "pt-wh-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio",
            "domain": "multilingual-v1"
        },
        {
            "modelPath": "pt-BR:default",
            "domain": "general-v3-brazil-default",
            "suburl": "pt-BR-default-v3-16",
            "sampleRate": 16000,
            "accent": "Brazil",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "pt-BR:latest_long",
            "domain": "general-v3-brazil-latest_long",
            "suburl": "pt-BR-latest_long-v3-16",
            "sampleRate": 16000,
            "accent": "Brazil",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "pt-PT:default",
            "domain": "general-v3-portugal-default",
            "suburl": "pt-PT-default-v3-16",
            "sampleRate": 16000,
            "accent": "Portugal",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "pt-PT:latest_long",
            "domain": "general-v3-portugal-latest_long",
            "suburl": "pt-PT-latest_long-v3-16",
            "sampleRate": 16000,
            "accent": "Portugal",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "es": [
        {
            "modelPath": "medium",
            "sampleRate": 16000,
            "suburl": "es-wh-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio",
            "domain": "multilingual-v1"
        },
        {
            "modelPath": "es-AR:default",
            "domain": "general-v3-argentina-default",
            "suburl": "es-AR-default-v3-16",
            "sampleRate": 16000,
            "accent": "Argentina",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "es-BO:default",
            "domain": "general-v3-bolivia-default",
            "suburl": "es-BO-default-v3-16",
            "sampleRate": 16000,
            "accent": "Bolivia",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "es-CL:default",
            "domain": "general-v3-chile-default",
            "suburl": "es-CL-default-v3-16",
            "sampleRate": 16000,
            "accent": "Chile",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "es-CO:default",
            "domain": "general-v3-colombia-default",
            "suburl": "es-CO-default-v3-16",
            "sampleRate": 16000,
            "accent": "Colombia",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "es-CR:default",
            "domain": "general-v3-costa_rica-default",
            "suburl": "es-CR-default-v3-16",
            "sampleRate": 16000,
            "accent": "Costa Rica",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "es-DO:default",
            "domain": "general-v3-dominican_republic-default",
            "suburl": "es-DO-default-v3-16",
            "sampleRate": 16000,
            "accent": "Dominican Republic",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "es-EC:default",
            "domain": "general-v3-ecuador-default",
            "suburl": "es-EC-default-v3-16",
            "sampleRate": 16000,
            "accent": "Ecuador",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "es-SV:default",
            "domain": "general-v3-el_salvador-default",
            "suburl": "es-SV-default-v3-16",
            "sampleRate": 16000,
            "accent": "El Salvador",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "es-GT:default",
            "domain": "general-v3-guatemala-default",
            "suburl": "es-GT-default-v3-16",
            "sampleRate": 16000,
            "accent": "Guatemala",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "es-HN:default",
            "domain": "general-v3-honduras-default",
            "suburl": "es-HN-default-v3-16",
            "sampleRate": 16000,
            "accent": "Honduras",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "es-MX:default",
            "domain": "general-v3-mexico-default",
            "suburl": "es-MX-default-v3-16",
            "sampleRate": 16000,
            "accent": "Mexico",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "es-NI:default",
            "domain": "general-v3-nicaragua-default",
            "suburl": "es-NI-default-v3-16",
            "sampleRate": 16000,
            "accent": "Nicaragua",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "es-PA:default",
            "domain": "general-v3-panama-default",
            "suburl": "es-PA-default-v3-16",
            "sampleRate": 16000,
            "accent": "Panama",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "es-PY:default",
            "domain": "general-v3-paraguay-default",
            "suburl": "es-PY-default-v3-16",
            "sampleRate": 16000,
            "accent": "Paraguay",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "es-PE:default",
            "domain": "general-v3-peru-default",
            "suburl": "es-PE-default-v3-16",
            "sampleRate": 16000,
            "accent": "Peru",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "es-PR:default",
            "domain": "general-v3-puerto_rico-default",
            "suburl": "es-PR-default-v3-16",
            "sampleRate": 16000,
            "accent": "Puerto Rico",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "es-ES:default",
            "domain": "general-v3-spain-default",
            "suburl": "es-ES-default-v3-16",
            "sampleRate": 16000,
            "accent": "Spain",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "es-ES:latest_long",
            "domain": "general-v3-spain-latest_long",
            "suburl": "es-ES-latest_long-v3-16",
            "sampleRate": 16000,
            "accent": "Spain",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "es-US:default",
            "domain": "general-v3-united_states-default",
            "suburl": "es-US-default-v3-16",
            "sampleRate": 16000,
            "accent": "United States",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "es-US:latest_long",
            "domain": "general-v3-united_states-latest_long",
            "suburl": "es-US-latest_long-v3-16",
            "sampleRate": 16000,
            "accent": "United States",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "es-UY:default",
            "domain": "general-v3-uruguay-default",
            "suburl": "es-UY-default-v3-16",
            "sampleRate": 16000,
            "accent": "Uruguay",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "es-VE:default",
            "domain": "general-v3-venezuela-default",
            "suburl": "es-VE-default-v3-16",
            "sampleRate": 16000,
            "accent": "Venezuela",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "ca": [
        {
            "modelPath": "medium",
            "sampleRate": 16000,
            "suburl": "ca-wh-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio",
            "domain": "multilingual-v1"
        },
        {
            "modelPath": "ca-ES:default",
            "domain": "general-v3-spain-default",
            "suburl": "ca-ES-default-v3-16",
            "sampleRate": 16000,
            "accent": "Spain",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "cs": [
        {
            "modelPath": "medium",
            "sampleRate": 16000,
            "suburl": "cs-wh-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio",
            "domain": "multilingual-v1"
        },
        {
            "modelPath": "cs-CZ:default",
            "domain": "general-v3-czech_republic-default",
            "suburl": "cs-CZ-default-v3-16",
            "sampleRate": 16000,
            "accent": "Czech Republic",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "ja": [
        {
            "modelPath": "medium",
            "sampleRate": 16000,
            "suburl": "ja-wh-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio",
            "domain": "multilingual-v1"
        },
        {
            "modelPath": "ja-JP:default",
            "domain": "general-v3-japan-default",
            "suburl": "ja-JP-default-v3-16",
            "sampleRate": 16000,
            "accent": "Japan",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "ja-JP:latest_long",
            "domain": "general-v3-japan-latest_long",
            "suburl": "ja-JP-latest_long-v3-16",
            "sampleRate": 16000,
            "accent": "Japan",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "kk": [
        {
            "modelPath": "kk-KZ:default",
            "domain": "general-v3-kazakhstan-default",
            "suburl": "kk-KZ-default-v3-16",
            "sampleRate": 16000,
            "accent": "Kazakhstan",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "tr": [
        {
            "modelPath": "medium",
            "sampleRate": 16000,
            "suburl": "tr-wh-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio",
            "domain": "multilingual-v1"
        },
        {
            "modelPath": "tr-TR:default",
            "domain": "general-v3-turkey-default",
            "suburl": "tr-TR-default-v3-16",
            "sampleRate": 16000,
            "accent": "Turkey",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "tr-TR:latest_long",
            "domain": "general-v3-turkey-latest_long",
            "suburl": "tr-TR-latest_long-v3-16",
            "sampleRate": 16000,
            "accent": "Turkey",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "it": [
        {
            "modelPath": "medium",
            "sampleRate": 16000,
            "suburl": "it-wh-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio",
            "domain": "multilingual-v1"
        },
        {
            "modelPath": "it-IT:default",
            "domain": "general-v3-italy-default",
            "suburl": "it-IT-default-v3-16",
            "sampleRate": 16000,
            "accent": "Italy",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "it-IT:latest_long",
            "domain": "general-v3-italy-latest_long",
            "suburl": "it-IT-latest_long-v3-16",
            "sampleRate": 16000,
            "accent": "Italy",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "it-CH:default",
            "domain": "general-v3-switzerland-default",
            "suburl": "it-CH-default-v3-16",
            "sampleRate": 16000,
            "accent": "Switzerland",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "zh": [
        {
            "modelPath": "medium",
            "sampleRate": 16000,
            "suburl": "zh-wh-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio",
            "domain": "multilingual-v1"
        }
    ],
    "af": [
        {
            "modelPath": "af-ZA:default",
            "domain": "general-v3-south_africa-default",
            "suburl": "af-ZA-default-v3-16",
            "sampleRate": 16000,
            "accent": "South Africa",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "am": [
        {
            "modelPath": "am-ET:default",
            "domain": "general-v3-ethiopia-default",
            "suburl": "am-ET-default-v3-16",
            "sampleRate": 16000,
            "accent": "Ethiopia",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "az": [
        {
            "modelPath": "az-AZ:default",
            "domain": "general-v3-azerbaijan-default",
            "suburl": "az-AZ-default-v3-16",
            "sampleRate": 16000,
            "accent": "Azerbaijan",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "bg": [
        {
            "modelPath": "medium",
            "sampleRate": 16000,
            "suburl": "bg-wh-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio",
            "domain": "multilingual-v1"
        },
        {
            "modelPath": "bg-BG:default",
            "domain": "general-v3-bulgaria-default",
            "suburl": "bg-BG-default-v3-16",
            "sampleRate": 16000,
            "accent": "Bulgaria",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "bn": [
        {
            "modelPath": "bn-BD:default",
            "domain": "general-v3-bangladesh-default",
            "suburl": "bn-BD-default-v3-16",
            "sampleRate": 16000,
            "accent": "Bangladesh",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "bn-IN:default",
            "domain": "general-v3-india-default",
            "suburl": "bn-IN-default-v3-16",
            "sampleRate": 16000,
            "accent": "India",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "stt_model/hi/whisper/14-03-2023-08-27-anuragshas-whisper-small-bn.zip",
            "domain": "multilingual-v2",
            "sampleRate": 16000,
            "suburl": "bn-hf-16"
        }
    ],
    "bs": [
        {
            "modelPath": "medium",
            "sampleRate": 16000,
            "suburl": "bs-wh-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio",
            "domain": "multilingual-v1"
        },
        {
            "modelPath": "bs-BA:default",
            "domain": "general-v3-bosnia_and_herzegovina-default",
            "suburl": "bs-BA-default-v3-16",
            "sampleRate": 16000,
            "accent": "Bosnia And Herzegovina",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "da": [
        {
            "modelPath": "medium",
            "sampleRate": 16000,
            "suburl": "da-wh-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio",
            "domain": "multilingual-v1"
        },
        {
            "modelPath": "da-DK:default",
            "domain": "general-v3-denmark-default",
            "suburl": "da-DK-default-v3-16",
            "sampleRate": 16000,
            "accent": "Denmark",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "da-DK:latest_long",
            "domain": "general-v3-denmark-latest_long",
            "suburl": "da-DK-latest_long-v3-16",
            "sampleRate": 16000,
            "accent": "Denmark",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "et": [
        {
            "modelPath": "et-EE:default",
            "domain": "general-v3-estonia-default",
            "suburl": "et-EE-default-v3-16",
            "sampleRate": 16000,
            "accent": "Estonia",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "eu": [
        {
            "modelPath": "eu-ES:default",
            "domain": "general-v3-spain-default",
            "suburl": "eu-ES-default-v3-16",
            "sampleRate": 16000,
            "accent": "Spain",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "fi": [
        {
            "modelPath": "medium",
            "sampleRate": 16000,
            "suburl": "fi-wh-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio",
            "domain": "multilingual-v1"
        },
        {
            "modelPath": "fi-FI:default",
            "domain": "general-v3-finland-default",
            "suburl": "fi-FI-default-v3-16",
            "sampleRate": 16000,
            "accent": "Finland",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "fi-FI:latest_long",
            "domain": "general-v3-finland-latest_long",
            "suburl": "fi-FI-latest_long-v3-16",
            "sampleRate": 16000,
            "accent": "Finland",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "gl": [
        {
            "modelPath": "medium",
            "sampleRate": 16000,
            "suburl": "gl-wh-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio",
            "domain": "multilingual-v1"
        },
        {
            "modelPath": "gl-ES:default",
            "domain": "general-v3-spain-default",
            "suburl": "gl-ES-default-v3-16",
            "sampleRate": 16000,
            "accent": "Spain",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "gu": [
        {
            "modelPath": "gu-IN:default",
            "domain": "general-v3-india-default",
            "suburl": "gu-IN-default-v3-16",
            "sampleRate": 16000,
            "accent": "India",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "stt_model/hi/whisper/14-03-2023-06-05-vasista22-whisper-gujarati-medium.zip",
            "domain": "multilingual-v2",
            "sampleRate": 16000,
            "suburl": "gu-hf-16"
        }
    ],
    "hr": [
        {
            "modelPath": "medium",
            "sampleRate": 16000,
            "suburl": "hr-wh-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio",
            "domain": "multilingual-v1"
        },
        {
            "modelPath": "hr-HR:default",
            "domain": "general-v3-croatia-default",
            "suburl": "hr-HR-default-v3-16",
            "sampleRate": 16000,
            "accent": "Croatia",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "hu": [
        {
            "modelPath": "hu-HU:default",
            "domain": "general-v3-hungary-default",
            "suburl": "hu-HU-default-v3-16",
            "sampleRate": 16000,
            "accent": "Hungary",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "hy": [
        {
            "modelPath": "hy-AM:default",
            "domain": "general-v3-armenia-default",
            "suburl": "hy-AM-default-v3-16",
            "sampleRate": 16000,
            "accent": "Armenia",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "id": [
        {
            "modelPath": "id-ID:default",
            "domain": "general-v3-indonesia-default",
            "suburl": "id-ID-default-v3-16",
            "sampleRate": 16000,
            "accent": "Indonesia",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "id-ID:latest_long",
            "domain": "general-v3-indonesia-latest_long",
            "suburl": "id-ID-latest_long-v3-16",
            "sampleRate": 16000,
            "accent": "Indonesia",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "is": [
        {
            "modelPath": "is-IS:default",
            "domain": "general-v3-iceland-default",
            "suburl": "is-IS-default-v3-16",
            "sampleRate": 16000,
            "accent": "Iceland",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "jv": [
        {
            "modelPath": "jv-ID:default",
            "domain": "general-v3-indonesia-default",
            "suburl": "jv-ID-default-v3-16",
            "sampleRate": 16000,
            "accent": "Indonesia",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "ka": [
        {
            "modelPath": "ka-GE:default",
            "domain": "general-v3-georgia-default",
            "suburl": "ka-GE-default-v3-16",
            "sampleRate": 16000,
            "accent": "Georgia",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "km": [
        {
            "modelPath": "km-KH:default",
            "domain": "general-v3-cambodia-default",
            "suburl": "km-KH-default-v3-16",
            "sampleRate": 16000,
            "accent": "Cambodia",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "kn": [
        {
            "modelPath": "kn-IN:default",
            "domain": "general-v3-india-default",
            "suburl": "kn-IN-default-v3-16",
            "sampleRate": 16000,
            "accent": "India",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "stt_model/hi/whisper/14-03-2023-06-33-vasista22-whisper-kannada-medium.zip",
            "domain": "multilingual-v2",
            "sampleRate": 16000,
            "suburl": "kn-hf-16"
        }
    ],
    "ko": [
        {
            "modelPath": "medium",
            "sampleRate": 16000,
            "suburl": "ko-wh-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio",
            "domain": "multilingual-v1"
        },
        {
            "modelPath": "ko-KR:default",
            "domain": "general-v3-south_korea-default",
            "suburl": "ko-KR-default-v3-16",
            "sampleRate": 16000,
            "accent": "South Korea",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "ko-KR:latest_long",
            "domain": "general-v3-south_korea-latest_long",
            "suburl": "ko-KR-latest_long-v3-16",
            "sampleRate": 16000,
            "accent": "South Korea",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "lo": [
        {
            "modelPath": "lo-LA:default",
            "domain": "general-v3-laos-default",
            "suburl": "lo-LA-default-v3-16",
            "sampleRate": 16000,
            "accent": "Laos",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "lt": [
        {
            "modelPath": "lt-LT:default",
            "domain": "general-v3-lithuania-default",
            "suburl": "lt-LT-default-v3-16",
            "sampleRate": 16000,
            "accent": "Lithuania",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "lv": [
        {
            "modelPath": "lv-LV:default",
            "domain": "general-v3-latvia-default",
            "suburl": "lv-LV-default-v3-16",
            "sampleRate": 16000,
            "accent": "Latvia",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "mk": [
        {
            "modelPath": "mk-MK:default",
            "domain": "general-v3-north_macedonia-default",
            "suburl": "mk-MK-default-v3-16",
            "sampleRate": 16000,
            "accent": "North Macedonia",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "mk-MK:latest_long",
            "domain": "general-v3-north_macedonia-latest_long",
            "suburl": "mk-MK-latest_long-v3-16",
            "sampleRate": 16000,
            "accent": "North Macedonia",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "ml": [
        {
            "modelPath": "ml-IN:default",
            "domain": "general-v3-india-default",
            "suburl": "ml-IN-default-v3-16",
            "sampleRate": 16000,
            "accent": "India",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "stt_model/hi/whisper/14-03-2023-08-32-thennal-whisper-medium-ml.zip",
            "domain": "multilingual-v2",
            "sampleRate": 16000,
            "suburl": "ml-hf-16"
        }
    ],
    "mn": [
        {
            "modelPath": "mn-MN:default",
            "domain": "general-v3-mongolia-default",
            "suburl": "mn-MN-default-v3-16",
            "sampleRate": 16000,
            "accent": "Mongolia",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "mr": [
        {
            "modelPath": "mr-IN:default",
            "domain": "general-v3-india-default",
            "suburl": "mr-IN-default-v3-16",
            "sampleRate": 16000,
            "accent": "India",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "stt_model/hi/whisper/14-03-2023-07-58-shripadbhat-whisper-medium-mr.zip",
            "domain": "multilingual-v2",
            "sampleRate": 16000,
            "suburl": "mr-hf-16"
        }
    ],
    "ms": [
        {
            "modelPath": "medium",
            "sampleRate": 16000,
            "suburl": "ms-wh-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio",
            "domain": "multilingual-v1"
        },
        {
            "modelPath": "ms-MY:default",
            "domain": "general-v3-malaysia-default",
            "suburl": "ms-MY-default-v3-16",
            "sampleRate": 16000,
            "accent": "Malaysia",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "my": [
        {
            "modelPath": "my-MM:default",
            "domain": "general-v3-myanmar-default",
            "suburl": "my-MM-default-v3-16",
            "sampleRate": 16000,
            "accent": "Myanmar",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "ne": [
        {
            "modelPath": "ne-NP:default",
            "domain": "general-v3-nepal-default",
            "suburl": "ne-NP-default-v3-16",
            "sampleRate": 16000,
            "accent": "Nepal",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "no": [
        {
            "modelPath": "medium",
            "sampleRate": 16000,
            "suburl": "no-wh-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio",
            "domain": "multilingual-v1"
        },
        {
            "modelPath": "no-NO:default",
            "domain": "general-v3-norway-default",
            "suburl": "no-NO-default-v3-16",
            "sampleRate": 16000,
            "accent": "Norway",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "no-NO:latest_long",
            "domain": "general-v3-norway-latest_long",
            "suburl": "no-NO-latest_long-v3-16",
            "sampleRate": 16000,
            "accent": "Norway",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "pa": [
        {
            "modelPath": "pa-Guru-IN:default",
            "domain": "general-v3-gurmukhi_india-default",
            "suburl": "pa-Guru-IN-default-v3-16",
            "sampleRate": 16000,
            "accent": "Gurmukhi India",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "stt_model/hi/whisper/14-03-2023-08-23-shripadbhat-whisper-small-pa-IN.zip",
            "domain": "multilingual-v2",
            "sampleRate": 16000,
            "suburl": "pa-hf-16"
        }
    ],
    "pl": [
        {
            "modelPath": "medium",
            "sampleRate": 16000,
            "suburl": "pl-wh-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio",
            "domain": "multilingual-v1"
        },
        {
            "modelPath": "pl-PL:default",
            "domain": "general-v3-poland-default",
            "suburl": "pl-PL-default-v3-16",
            "sampleRate": 16000,
            "accent": "Poland",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "pl-PL:latest_long",
            "domain": "general-v3-poland-latest_long",
            "suburl": "pl-PL-latest_long-v3-16",
            "sampleRate": 16000,
            "accent": "Poland",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "ro": [
        {
            "modelPath": "medium",
            "sampleRate": 16000,
            "suburl": "ro-wh-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio",
            "domain": "multilingual-v1"
        },
        {
            "modelPath": "ro-RO:default",
            "domain": "general-v3-romania-default",
            "suburl": "ro-RO-default-v3-16",
            "sampleRate": 16000,
            "accent": "Romania",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "ro-RO:latest_long",
            "domain": "general-v3-romania-latest_long",
            "suburl": "ro-RO-latest_long-v3-16",
            "sampleRate": 16000,
            "accent": "Romania",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "si": [
        {
            "modelPath": "si-LK:default",
            "domain": "general-v3-sri_lanka-default",
            "suburl": "si-LK-default-v3-16",
            "sampleRate": 16000,
            "accent": "Sri Lanka",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "sk": [
        {
            "modelPath": "medium",
            "sampleRate": 16000,
            "suburl": "sk-wh-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio",
            "domain": "multilingual-v1"
        },
        {
            "modelPath": "sk-SK:default",
            "domain": "general-v3-slovakia-default",
            "suburl": "sk-SK-default-v3-16",
            "sampleRate": 16000,
            "accent": "Slovakia",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "sl": [
        {
            "modelPath": "sl-SI:default",
            "domain": "general-v3-slovenia-default",
            "suburl": "sl-SI-default-v3-16",
            "sampleRate": 16000,
            "accent": "Slovenia",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "sq": [
        {
            "modelPath": "sq-AL:default",
            "domain": "general-v3-albania-default",
            "suburl": "sq-AL-default-v3-16",
            "sampleRate": 16000,
            "accent": "Albania",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "sr": [
        {
            "modelPath": "sr-RS:default",
            "domain": "general-v3-serbia-default",
            "suburl": "sr-RS-default-v3-16",
            "sampleRate": 16000,
            "accent": "Serbia",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "su": [
        {
            "modelPath": "su-ID:default",
            "domain": "general-v3-indonesia-default",
            "suburl": "su-ID-default-v3-16",
            "sampleRate": 16000,
            "accent": "Indonesia",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "sw": [
        {
            "modelPath": "sw-KE:default",
            "domain": "general-v3-kenya-default",
            "suburl": "sw-KE-default-v3-16",
            "sampleRate": 16000,
            "accent": "Kenya",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "sw-TZ:default",
            "domain": "general-v3-tanzania-default",
            "suburl": "sw-TZ-default-v3-16",
            "sampleRate": 16000,
            "accent": "Tanzania",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "ta": [
        {
            "modelPath": "medium",
            "sampleRate": 16000,
            "suburl": "ta-wh-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio",
            "domain": "multilingual-v1"
        },
        {
            "modelPath": "ta-IN:default",
            "domain": "general-v3-india-default",
            "suburl": "ta-IN-default-v3-16",
            "sampleRate": 16000,
            "accent": "India",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "ta-MY:default",
            "domain": "general-v3-malaysia-default",
            "suburl": "ta-MY-default-v3-16",
            "sampleRate": 16000,
            "accent": "Malaysia",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "ta-SG:default",
            "domain": "general-v3-singapore-default",
            "suburl": "ta-SG-default-v3-16",
            "sampleRate": 16000,
            "accent": "Singapore",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "ta-LK:default",
            "domain": "general-v3-sri_lanka-default",
            "suburl": "ta-LK-default-v3-16",
            "sampleRate": 16000,
            "accent": "Sri Lanka",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "stt_model/hi/whisper/14-03-2023-05-53-vasista22-whisper-tamil-medium.zip",
            "domain": "multilingual-v2",
            "sampleRate": 16000,
            "suburl": "ta-hf-16"
        }
    ],
    "te": [
        {
            "modelPath": "te-IN:default",
            "domain": "general-v3-india-default",
            "suburl": "te-IN-default-v3-16",
            "sampleRate": 16000,
            "accent": "India",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "stt_model/hi/whisper/14-03-2023-06-21-vasista22-whisper-telugu-medium.zip",
            "domain": "multilingual-v2",
            "sampleRate": 16000,
            "suburl": "te-hf-16"
        }
    ],
    "th": [
        {
            "modelPath": "medium",
            "sampleRate": 16000,
            "suburl": "th-wh-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio",
            "domain": "multilingual-v1"
        },
        {
            "modelPath": "th-TH:default",
            "domain": "general-v3-thailand-default",
            "suburl": "th-TH-default-v3-16",
            "sampleRate": 16000,
            "accent": "Thailand",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "th-TH:latest_long",
            "domain": "general-v3-thailand-latest_long",
            "suburl": "th-TH-latest_long-v3-16",
            "sampleRate": 16000,
            "accent": "Thailand",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "ur": [
        {
            "modelPath": "medium",
            "sampleRate": 16000,
            "suburl": "ur-wh-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio",
            "domain": "multilingual-v1"
        },
        {
            "modelPath": "ur-IN:default",
            "domain": "general-v3-india-default",
            "suburl": "ur-IN-default-v3-16",
            "sampleRate": 16000,
            "accent": "India",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "ur-PK:default",
            "domain": "general-v3-pakistan-default",
            "suburl": "ur-PK-default-v3-16",
            "sampleRate": 16000,
            "accent": "Pakistan",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "uz": [
        {
            "modelPath": "uz-UZ:default",
            "domain": "general-v3-uzbekistan-default",
            "suburl": "uz-UZ-default-v3-16",
            "sampleRate": 16000,
            "accent": "Uzbekistan",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "vi": [
        {
            "modelPath": "medium",
            "sampleRate": 16000,
            "suburl": "vi-wh-16",
            "description": "Select this domain if you need transcriptions for code mixed multilingual audio",
            "domain": "multilingual-v1"
        },
        {
            "modelPath": "vi-VN:default",
            "domain": "general-v3-vietnam-default",
            "suburl": "vi-VN-default-v3-16",
            "sampleRate": 16000,
            "accent": "Vietnam",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        },
        {
            "modelPath": "vi-VN:latest_long",
            "domain": "general-v3-vietnam-latest_long",
            "suburl": "vi-VN-latest_long-v3-16",
            "sampleRate": 16000,
            "accent": "Vietnam",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ],
    "zu": [
        {
            "modelPath": "zu-ZA:default",
            "domain": "general-v3-south_africa-default",
            "suburl": "zu-ZA-default-v3-16",
            "sampleRate": 16000,
            "accent": "South Africa",
            "description": "Select this domain if you need transcriptions that are accurate across domains."
        }
    ]
}

export const sttLanguages = () =>{
    let langList = [];
    Object.keys(sttLanguageDomainModelMap).forEach(l=>{
        langList.push(reverseLangMap[l]);
    });
    return langList;
}

export const getSupportedLanguages = ()=>{
    let languageList = []
    Object.keys(sttLanguageDomainModelMap).forEach(language => {
        let languageObject = { languageCode: language, languageLabel: reverseLangMap[language] }
        languageList.push(languageObject)
    })
    return languageList
}

export const getSupportedDomains = (language)=>{
    let domainList = [] 
    sttLanguageDomainModelMap[language].forEach(d =>  {
        let domainObj = {}
        if(d.domain){
            domainObj.domain = d.domain
        }
        else{
            domainObj.domain = "common"
        }
        domainObj.suburl = d.suburl
        domainList.push(domainObj)
    });
    return domainList
}

export const getSupportedLanguagesDictation = ()=>{
    let languageList = []
    Object.keys(sttLanguageDomainModelMapDictation).forEach(language => {
        let languageObject = { languageCode: language, languageLabel: reverseLangMap[language] }
        languageList.push(languageObject)
    })
    return languageList
}

export const getSupportedDomainsDictation = (language)=>{
    let domainList = [] 
    sttLanguageDomainModelMapDictation[language].forEach(d =>  {
        let domainObj = {}
        if(d.domain){
            domainObj.domain = d.domain
        }
        else{
            domainObj.domain = "common"
        }
        domainObj.suburl = d.suburl
        domainList.push(domainObj)
    });
    return domainList
}

export const getSupportedSampleRate = (language, suburl) =>{
    let sampleRate = 16000
    sttLanguageDomainModelMap[language].forEach(d =>  {
        if(d.suburl === suburl){
            sampleRate = d.sampleRate
        }
    });
    return sampleRate
}

export function getDomainDescription(suburl, language){
    let desc = ""
    sttLanguageDomainModelMap[language].forEach(d =>  {
        if(d.suburl === suburl){
            desc = d.description
        }
    });
    return desc
}