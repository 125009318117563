import React from "react";

import SectionLoading from "../components/loading/section-loading";
import Loadable from "../utils/loadable";

/* ----------------------------------------Auth components ------------------------------*/
export const Signin = Loadable(()=> import("./auth/signin"),{
  fallback: <SectionLoading size={64} />
});

export const SignUp = Loadable(()=> import("./auth/signup"),{
  fallback: <SectionLoading size={64} />
});

export const EmailVerfication = Loadable(()=> import("./auth/email-verification"),{
  fallback: <SectionLoading size={64} />
});

export const EmailConfirmation = Loadable(()=> import("./auth/email-confirmation"),{
  fallback: <SectionLoading size={64} />
});

export const ForgotPassword = Loadable(()=> import("./auth/forgot-password"),{
  fallback: <SectionLoading size={64} />
});

export const ResetPassword = Loadable(()=> import("./auth/reset-password"),{
  fallback: <SectionLoading size={64} />
});

/* ----------------------------------------Main components ------------------------------*/

export const Dashboard = Loadable(()=> import("./application/common/dashboard"), {
  fallback: <SectionLoading size={64} />
})

export const MainDashboard = Loadable(()=> import("./application/common/dashboard/dashboard"),{
  fallback: <SectionLoading size={64} />
});

export const BlockUser = Loadable(()=> import("./application/common/block/block-user"),{
  fallback: <SectionLoading size={64} />
});

export const Profile = Loadable(()=> import("./application/common/profile/profile"),{
  fallback: <SectionLoading size={64} />
});

export const Settings = Loadable(()=> import("./application/common/settings/settings"),{
  fallback: <SectionLoading size={64} />
});

export const Billing = Loadable(()=> import("./application/common/subscription/billings/billing"),{
  fallback: <SectionLoading size={64} />
});

export const Invoices = Loadable(()=> import("./application/common/subscription/invoices/invoices"),{
  fallback: <SectionLoading size={64} />
});

export const Plans = Loadable(()=> import("./application/common/subscription/plans/plans"),{
  fallback: <SectionLoading size={64} />
});

export const Transactions = Loadable(()=> import("./application/common/subscription/transactions/transactions"),{
  fallback: <SectionLoading size={64} />
});

export const Subscription = Loadable(()=> import("./application/common/subscription"),{
  fallback: <SectionLoading size={64} />
});

export const PricingPage = Loadable(()=> import("./pricing/pricing-page"),{
  fallback: <SectionLoading size={64} />
});


/* ----------------------------------------NLU components ------------------------------*/
export const NluApp = Loadable(()=> import("./application/nlu"),{
  fallback: <SectionLoading size={64} />
});

export const NluDashboard = Loadable(()=> import("./application/nlu/nlu-dashboard/nlu-dashboard"),{
  fallback: <SectionLoading size={64} />
});

export const NluProjects = Loadable(()=> import("./application/nlu/nlu-projects/nlu-projects"),{
  fallback: <SectionLoading size={64} />
});

export const NluProjectDetails = Loadable(()=> import("./application/nlu/nlu-projects/nlu-project-details"),{
  fallback: <SectionLoading size={64} />
});

export const NluDataStudio = Loadable(() => import("./application/nlu/nlu-projects/nlu-data-studio"),{
  fallback: <SectionLoading size={64} />
});

export const NluModelFeedback = Loadable(()=> import("./application/nlu/nlu-projects/models/nlu-model-feedback"),{
  fallback: <SectionLoading size={64} />
});

export const NluModelAnalytics = Loadable(()=> import("./application/nlu/nlu-projects/models/nlu-model-analytics"), {
  fallback: <SectionLoading size={64} />
});

export const NluEntities = Loadable(()=> import("./application/nlu/nlu-entities/nlu-entities"),{
  fallback: <SectionLoading size={64} />
});

export const NluBulkDataset = Loadable(()=> import("./application/nlu/nlu-bulk-dataset/nlu-bulk-dataset"),{
  fallback: <SectionLoading size={64} />
});

export const NluImportDataset = Loadable(()=> import("./application/nlu/nlu-import-dataset/nlu-import-dataset"),{
  fallback: <SectionLoading size={64} />
});

/* ----------------------------------------NER components ------------------------------*/
export const NerApp = Loadable(()=> import("./application/ner"),{
  fallback: <SectionLoading size={64} />
});

export const NerDashboard = Loadable(()=> import("./application/ner/ner-dashboard/ner-dashboard"),{
  fallback: <SectionLoading size={64} />
});

export const NerProjects = Loadable(()=> import("./application/ner/ner-projects/ner-projects"),{
  fallback: <SectionLoading size={64} />
});

export const NerEntities = Loadable(()=> import("./application/ner/ner-entities/ner-entities"),{
  fallback: <SectionLoading size={64} />
});

export const NerProjectDetails = Loadable(()=> import("./application/ner/ner-projects/ner-project-details"),{
  fallback: <SectionLoading size={64} />
});

export const NerDataStudio = Loadable(() => import("./application/ner/ner-projects/ner-data-studio"),{
  fallback: <SectionLoading size={64} />
});

export const NerModelFeedback = Loadable(()=> import("./application/ner/ner-projects/models/ner-model-feedback"),{
  fallback: <SectionLoading size={64} />
});

export const NerModelAnalytics = Loadable(()=> import("./application/ner/ner-projects/models/ner-model-analytics"), {
  fallback: <SectionLoading size={64} />
});

export const NerBulkDataset = Loadable(()=> import("./application/ner/ner-bulk-dataset/ner-bulk-dataset"),{
  fallback: <SectionLoading size={64} />
});

export const NerImportDataset = Loadable(()=> import("./application/ner/ner-import-dataset/ner-import-dataset"),{
  fallback: <SectionLoading size={64} />
});
/* ----------------------------------------Translation components ------------------------------*/
export const TranslationApp = Loadable(()=> import("./application/translation"),{
  fallback: <SectionLoading size={64} />
});

export const TranslationDashboard = Loadable(()=> import("./application/translation/translation-dashboard/translation-dashboard"),{
  fallback: <SectionLoading size={64} />
});

/* ----------------------------------------Transliteration components ------------------------------*/
export const TransliterationApp = Loadable(()=> import("./application/transliteration"),{
  fallback: <SectionLoading size={64} />
});

export const TransliterationDashboard = Loadable(()=> import("./application/transliteration/transliteration-dashboard/transliteration-dashboard"),{
  fallback: <SectionLoading size={64} />
});

export const TransliterationProjects = Loadable(()=> import("./application/transliteration/transliteration-projects/transliteration-projects"),{
  fallback: <SectionLoading size={64} />
});

export const TransliterationProjectDetails = Loadable(()=> import("./application/transliteration/transliteration-projects/transliteration-project-details"),{
  fallback: <SectionLoading size={64} />
});

export const TransliterationDataStudio = Loadable(()=> import("./application/transliteration/transliteration-projects/transliteration-data-studio"),{
  fallback: <SectionLoading size={64} />
});

export const TransliterationFeedback = Loadable(()=> import("./application/transliteration/transliteration-projects/models/transliteration-model-feedback"),{
  fallback: <SectionLoading size={64} />
})

export const TransliterationBulkDatatset = Loadable(()=> import("./application/transliteration/transliteration-bulk-dataset/transliteration-bulk-dataset"), {
  fallback: <SectionLoading size={64} />
});

export const TransliterationImportDataset = Loadable(()=> import("./application/transliteration/transliteration-import-dataset/transliteration-import-dataset"),{
  fallback: <SectionLoading size={64} />
});

/* ----------------------------------------Augmentation components ------------------------------*/
export const AugmentationApp = Loadable(()=> import("./application/augmentation"),{
  fallback: <SectionLoading size={64} />
});

export const AugmentationDashboard = Loadable(()=> import("./application/augmentation/augmentation-dashboard/augmentation-dashboard"),{
  fallback: <SectionLoading size={64} />
});

/* ----------------------------------------Language detection components ------------------------------*/
export const LanguageDetectionApp = Loadable(()=> import("./application/language-detection"),{
  fallback: <SectionLoading size={64} />
});

export const LanguageDetectionDashboard = Loadable(()=> import("./application/language-detection/language-detection-dashboard/language-detection-dashboard"),{
  fallback: <SectionLoading size={64} />
});

/* ----------------------------------------STT components ------------------------------*/
export const SttApp = Loadable(()=> import("./application/stt"),{
  fallback: <SectionLoading size={64} />
});

export const SttDashboard = Loadable(()=> import("./application/stt/stt-dashboard/stt-dashboard"),{
  fallback: <SectionLoading size={64} />
});

export const FileTranscription = Loadable(()=> import("./application/stt/file-transcription"),{
  fallback: <SectionLoading size={64} />
});

export const Dictation = Loadable(()=> import("./application/stt/dictation"),{
  fallback: <SectionLoading size={64} />
});
