import _ from "lodash";

import { settingsState, reducerName, SETTINGS_PARAMS_CHANGE, SETTINGS_LOADING, SET_WINDOW_WIDTH, SET_NAVCOLLAPSED } from "../states/settings-state";
import ReducerFactory from "../../utils/reducer-factory";


const initialState = {...settingsState};
const reducerFactory = new ReducerFactory(reducerName, initialState);

reducerFactory.addAction(SETTINGS_LOADING.type, SETTINGS_LOADING.name,
  status => status, (state,action)=>{
		const newState = _.cloneDeep(state);
    newState.loading = action.data;
    return newState;
  }
);

reducerFactory.addAction(SET_WINDOW_WIDTH.type, SET_WINDOW_WIDTH.name,
  width => width, (state,action) => {
    const newState = _.cloneDeep(state);
    newState.windowWidth = action.data;
    return newState;
  }
);

reducerFactory.addAction(SET_NAVCOLLAPSED.type, SET_NAVCOLLAPSED.name,
  navCollapsed => {
    return navCollapsed || false;
  }, (state,action) => {
    const newState = _.cloneDeep(state);
    newState.navCollapsed = action.data;
    return newState;
  }
)

reducerFactory.addAction(SETTINGS_PARAMS_CHANGE.type, SETTINGS_PARAMS_CHANGE.name,
  async data=> data,(state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.length > 0){
      action.data.forEach(d=>{
        const { key, value } = d;
        newState[key] = value;
      });
    }  
    return newState;
  } 
);

export default reducerFactory;