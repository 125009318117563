import { validArray } from "../helpers/common-helper";
import { NLU, NER, DOCS_URL } from "../constants/common-constant";
import { nluAppRoutes, NLU_ROUTE } from "./nlu";
import { nerAppRoutes, NER_ROUTE } from "./ner";
// import { LANGUAGE_DETECTION_ROUTE } from "./language-detection";
// import { transliterationAppRoutes, TRANSLITERATION_ROUTE } from "./transliteration";
// import { TRANSLATION_ROUTE } from "./translation";
// import { AUGMENTATION_ROUTE } from "./augmentation";
// import { STT_ROUTE } from "./stt";
import { ENABLE_PAYMENT } from "../utils/ui";
import moment from "moment";

export const authRoutes = {
  ROOT_ROUTE: "/",
  LOGIN_ROUTE: "/auth/signin",
  SIGNUP_ROUTE: "/auth/signup",
  VERIFY_EMAIL_ROUTE: "/auth/verify_email",
  CONFIRM_EMAIL_ROUTE: "/auth/email/:authRequestId/verification",
  FORGOT_PASSWORD_ROUTE: "/auth/forgot_password",
  RESET_PASSWORD_ROUTES: "/auth/password/:authToken/reset"
}

export const pricingRoute = {
  PRICING_ROOT: "/pricing",
  PRICING_ROUTE: "/pricing/calculator"
}

export const applicationRoutes = {
  ROOT_ROUTE: "/",
  DASHBOARD_ROUTE: "/platform/dashboard",
  PROFILE_ROUTE: "/platform/profile",
  SETTINGS_ROUTE: "/platform/settings",
  SUBSCRIPTION_ROUTE: "/platform/subscription",
  SUBSCRIPTION_PLAN_ROUTE: "/platform/subscription/plans",
  SUBSCRIPTION_BILLING_ROUTE: "/platform/subscription/billings",
  SUBSCRIPTION_INVOICE_ROUTE: "/platform/subscription/invoices",
  SUBSCRIPTION_TRANSACTION_ROUTE: "/platform/subscription/transaction"
}

export const homeRoutes = () => {
  return[
    {
      key: applicationRoutes.DASHBOARD_ROUTE,
      name: "Dashboard",
      path: applicationRoutes.DASHBOARD_ROUTE,
      icon: "icon icon-home",
      i18Message: "sidebar.main.dashboard"
    }
  ]
}

export const docRoutes = () => {
  return [
    {
      key: "doc",
      name: "Profile",
      path: "doc",
      icon: "icon icon-ckeditor",
      i18Message: "sidebar.docs",
      link: DOCS_URL
    }
  ]
}

export const commonRoutes = () => {
  let routeArr = [
    {
      key: applicationRoutes.PROFILE_ROUTE,
      name: "Profile",
      path: applicationRoutes.PROFILE_ROUTE,
      icon: "icon icon-profile",
      i18Message: "sidebar.profile"
    }
  ];
  if (ENABLE_PAYMENT === "YES") {
    routeArr.push(
      {
        key: "subscription",
        name: "Subscription",
        path: applicationRoutes.SUBSCRIPTION_ROUTE,
        icon: "icon icon-subscription",
        i18Message: "sidebar.subscription",
        subRoutes: subscriptionRoutes
      }
    )
  }
  return routeArr
}

export const getTransactionRoute = (fromDate, toDate, appType, paramaterName, transactionStatus, pageNumber, pageSize) => 
  `${applicationRoutes.SUBSCRIPTION_TRANSACTION_ROUTE}?from=${fromDate}&to=${toDate}&app=${appType}&paramter=${paramaterName}&status=${transactionStatus}&page=${pageNumber}&limit=${pageSize}`;

export const subscriptionRoutes = [
  {
    key: applicationRoutes.SUBSCRIPTION_PLAN_ROUTE,
    name: "Plans",
    path: applicationRoutes.SUBSCRIPTION_PLAN_ROUTE,
    icon: "icon icon-plans ns-mr-2 ns-vertical-align-custom ns-fs-lg",
    i18Message: "sidebar.subscription.plan"
  },
  {
    key: applicationRoutes.SUBSCRIPTION_BILLING_ROUTE,
    name: "License and Bill",
    path: applicationRoutes.SUBSCRIPTION_BILLING_ROUTE,
    icon: "icon icon-billing ns-mr-2 ns-vertical-align-custom ns-fs-lg",
    i18Message: "sidebar.subscription.billings"
  },
  {
    key: applicationRoutes.SUBSCRIPTION_TRANSACTION_ROUTE,
    name: "Transactions",
    path: getTransactionRoute(moment().subtract(1, "month").format("LLL"), moment().format("LLL"), "all", "all", "all", 1, 15),
    icon: "icon icon-transaction ns-mr-2 ns-vertical-align-custom ns-fs-lg",
    i18Message: "sidebar.subscription.transactions"
  },
  {
    key: applicationRoutes.SUBSCRIPTION_INVOICE_ROUTE,
    name: "Invoices",
    path: applicationRoutes.SUBSCRIPTION_INVOICE_ROUTE,
    icon: "icon icon-invoices ns-mr-2 ns-vertical-align-custom ns-fs-lg",
    i18Message: "sidebar.subscription.invoices"
  }
]

export const appRoutes = apps => {
  let menus = [];
  if (validArray(apps)) {
    apps.forEach(app => {
      if (app === NLU) {
        menus.push({
          key: NLU_ROUTE,
          name: NLU,
          path: NLU_ROUTE,
          icon: "icon icon-nlu",
          i18Message: `sidebar.${NLU}`,
          subRoutes: nluAppRoutes
        })
      }
      if (app === NER) {
        menus.push({
          key: NER_ROUTE,
          name: NER,
          path: NER_ROUTE,
          icon: "icon icon-ner",
          i18Message: `sidebar.${NER}`,
          subRoutes: nerAppRoutes
        })
      }
      // if (app === TRANSLITERATION) {
      //   menus.push({
      //     key: TRANSLITERATION_ROUTE,
      //     name: TRANSLITERATION,
      //     path: TRANSLITERATION_ROUTE,
      //     icon: "icon icon-transliteration",
      //     i18Message: `sidebar.${TRANSLITERATION}`,
      //     subRoutes: transliterationAppRoutes
      //   })
      // }
      // if (app === STT) {
      //   menus.push({
      //     key: STT_ROUTE,
      //     name: STT,
      //     path: STT_ROUTE,
      //     icon: "icon icon-mic",
      //     i18Message: `sidebar.${STT}`
      //   })
      // }
      // if (app === TRANSLATION) {
      //   menus.push({
      //     key: TRANSLATION_ROUTE,
      //     name: TRANSLATION,
      //     path: TRANSLATION_ROUTE,
      //     icon: "icon icon-translation",
      //     i18Message: `sidebar.${TRANSLATION}`
      //   })
      // }
      // if (app === LANGUAGE_DETECTION) {
      //   menus.push({
      //     key: LANGUAGE_DETECTION_ROUTE,
      //     name: LANGUAGE_DETECTION,
      //     path: LANGUAGE_DETECTION_ROUTE,
      //     icon: "icon icon-ld",
      //     i18Message: `sidebar.${LANGUAGE_DETECTION}`
      //   })
      // }
      // if (app === AUGMENTATION) {
      //   menus.push({
      //     key: AUGMENTATION_ROUTE,
      //     name: AUGMENTATION,
      //     path: AUGMENTATION_ROUTE,
      //     icon: "icon icon-aug",
      //     i18Message: `sidebar.${AUGMENTATION}`
      //   })
      // }
    })
  }
  return menus;
}