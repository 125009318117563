import _ from "lodash";

import { transliterationDashboardState, reducerName, TRANSLITERATION_DASHBOARD_PARAMS_CHANGE, GET_TRANSLITERATION_DASHBOARD, GET_TRANSLITERATION_DASHBOARD_API_COUNT,
  GET_TRANSLITERATION_DASHBOARD_DEPLOYED_MODELS,
  GET_TRANSLITERATION_DASHBOARD_STATS,
  GET_TRANSLITERATION_DASHBOARD_EXAMPLES_PER_DATASET_HISTOGRAM,
  TRANSLITERATION_DASHBOARD_DATA_CHANGE
} from "../../states/transliteration/transliteration-dashboard-state";
import * as TransliterationDashboardApi from "../../apis/transliteration/transliteration-dashboard-api";
import ReducerFactory from "../../../utils/reducer-factory";


const initialState = {...transliterationDashboardState};
const reducerFactory = new ReducerFactory(reducerName, initialState);

reducerFactory.addAction(TRANSLITERATION_DASHBOARD_PARAMS_CHANGE.type, TRANSLITERATION_DASHBOARD_PARAMS_CHANGE.name,
  async data=> data,(state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.length > 0){
      action.data.forEach(d=>{
        const { key, value } = d;
        newState[key] = value;
      });
    }  
    return newState;
  } 
);

reducerFactory.addAction(TRANSLITERATION_DASHBOARD_DATA_CHANGE.type, TRANSLITERATION_DASHBOARD_DATA_CHANGE.name,
  data=> data,(state,action)=>{
    const newState = _.cloneDeep(state);
    const { key, value } = action.data;
    newState[key] = value;
    return newState;
  } 
);

reducerFactory.addAction(GET_TRANSLITERATION_DASHBOARD.type, GET_TRANSLITERATION_DASHBOARD.name,
  async ()=>{
    reducerFactory.action(TRANSLITERATION_DASHBOARD_PARAMS_CHANGE.name, [{key: "transliterationDashboardCountsLoading", value: true}]);
    const response = await TransliterationDashboardApi.getTransliterationDashboard();
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.transliterationCountData = action.data.data.counts;
      newState.transliterationAppUsage = action.data.data.appUsage;
    }
    newState.transliterationDashboardCountsLoading = false;
    return newState;
  }
);

reducerFactory.addAction(GET_TRANSLITERATION_DASHBOARD_API_COUNT.type, GET_TRANSLITERATION_DASHBOARD_API_COUNT.name,
  async (data)=>{
    reducerFactory.action(TRANSLITERATION_DASHBOARD_PARAMS_CHANGE.name, [{key: "transliterationDashboardGarphLoading", value: true}]);
    const response = await TransliterationDashboardApi.getTransliterationDashboardApiCount(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.count = action.data.data.count;
      newState.apiCalls = action.data.data.apiCalls;
    }
    newState.transliterationDashboardGarphLoading = false;
    return newState;
  }
);

reducerFactory.addAction(GET_TRANSLITERATION_DASHBOARD_DEPLOYED_MODELS.type, GET_TRANSLITERATION_DASHBOARD_DEPLOYED_MODELS.name,
  async ()=>{
    reducerFactory.action(TRANSLITERATION_DASHBOARD_PARAMS_CHANGE.name, [{key: "transliterationDashboardDeployedModelsLoading", value: true}]);
    const response = await TransliterationDashboardApi.getTransliterationDeployedModels();
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.deployedModels = action.data.data;
    }
    newState.transliterationDashboardDeployedModelsLoading = false;
    return newState;
  }
);

reducerFactory.addAction(GET_TRANSLITERATION_DASHBOARD_STATS.type, GET_TRANSLITERATION_DASHBOARD_STATS.name,
  async (data)=>{
    reducerFactory.action(TRANSLITERATION_DASHBOARD_PARAMS_CHANGE.name, [{key: "transliterationStatsLoading", value: true}]);
    const response = await TransliterationDashboardApi.getTransliterationDashboardStats(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.transliterationStats = action.data.data || [];
    }
    newState.transliterationStatsLoading = false;
    return newState;
  }
);

reducerFactory.addAction(GET_TRANSLITERATION_DASHBOARD_EXAMPLES_PER_DATASET_HISTOGRAM.type, GET_TRANSLITERATION_DASHBOARD_EXAMPLES_PER_DATASET_HISTOGRAM.name,
  async ()=>{
    reducerFactory.action(TRANSLITERATION_DASHBOARD_PARAMS_CHANGE.name, [{key: "transliterationExamplesPerProjectLoading", value: true}]);
    const response = await TransliterationDashboardApi.getExamplesPerProjectHistogram();
    return response.data;
  }, (state, action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.examplesPerProjectHistogram = action.data.data || [];
    }
    newState.transliterationExamplesPerProjectLoading = false;
    return newState;
  }
);

export default reducerFactory;