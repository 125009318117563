
import { TRANSLITERATION, TRANSLITERATION_NAME } from "../constants/common-constant";

export const TRANSLITERATION_ROUTE = `/platform/${TRANSLITERATION}`;
export const TRANSLITERATION_DASHBOARD = `/platform/${TRANSLITERATION}/dashboard`;
export const TRANSLITERATION_PROJECTS = `/platform/${TRANSLITERATION}/projects`;
export const TRANSLITERATION_PROJECT_DETAILS = `/platform/${TRANSLITERATION}/projects/:projectId/:language/details`;
export const TRANSLITERATION_DATA_STUDIO = `/platform/${TRANSLITERATION}/projects/:projectId/:language/data_studio`;
export const TRANSLITERATION_MODEL_FEEDBACK_PAGE = `/platform/${TRANSLITERATION}/projects/:projectId/:language/:modelId/feedback`;
export const TRANSLITERATION_UPLOAD_DATASET = `/platform/${TRANSLITERATION}/upload_dataset`;
export const TRANSLITERATION_IMPORT_DATASET = `/platform/${TRANSLITERATION}/import_dataset`;

export const getTransliterationDashboardRoute = (modelId) => `${TRANSLITERATION_DASHBOARD}?model=${modelId}`;

export const getTransliterationProjectRoute = (language, pageNumber, pageSize) => 
  `${TRANSLITERATION_PROJECTS}?languages=${language}&page=${pageNumber}&limit=${pageSize}`;

export const getTransliterationProjectDetailsRoute = (projectDetailsURI, trainingStatus, deployedStatus, pageNumber, pageSize, modelId) =>
  `${projectDetailsURI}?trainingStatus=${trainingStatus}&deployedStatus=${deployedStatus}&page=${pageNumber}&limit=${pageSize}&model=${modelId}`;

export const getTransliterationDataStudioRoute = (dataStudioUri, exampleType, pageNumber, pageSize) =>
  `${dataStudioUri}?&type=${exampleType}&page=${pageNumber}&limit=${pageSize}`;

export const getTransliterationModelFeedbackRoute = (feedbackUri, pageNumber, pageSize) => 
  `${feedbackUri}?page=${pageNumber}&limit=${pageSize}`;

export const getTransliterationUploadDatasetRoute = (pageNumber) =>
  `${TRANSLITERATION_UPLOAD_DATASET}?page=${pageNumber}`;

export const getTransliterationImportDatasetRoute = (sourceLanguage, language, planType, search, domain, pageNumber, pageSize, importPageNumber) =>
  `${TRANSLITERATION_IMPORT_DATASET}?sourceLanguage=${sourceLanguage}&language=${language}&plan=${planType}&search=${search}&domain=${domain}&page=${pageNumber}&limit=${pageSize}&importPage=${importPageNumber}`;

export const transliterationAppRoutes = [
  {
    key: TRANSLITERATION_PROJECTS,
    name: "Projects",
    path: getTransliterationProjectRoute("", 1, 12),
    icon: "icon icon-feedback ns-mr-2 ns-vertical-align-custom ns-fs-lg",
    i18Message: "sidebar.projects"
  },
  {
    key: TRANSLITERATION_UPLOAD_DATASET,
    name: "Upload Datasets",
    path: getTransliterationUploadDatasetRoute(1),
    icon: "icon icon-upload ns-mr-2 ns-vertical-align-custom ns-fs-lg",
    i18Message: "sidebar.upload_dataset"
  },
  {
    key: TRANSLITERATION_IMPORT_DATASET,
    name: "Import Datasets",
    path: getTransliterationImportDatasetRoute("All","All","All","","",1,12,1),
    icon: "icon icon-folder-o ns-mr-2 ns-vertical-align-custom ns-fs-lg",
    i18Message: "sidebar.import_dataset"
  },
  {
    key: TRANSLITERATION_DASHBOARD,
    name: TRANSLITERATION_NAME,
    path: TRANSLITERATION_DASHBOARD,
    icon: "icon icon-chart-line ns-mr-2 ns-vertical-align-custom ns-fs-lg",
    i18Message: "sidebar.dashboard"
  },
];
