import _ from "lodash";

import { dashboardState, reducerName, GET_MAIN_DASHBOARD, GET_MAIN_DASHBOARD_API_COUNT, DASHBOARD_PARAMS_CHANGE,
  GET_MAIN_DASHBOARD_APP_SPECIFIC_STATS, GET_MAIN_DASHBOARD_GENERIC_STATS
} from "../states/dashboard-state";
import * as DashboardApi from "../apis/dashboard-api";
import ReducerFactory from "../../utils/reducer-factory";


const initialState = {...dashboardState};
const reducerFactory = new ReducerFactory(reducerName, initialState);

reducerFactory.addAction(DASHBOARD_PARAMS_CHANGE.type, DASHBOARD_PARAMS_CHANGE.name,
  async data=> data,(state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.length > 0){
      action.data.forEach(d=>{
        const { key, value } = d;
        newState[key] = value;
      });
    }  
    return newState;
  } 
);

reducerFactory.addAction(GET_MAIN_DASHBOARD.type, GET_MAIN_DASHBOARD.name,
  async ()=>{
    reducerFactory.action(DASHBOARD_PARAMS_CHANGE.name, [{key: "dashboardCountsLoading", value: true}]);
    const response = await DashboardApi.getMaindashboard();
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.usage = action.data.data.usage;
      newState.appUsage = action.data.data.appUsage;
    }
    newState.dashboardCountsLoading = false;
    return newState;
  }
);

reducerFactory.addAction(GET_MAIN_DASHBOARD_API_COUNT.type, GET_MAIN_DASHBOARD_API_COUNT.name,
  async (data)=>{
    reducerFactory.action(DASHBOARD_PARAMS_CHANGE.name, [{key: "dashboardGarphLoading", value: true}]);
    const response = await DashboardApi.getMaindashboardApiCount(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.count = action.data.data.count;
      newState.apiCalls = action.data.data.apiCalls;
    }
    newState.dashboardGarphLoading = false;
    return newState;
  }
);

reducerFactory.addAction(GET_MAIN_DASHBOARD_GENERIC_STATS.type, GET_MAIN_DASHBOARD_GENERIC_STATS.name,
  async ()=>{
    reducerFactory.action(DASHBOARD_PARAMS_CHANGE.name, [{key: "genericLoading", value: true}]);
    const response = await DashboardApi.getMainDashboardGenericStats();
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.generic = action.data.data || [];
    }
    newState.genericLoading = false;
    return newState;
  }
);

reducerFactory.addAction(GET_MAIN_DASHBOARD_APP_SPECIFIC_STATS.type, GET_MAIN_DASHBOARD_APP_SPECIFIC_STATS.name,
  async (data)=>{
    reducerFactory.action(DASHBOARD_PARAMS_CHANGE.name, [{key: "appSpecificLoading", value: true}]);
    const response = await DashboardApi.getMainDashboardAppSpecificStats(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.appSpecific = action.data.data || [];
    }
    newState.appSpecificLoading = false;
    return newState;
  }
);
export default reducerFactory;