export const reducerName = "nluProject";
export const nluProjectState = {
  loading: false,
  singleProjectLoading: false,
  oneProject: {},
  oneProjectId: "",
  pageNumber: 1,
  pageSize: 15,
  search: "",
  sortBy:{
    key: "createdAt",
    value: "asc"
  },
  sortByKeys:{
    createdAt: "asc",
    noOfExamples: "asc",
    noOfIntents: "asc",
    noOfModels: "asc"
  },
  languages: [],
  selectedLanguage: "",
  projects: [],
  count: 0,
  allProjects: [],

  //Project synonyms
  projectSynonymLoading: false,
  projectSynonyms: [],
  projectSynonymCount: 0,
  error: null
}

export const PROJECT_LOADING = {
  type: "PROJECT_LOADING",
  name: "projectLoading"
};

export const PROJECT_PARAMS_CHANGE = {
  type: "PROJECT_PARAMS_CHANGE",
  name: "projectParamsChange"
}

export const PROJECT_PARAMS_CHANGE_WITHOUT_ASYNC = {
  type: "PROJECT_PARAMS_CHANGE_WITHOUT_ASYNC",
  name: "projectParamsChangeWithoutAsync"
}

export const SORT_PROJECTS = {
  type: "SORT_PROJECTS",
  name: "sortProjects"
}

export const CREATE_PROJECT = {
  type: "CREATE_PROJECT",
  name: "createProject"
};

export const FETCH_PROJECTS = {
  type: "FETCH_PROJECTS",
  name: "fetchProjects"
};

export const FETCH_ALL_PROJECTS = {
  type: "FETCH_ALL_PROJECTS",
  name: "fetchAllProjects"
}

export const SINGLE_PROJECT = {
  type: "SINGLE_PROJECT",
  name: "singleProject"
};

export const UPDATE_PROJECT = {
  type: "UPDATE_PROJECT",
  name: "updateProject"
};

export const DELETE_PROJECT = {
  type: "DELETE_PROJECT",
  name: "deleteProject"
};

export const ADD_SYNONYM_TO_PROJECT = {
  type: "ADD_SYNONYM_TO_PROJECT",
  name: "addSynonymToProject"
};

export const FETCH_PROJECT_SYNONYMS = {
  type: "FETCH_PROJECT_SYNONYMS",
  name: "fetchProjectSynonyms"
};

export const REMOVE_SYNONYM_FROM_PROJECT = {
  type: "REMOVE_SYNONYM_FROM_PROJECT",
  name: "removeSynonymFromProject"
};
