export const reducerName = "nluExample";
export const nluExampleState = {
  loading: false,
  exampleListLoading: false,
  exampleIntentDistLoading: false,
  oneExample: {},
  pageNumber: 1,
  pageSize: 15,
  search: "",
  examples: [],
  count: 0,
  unpreparedCount: 0,
  selectedExamples: [],
  exampleIntentDistribution: [],
  selectedLanguage: "",
  selectedEntityType: "all",
  selectedEntity: "",
  exampleType: "Train",
  autoDetectEntities: false,
  phoneticTyping: false,
  //Filter states

  filterPrepared: "all",
  filterEntityType: "all",
  filterExampleType: "all",
  filterEntity: "All",
  filterIntent: "All",

  //basket item states
  basketLoading: false,
  basketEntities: [],
  basketEntitiesCount: 0,

  //create example states
  newExample: {
    text: "",
    entities: [],
    styledText: ""
  },

  error: null
}

export const EXAMPLE_LOADING = {
  type: "EXAMPLE_LOADING",
  name: "exampleLoading"
};

export const EXAMPLE_PARAMS_CHANGE = {
  type: "EXAMPLE_PARAMS_CHANGE",
  name: "exampleParamsChange"
};

export const EXAMPLE_DATA_CHANGE = {
  type: "EXAMPLE_DATA_CHANGE",
  name: "exampleDataChange"
};

export const CREATE_EXAMPLE = {
  type: "CREATE_EXAMPLE",
  name: "createExample"
};

export const FETCH_EXAMPLES = {
  type: "FETCH_EXAMPLES",
  name: "fetchExamples"
};

export const SINGLE_EXAMPLE = {
  type: "SINGLE_EXAMPLE",
  name: "singleExample"
};

export const UPDATE_EXAMPLE = {
  type: "UPDATE_EXAMPLE",
  name: "updateExample"
};

export const UPDATE_MULTIPLE_EXAMPLE = {
  type: "UPDATE_MULTIPLE_EXAMPLE",
  name: "nluUpdateMultipleExample"
}

export const UPDATE_EXAMPLE_FROM_SOCKET = {
  type: "UPDATE_EXAMPLE_FROM_SOCKET",
  name: "updateExampleFromSocket"
}

export const UPDATE_TEXT_IN_EXAMPLE = {
  type: "UPDATE_TEXT_IN_EXAMPLE",
  name: "updateTextInExample"
}

export const CREATE_NEW_INTENT_FROM_EXAMPLE={
  type: "CREATE_NEW_INTENT_FROM_EXAMPLE",
  name: "createNewIntentFromExample"
};

export const UPDATE_TYPE_IN_EXAMPLE={
  type: "UPDATE_TYPE_IN_EXAMPLE",
  name: "updateTypeinExample"
}

export const UPDATE_INTENT_IN_EXAMPLE={
  type: "UPDATE_INTENT_IN_EXAMPLE",
  name: "updateIntentinExample"
};

export const UPDATE_ENTITY_IN_EXAMPLE={
  type: "UPDATE_ENTITY_IN_EXAMPLE",
  name: "updateEntityInExample"
};

export const CREATE_NEW_ENTITY_FROM_EXAMPLE={
  type: "CREATE_NEW_ENTITY_FROM_EXAMPLE",
  name: "createNewEntityFromExample"
};

export const DELETE_EXAMPLE = {
  type: "DELETE_EXAMPLE",
  name: "deleteExample"
};

export const DELETE_SINGLE_EXAMPLE = {
  type: "DELETE_SINGLE_EXAMPLE",
  name: "deleteSingleExample"
};

export const DELETE_EXAMPLE_FEEDBACK = {
  type: "DELETE_EXAMPLE_FEEDBACK",
  name: "deleteExampleFeedback"
}

export const INTENT_EXAMPLES = {
  type: "INTENT_EXAMPLES",
  name: "intentExamples"
};

export const FETCH_BASKET_ENTITIES = {
  type: "FETCH_BASKET_ENTITIES",
  name: "fetchBasketEntities"
};

export const DELETE_ENTITY_FROM_BASKET = {
  type: "DELETE_ENTITY_FROM_BASKET",
  name: "deleteEntityFromBasket"
};

export const ADD_EXAMPLE_TO_DATASET = {
  type: "ADD_EXAMPLE_TO_DATASET",
  name: "addExampleToDataset"
}