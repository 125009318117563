import { Divider } from "antd";
import React from "react";
import { PRIVACY_URL, TERMS_URL, SLACK_URL, LINKEDIN_URL, TWITTER_URL, YOUTUBE_URL } from "../../constants/common-constant"
import Youtube from "../../assets/images/yt.svg";
import Linkedin from "../../assets/images/li.svg";
import Slack from "../../assets/images/sl.svg";
import Twitter from "../../assets/images/tw.svg";

class Footer extends React.Component {
	render() {
		return (
			<div className="ns-social-footer">
				<div className="ns-layout-footer-content">
					<a className="ns-link-underline" href={PRIVACY_URL} target="_blank" rel="noreferrer">Privacy Policy</a>
					<Divider type="vertical" />
					<a className="ns-link-underline" href={TERMS_URL} target="_blank" rel="noreferrer">Terms</a>
				</div>
				<div className="ns-pt-3">
					<a className="ns-p-1" href={LINKEDIN_URL} target="_blank" rel="noreferrer"><img className="ns-footer-social-icons" src={Linkedin} alt="linkedin icon" /></a>
					<a className="ns-p-1" href={SLACK_URL} target="_blank" rel="noreferrer"><img className="ns-footer-social-icons" src={Slack} alt="slack icon" /></a>
					<a className="ns-p-1" href={YOUTUBE_URL} target="_blank" rel="noreferrer"><img className="ns-footer-social-icons" src={Youtube} alt="youtube icon" /></a>
					<a className="ns-p-1" href={TWITTER_URL} target="_blank" rel="noreferrer"><img className="ns-footer-social-icons" src={Twitter} alt="twitter icon" /></a>
				</div>
			</div>

		)
	}
}
export default Footer;