import React from "react";
import PropTypes from "prop-types";
import { Avatar, Popover } from "antd";

import { getUsername } from "../../helpers/common-helper";
import ApikeyComponent from "../common/apikey-component";

class UserInfo extends React.Component {

  onClickSingOut = () => {
    window.localStorage.clear();
    window.location.reload();
  }

  render() {
    const { user } = this.props;
    const userMenuOptions = (
      <ul className="ns-user-popover">
        <ApikeyComponent user={user} />
        <li className="ns-link" onClick={this.onClickSingOut}>Sign Out</li>
      </ul>
    );
    return (
      <Popover overlayClassName="ns-popover-horizantal" placement="bottomRight" content={userMenuOptions}
        trigger="click">
        <Avatar className="ns-avatar ns-pointer" alt="">{getUsername(user)[0]}</Avatar>
      </Popover>
    )
  }
}

UserInfo.propTypes={
  user: PropTypes.object.isRequired
}

export default UserInfo