import { combineReducers } from "redux-immutable";
import { connectRouter } from "connected-react-router";

import History from "../utils/history";

/*********************************** State imports ********************************************* */
//Common States
import * as AuthState from "./states/auth-state";
import * as UsageState from "./states/usage-state";
import * as DashboardState from "./states/dashboard-state";
import * as SettingsState from "./states/settings-state";
import * as PaymentState from "./states/payment-state";
import * as TransactionState from "./states/transaction-state";
import * as FileState from "./states/file-state";

//Nlu states
import * as NluDashboardState from "./states/nlu/nlu-dashboard-state";
import * as NluProjectState from "./states/nlu/nlu-project-state";
import * as NluModelState from "./states/nlu/nlu-model-state";
import * as NluIntentState from "./states/nlu/nlu-intent-state";
import * as NluEntityState from "./states/nlu/nlu-entity-state";
import * as NluExampleState from "./states/nlu/nlu-example-state";
import * as NluDatasetState from "./states/nlu/nlu-dataset-state";

//NER states
import * as NerDashboardState from "./states/ner/ner-dashboard-state";
import * as NerProjectState from "./states/ner/ner-project-state";
import * as NerModelState from "./states/ner/ner-model-state";
import * as NerExampleState from "./states/ner/ner-example-state";
import * as NerEntityState from "./states/ner/ner-entity-state";
import * as NerDatasetState from "./states/ner/ner-dataset-state";

//Transliteration states
import * as TransliterationDashboardState from "./states/transliteration/transliteration-dashboard-state";
import * as TransliterationProjectState from "./states/transliteration/transliteration-project-state";
import * as TransliterationExampleState from "./states/transliteration/transliteration-example-state";
import * as TransliterationModelState from "./states/transliteration/transliteration-model-state";
import * as TransliterationDatasetState from "./states/transliteration/transliteration-dataset-state";

//Translation state
import * as TranslationState from "./states/translation/translation-state";

// Augmentation state
import * as AugmentationState from "./states/augmentation/augmentation-state";


//Transcription States
import * as TranscriptionFileState from "./states/transcription/transcription-file-transcription-state";
/*********************************** Reducer imports ********************************************* */
//Common reducers
import AuthModule from "./modules/auth-module";
import UsageModule from "./modules/usage-module";
import DashboardModule from "./modules/dashboard-module";
import SettingsModule from "./modules/settings-module";
import PaymentModule from "./modules/payment-module";
import TransactionModule from "./modules/transaction-module";
import FileModule from "./modules/file-module";

//NLU reducers
import NluDashboardModule from "./modules/nlu/nlu-dashboard-module";
import NluProjectModule from "./modules/nlu/nlu-project-module";
import NluModelModule from "./modules/nlu/nlu-model-module";
import NluIntentModule from "./modules/nlu/nlu-intent-module";
import NluExampleModule from "./modules/nlu/nlu-example-module";
import NluEntityModule from "./modules/nlu/nlu-entity-module";
import NluDatasetModule from "./modules/nlu/nlu-dataset-module";

//NER reducers
import NerEntityModule from "./modules/ner/ner-entity-module";
import NerDashboardModule from "./modules/ner/ner-dashboard-module";
import NerProjectModule from "./modules/ner/ner-project-module";
import NerModelModule from "./modules/ner/ner-model-module";
import NerExampleModule from "./modules/ner/ner-example-module";
import NerDatasetModule from "./modules/ner/ner-dataset-module";

//Transliteration reducers
import TransliterationDashboardModule from "./modules/transliteration/transliteration-dashboard-module";
import TransliterationProjectModule from "./modules/transliteration/transliteration-project-module";
import TransliterationExampleModule from "./modules/transliteration/transliteration-example-module";
import TransliterationModelModule from "./modules/transliteration/transliteration-model-module";
import TransliterationDatasetModule from "./modules/transliteration/transliteration-dataset-module";

// Transaltion reducer
import TranslationModule from "./modules/translation/translation-module";

// Augmentation reducer
import AugmentationModule from "./modules/augmentation/augmentation-module";

//Transcription reducers
import TranscriptionFileModule from "./modules/transcription/transcription-file-module";

export default function createReducer(injectedReducer = {}) {
  const rootReducer = combineReducers({
    
    [AuthState.reducerName]: AuthModule.getReducer(),
    [UsageState.reducerName]: UsageModule.getReducer(),
    [DashboardState.reducerName]: DashboardModule.getReducer(),
    [SettingsState.reducerName]: SettingsModule.getReducer(),
    [PaymentState.reducerName]: PaymentModule.getReducer(),
    [TransactionState.reducerName]: TransactionModule.getReducer(),
    [FileState.reducerName]: FileModule.getReducer(),

    //NLU reducers
    [NluDashboardState.reducerName]: NluDashboardModule.getReducer(),
    [NluProjectState.reducerName]: NluProjectModule.getReducer(),
    [NluModelState.reducerName]: NluModelModule.getReducer(),
    [NluIntentState.reducerName]: NluIntentModule.getReducer(),
    [NluExampleState.reducerName]: NluExampleModule.getReducer(),
    [NluEntityState.reducerName]: NluEntityModule.getReducer(),
    [NluDatasetState.reducerName]: NluDatasetModule.getReducer(),

    //NER reducers
    [NerDashboardState.reducerName]: NerDashboardModule.getReducer(),
    [NerEntityState.reducerName]: NerEntityModule.getReducer(),
    [NerProjectState.reducerName]: NerProjectModule.getReducer(),
    [NerModelState.reducerName]: NerModelModule.getReducer(),
    [NerExampleState.reducerName]: NerExampleModule.getReducer(),
    [NerDatasetState.reducerName]: NerDatasetModule.getReducer(),

    //Transliteration reducers
    [TransliterationDashboardState.reducerName]: TransliterationDashboardModule.getReducer(),
    [TransliterationProjectState.reducerName]: TransliterationProjectModule.getReducer(),
    [TransliterationExampleState.reducerName]: TransliterationExampleModule.getReducer(),
    [TransliterationModelState.reducerName]: TransliterationModelModule.getReducer(),
    [TransliterationDatasetState.reducerName]: TransliterationDatasetModule.getReducer(),
    
    // Translation reducer
    [TranslationState.reducerName]: TranslationModule.getReducer(),

    // Augmentation reducer
    [AugmentationState.reducerName]: AugmentationModule.getReducer(),

    // Transcription reducers
    [TranscriptionFileState.reducerName]: TranscriptionFileModule.getReducer(),


    router: connectRouter(History),
    ...injectedReducer
  });
  const mergeWithRouterState = connectRouter(History);
  return mergeWithRouterState(rootReducer);
}