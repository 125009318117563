import React, {lazy, Suspense} from "react";

const loadable = (importSync, { fallback = null } = { fallback: null }) => {
  const LazyComponent = lazy(importSync);

  return props =>(
    <Suspense fallback={fallback}>
      <LazyComponent {...props} />
    </Suspense>
  )
}

export default loadable;