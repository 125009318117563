import { PROD_URL, STRIPE_PUBLIC_KEY_LIVE, STRIPE_PUBLIC_KEY_TEST } from "../utils/ui";

export const HTTP_METHOD = {
    GET: "get",
    POST: "post",
    PUT: "put",
    DELETE: "delete"
}
export const PAY_AS_YOU_NEED = "Recurring Charges";
export const GENERIC_APP_TYPE = "generic";
export const NLU = "nlu";
export const NER = "ner";
export const TRANSLATION = "translation";
export const TRANSLITERATION = "transliteration";
export const LANGUAGE_DETECTION = "language-detection";
export const AUGMENTATION = "augmentation";
export const STT = "transcription";
export const TTS = "tts";
export const TTS_NAME = "Text to Speech";
export const VOICE_EXTRACTOR = "voice-extractor";
export const VOICE_EXTRACTOR_NAME = "Voice Extractor";
export const IVR = "ivr";
export const VERSION1 = "v1";
export const PLATFORM_AUTH = "platform_auth";
export const USER_ACCESS_TYPE = "platform";
export const NLU_NAME = "Language Understanding";
export const NER_NAME = "Entity Recognition";
export const TRANSLATION_NAME = "Machine Translation";
export const TRANSLITERATION_NAME = "Transliteration";
export const AUGMENTATION_NAME = "Sentence Generator";
export const LANGUAGE_DETECTION_NAME = "Language Detection";
export const STT_NAME = "Speech to Text";
export const TRANSCRIPTION = "transcription";
export const SPEAKER_IDENTIFICATION = "speaker-identification";
export const SPEAKER_IDENTIFICATION_NAME= "Speaker Identification";

export const pageSizeOptions = [15,20,25,50];
export const cardPageSizeOptions = [12,24,48,60];
export const dateFormat="MMM Do YYYY";
export const timeFormat="MMM Do YYYY hh:mm a";

export const WEBSITE_URL="https://www.neuralspace.ai/";
export const PRIVACY_URL="https://www.neuralspace.ai/privacy-policy";
export const TERMS_URL="https://www.neuralspace.ai/terms";
export const SLACK_URL="https://join.slack.com/t/neuralspacecommunity/shared_invite/zt-xlj1xr8k-GQrOkp7tRIV9IuI_0GWS7Q";
export const MEDIUM_URL="https://medium.com/neuralspace";
export const LINKEDIN_URL ="https://www.linkedin.com/company/neuralspace/"
export const TWITTER_URL = "https://twitter.com/NeuralSpace"
export const YOUTUBE_URL = "https://www.youtube.com/channel/UCIX-0OHfP7QS2D5lQY9UWwg/about"
export const INSTAGRAM_URL ="https://www.instagram.com/neuralspace/?utm_medium=copy_link"
export const DOCS_URL="https://docs.neuralspace.ai/";
export const POSTMAN_URL="https://docs.neuralspace.ai/postman";
export const HATE_POLICE_URL="https://github.com/Neural-Space/neuralingo-tutorial-hatepolice-twitter-bot";
export const CITIZEN_SERVICES_URL="https://colab.research.google.com/drive/1nMVVnfKmddPQISUr4JKPR71HGLYksab9?authuser=1";
export const E_COMMERCE_URL="https://colab.research.google.com/drive/1l5m47Wv3flfdEPbeX6cCSIsoEZKe7Bu4?usp=sharing";
export const TRANSLITERATION_TWITTER_BOT="https://github.com/Neural-Space/transliteration-tutorial-twitter-bot";
export const BLOG_URL="https://medium.com/neuralspace";
export const NLU_BULK_UPLOAD_DATASET = "https://docs.neuralspace.ai/natural-language-understanding/concepts/training-data#upload-entire-dataset"
export const NER_BULK_UPLOAD_DATASET = "https://docs.neuralspace.ai/named-entity-recognition/concepts/training-data#upload-entire-dataset"

export const LANDING_PAGE_VIDEO="https://www.youtube.com/embed/n_KKdKWAjQk";
export const NLU_VIDEO="https://www.youtube.com/embed/81VpYlasDCQ";
export const NER_VIDEO="https://www.youtube.com/embed/qOqrceKrXPc";
export const TRANSLATION_VIDEO="https://www.youtube.com/embed/IVb2N8hbgro";
export const TRANSLITERATION_VIDEO="https://www.youtube.com/embed/qwRNgNkxCPs";
export const AUGMENTATION_VIDEO="https://www.youtube.com/embed/xJdZEmfhyws";
export const LANGUAGE_DETECTION_VIDEO="https://www.youtube.com/embed/XBseU76WIVw";
export const STT_VIDEO="https://www.youtube.com/embed/XBseU76WIVw";

export const BASE_URL = window.location.origin;
export const STRIPE_KEY = BASE_URL === PROD_URL ? STRIPE_PUBLIC_KEY_LIVE : STRIPE_PUBLIC_KEY_TEST;
// export const API_URL = process.env.REACT_APP_API_URL;
// export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
export const API_URL = "/api";
export const SOCKET_URL = "/socket";
export const ENVIRONMENT = "DEV";
// export const ENVIRONMENT = "PROD";
// export const ENVIRONMENT = "LOCAL";