import { BRAND_NAME } from "../brand-constant";
import Congrats from "../../assets/images/complete.svg";
import { NLU_VIDEO } from "../common-constant";
export const DASHBOARD_TOUR_NAME = "dashboardTour";
//Key names
export const DASHBOARD_STEP_WELCOME_KEY = "welcome";
export const DASHBOARD_STEP_INSTALL_APP_KEY = "install-first-app";
export const DASHBOARD_STEP_API_GRAPH_KEY = "api-graph";
export const DASHBOARD_STEP_APP_OVERVIEW_KEY = "app-overview";
export const DASHBOARD_STEP_DASHBOARD_INFO_KEY = "platform-info";
export const DASHBOARD_STEP_HEADER_MENU_KEY = "header-menus";
export const DASHBOARD_STEP_UPGRADE_PLAN_KEY = "upgrade-plan";
export const DASHBOARD_STEP_END_KEY = "dashboard-end";

//Targets
export const DASHBOARD_STEP_WELCOME_TARGET = "body";
export const DASHBOARD_STEP_INSTALL_APP_TARGET = "tour-app-install";
export const DASHBOARD_STEP_API_GRAPH_TARGET = "tour-api-graph";
export const DASHBOARD_STEP_APP_OVERVIEW_TARGET = "tour-app-overview";
export const DASHBOARD_STEP_DASHBOARD_INFO_TARGET = "tour-platform-info";
export const DASHBOARD_STEP_HEADER_MENU_TARGET = "tour-header-menus";
export const DASHBOARD_STEP_UPGRADE_PLAN_TARGET = "tour-upgrade-plan";
export const DASHBOARD_STEP_END_TARGET = "body";

export const dashboardSteps = [
  {
    key: DASHBOARD_STEP_WELCOME_KEY,
    target: DASHBOARD_STEP_WELCOME_TARGET,
    title: <div><b>{`Welcome to ${BRAND_NAME}`}</b></div>,
    content: (
      <div>
        NeuralSpace is a no-code SaaS Platform for Natural Language Processing (NLP) tasks in 80+ languages with AutoNLP and AutoMLOps. <br />
      </div>
    ),
    placement: "center",
    styles: {
      options: {
        width: 600,
      },
    },
    locale: {
      next: "Get Started",
    },
  },
  {
    key: DASHBOARD_STEP_INSTALL_APP_KEY,
    target: `.${DASHBOARD_STEP_INSTALL_APP_TARGET}`,
    title: <b>Find all your Services</b>,
    content: (
      <div>
        You can click on this button to browse through our collection of Services. Watch the video where we explain one of our Services, Language Understanding.
        <br />
        <div className="ns-align-items-center ns-flex-column ns-mt-3">
          <iframe
            width="550"
            height="320"
            src={NLU_VIDEO}
            title="Tutorial"
            className="ns-about-us-video"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
    ),
    placement: "right",
    styles: {
      options: {
        width: 600,
      },
    },
    locale: {
      next: "Next"
    }
  },
  {
    key: DASHBOARD_STEP_API_GRAPH_KEY,
    target: `.${DASHBOARD_STEP_API_GRAPH_TARGET}`,
    title: <b>Platform activity</b>,
    content:`This graph shows how many API calls were made to the different Services and components of the ${BRAND_NAME} Platform from your account.`,
    placement: "top",
  },
  {
    key: DASHBOARD_STEP_APP_OVERVIEW_KEY,
    target: `.${DASHBOARD_STEP_APP_OVERVIEW_TARGET}`,
    title: <b>Service Overview</b>,
    content:
      "Get an overview of your current usage and limits. You can check how many models you have trained, how many models you have deployed and more Service-specific usage information.",
    placement: "top",
  },
  {
    key: DASHBOARD_STEP_DASHBOARD_INFO_KEY,
    target: `.${DASHBOARD_STEP_DASHBOARD_INFO_TARGET}`,
    content: "Want to start with a tutorial or talk to fellow NeuralSpace users? You will see all of this here.",
    placement: "top",
  },
  {
    key: DASHBOARD_STEP_HEADER_MENU_KEY,
    target: `.${DASHBOARD_STEP_HEADER_MENU_TARGET}`,
    content: (
      <div>
        <p className="ns-mb-1"><b>Copy  access token</b></p>
        <p className="ns-fs-sm">Your access token can be used to integrate APIs of the {BRAND_NAME} Platform.</p>
      </div>
    ),
  },
  {
    key: DASHBOARD_STEP_UPGRADE_PLAN_KEY,
    target: `.${DASHBOARD_STEP_UPGRADE_PLAN_TARGET}`,
    content: (
      <div>
        Here you can see the available credit and the current usage in your account.
      </div>
    ),
  },
  {
    key: DASHBOARD_STEP_END_KEY,
    target: DASHBOARD_STEP_END_TARGET,
    title: <b>Congratulations!</b>,
    content: (
      <div>
        <img src={Congrats} className="ns-w-25" alt="End tour"/>
      </div>
    ),
    placement: "center",
    styles: {
      tooltipContainer: {
        textAlign: "center",
      },
      options: {
        width: 600,
      },
    },
  },
];
