import _ from "lodash";

import { transactionState, reducerName, TRANSACTION_PARAMS_CHANGE, FETCH_USER_TRANSACTIONS } from "../states/transaction-state";
import * as TransactionApi from "../apis/transaction-api";
import ReducerFactory from "../../utils/reducer-factory";


const initialState = {...transactionState};
const reducerFactory = new ReducerFactory(reducerName, initialState);

reducerFactory.addAction(TRANSACTION_PARAMS_CHANGE.type, TRANSACTION_PARAMS_CHANGE.name,
  async data=> data,(state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.length > 0){
      action.data.forEach(d=>{
        const { key, value } = d;
        newState[key] = value;
      });
    }  
    return newState;
  } 
);

reducerFactory.addAction(FETCH_USER_TRANSACTIONS.type, FETCH_USER_TRANSACTIONS.name,
	async(data)=>{
    reducerFactory.action(TRANSACTION_PARAMS_CHANGE.name, [{key: "transactionsLoading", value: true}]);
    const response = await TransactionApi.fetchUserTransactions(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.count = action.data.data.count;
      newState.transactions = action.data.data.transactions;
    }else{
      newState.count = 0;
      newState.transactions = [];
    }
    newState.transactionsLoading = false;
    return newState;
  }	
);

export default reducerFactory;