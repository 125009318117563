export const reducerName = "file";
export const fileState = {
  loading: false,
}

export const FILE_LOADING = {
  type: "FILE_LOADING",
  name: "fileLoading"
}

export const UPLOAD_FILE = {
  type: "UPLOAD_FILE",
  name: "uploadFile"
}