export const reducerName = "transliterationDashboard";
export const transliterationDashboardState = {
  transliterationDashboardGarphLoading: false,
  transliterationDashboardCountsLoading: false,
  transliterationDashboardDeployedModelsLoading: false,
  transliterationStatsLoading: false,
  transliterationExamplesPerProjectLoading: false,
  transliterationCountData:{},
  transliterationAppUsage: {},
  count: 0,
  apiCalls: [],
  deployedModels: [],
  transliterationStats: [],
  examplesPerProjectHistogram: []
}

export const TRANSLITERATION_DASHBOARD_PARAMS_CHANGE = {
  type: "TRANSLITERATION_DASHBOARD_PARAMS_CHANGE",
  name: "transliterationDashboardParamsChange"
}

export const TRANSLITERATION_DASHBOARD_DATA_CHANGE = {
  type: "TRANSLITERATION_DASHBOARD_DATA_CHANGE",
  name: "transliterationDashboardDataChange"
}

export const GET_TRANSLITERATION_DASHBOARD = {
  type: "GET_TRANSLITERATION_DASHBOARD",
  name: "getTransliterationDashboard"
}

export const GET_TRANSLITERATION_DASHBOARD_API_COUNT = {
  type: "GET_TRANSLITERATION_DASHBOARD_API_COUNT",
  name: "getTransliterationDashboardApiCount"
}

export const GET_TRANSLITERATION_DASHBOARD_DEPLOYED_MODELS = {
  type: "GET_TRANSLITERATION_DASHBOARD_DEPLOYED_MODELS",
  name: "getTransliterationDashboardDeployedModels"
}

export const GET_TRANSLITERATION_DASHBOARD_STATS = {
  type: "GET_TRANSLITERATION_DASHBOARD_STATS",
  name: "getTransliterationDashboardStats"
}

export const GET_TRANSLITERATION_DASHBOARD_EXAMPLES_PER_DATASET_HISTOGRAM = {
  type: "GET_TRANSLITERATION_DASHBOARD_EXAMPLES_PER_DATASET_HISTOGRAM",
  name: "getExamplesPerProjectHistogram"
}