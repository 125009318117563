import { NER, NER_NAME } from "../constants/common-constant";
import { encodeUriValueConvertor } from "../helpers/common-helper";

export const NER_ROUTE = `/platform/${NER}`;
export const NER_DASHBOARD = `/platform/${NER}/dashboard`;
export const NER_PROJECTS = `/platform/${NER}/projects`;
export const NER_ENTITIES = `/platform/${NER}/entities`;
export const NER_UPLOAD_DATASET = `/platform/${NER}/upload_dataset`;
export const NER_IMPORT_DATASET = `/platform/${NER}/import_dataset`;
export const NER_PROJECT_DETAILS = `/platform/${NER}/projects/:projectId/:language/details`;
export const NER_DATA_STUDIO = `/platform/${NER}/projects/:projectId/:language/data_studio`;
export const NER_MODEL_FEEDBACK_PAGE = `/platform/${NER}/projects/:projectId/:language/:modelId/feedback`;
export const NER_MODEL_ANALYTICS_PAGE = `/platform/${NER}/projects/:projectId/:language/:modelId/analytics`;

export const getNerDashboardRoute = (modelId) => `${NER_DASHBOARD}?model=${modelId}`;

export const getNerProjectRoute = (language, pageNumber, pageSize) => 
  `${NER_PROJECTS}?languages=${language}&page=${pageNumber}&limit=${pageSize}`;

export const getNerProjectDetailsRoute = (projectDetailsURI, trainingStatus, deployedStatus, pageNumber, pageSize, modelId) =>
  `${projectDetailsURI}?trainingStatus=${trainingStatus}&deployedStatus=${deployedStatus}&page=${pageNumber}&limit=${pageSize}&model=${modelId}`;

export const getNerDataStudioRoute = (dataStudioUri, exampleStatus, exampleType, entityType, entity, pageNumber, pageSize) =>{
  entity = encodeUriValueConvertor(entity);
  return `${dataStudioUri}?${encodeURIComponent(`status=${exampleStatus}&type=${exampleType}&entityType=${entityType}&entity=${entity}&page=${pageNumber}&limit=${pageSize}`)}`;
}

export const getNerModelFeedbackRoute = (feedbackUri, entity, entityRange, pageNumber, pageSize) => {
  entity = encodeUriValueConvertor(entity);
  return `${feedbackUri}?${encodeURIComponent(`entity=${entity}&entityRange=${entityRange}&page=${pageNumber}&limit=${pageSize}`)}`;
}

export const getNerModelAnalyticsRoute = (analyticsUri, type, pageNumber, pageSize) =>
  `${analyticsUri}?type=${type}&page=${pageNumber}&limit=${pageSize}`;

export const getNerEntityRoute = (type, language, pageNumber, pageSize) => 
  `${NER_ENTITIES}?filter=${type}&language=${language}&page=${pageNumber}&limit=${pageSize}`;

export const getNerUploadDatasetRoute = (pageNumber) =>
  `${NER_UPLOAD_DATASET}?page=${pageNumber}`;

export const getNerImportDatasetRoute = (language, planType, search, domain, entity, entityRange, pageNumber, pageSize, importPageNumber) =>
  `${NER_IMPORT_DATASET}?language=${language}&plan=${planType}&search=${search}&domain=${domain}&entity=${entity}&entityRange=${entityRange}&page=${pageNumber}&limit=${pageSize}&importPage=${importPageNumber}`;

export const nerAppRoutes = [
  {
    key: NER_PROJECTS,
    name: "Projects",
    path: getNerProjectRoute("", 1, 12),
    icon: "icon icon-feedback ns-mr-2 ns-vertical-align-custom ns-fs-lg",
    i18Message: "sidebar.projects"
  },
  {
    key: NER_ENTITIES,
    name: "Entities",
    path: getNerEntityRoute("All", "English", 1, 12),
    icon: "icon icon-ner ns-mr-2 ns-vertical-align-custom ns-fs-lg",
    i18Message: "sidebar.entities"
  },
  {
    key: NER_UPLOAD_DATASET,
    name: "Upload Datasets",
    path: getNerUploadDatasetRoute(1),
    icon: "icon icon-upload ns-mr-2 ns-vertical-align-custom ns-fs-lg",
    i18Message: "sidebar.upload_dataset"
  },
  {
    key: NER_IMPORT_DATASET,
    name: "Import Datasets",
    path: getNerImportDatasetRoute("All","All","","","All","All",1,12,1),
    icon: "icon icon-folder-o ns-mr-2 ns-vertical-align-custom ns-fs-lg",
    i18Message: "sidebar.import_dataset"
  },
  {
    key: NER_DASHBOARD,
    name: NER_NAME,
    path: NER_DASHBOARD,
    icon: "icon icon-chart-line ns-mr-2 ns-vertical-align-custom ns-fs-lg",
    i18Message: "sidebar.dashboard"
  },
];
