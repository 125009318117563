export const reducerName = "nluIntent";
export const nluIntentState = {
  loading: false,
  oneIntent: {},
  newIntent: "",
  pageNumber: 1,
  pageSize: 15,
  search: "",
  count: 0,
  intents: [],
  error: null,
  searchedIntents: []
}

export const INTENT_LOADING = {
  type: "INTENT_LOADING",
  name: "intentLoading"
};

export const INTENT_PARAMS_CHANGE = {
  type: "INTENT_PARAMS_CHANGE",
  name: "intentParamsChange"
};

export const CREATE_INTENT = {
  type: "CREATE_INTENT",
  name: "createIntent"
};

export const FETCH_INTENTS = {
  type: "FETCH_INTENTS",
  name: "fetchIntents"
};

export const SEARCH_INTENTS_IN_EXAMPLE = {
  type: "SEARCH_INTENTS_IN_EXAMPLE",
  name: "searchIntentsInExample"
}

export const SINGLE_INTENT = {
  type: "SINGLE_INTENT",
  name: "singleIntent"
};

export const DELETE_INTENT={
  type: "DELETE_INTENT",
  name: "deleteIntent"
};