import _ from "lodash";
import toastr from "toastr";
import { 
  transliterationExampleState,
  reducerName, 
  EXAMPLE_LOADING,
  EXAMPLE_DATA_CHANGE,
  EXAMPLE_PARAMS_CHANGE,
  CREATE_EXAMPLE,
  FETCH_EXAMPLES,
  DELETE_SINGLE_EXAMPLE,
  DELETE_EXAMPLE,
  UPDATE_EXAMPLE,
  ADD_EXAMPLE_TO_DATASET,
  DELETE_EXAMPLE_FEEDBACK,
  UPDATE_MULTIPLE_EXAMPLE
} from "../../states/transliteration/transliteration-example-state";
import * as TransliterationExampleApi from "../../apis/transliteration/transliteration-example-api";
import ReducerFactory from "../../../utils/reducer-factory";

const initialState = {...transliterationExampleState};
const reducerFactory = new ReducerFactory(reducerName, initialState);

reducerFactory.addAction(EXAMPLE_LOADING.type, EXAMPLE_LOADING.name,
  status => status, (state,action)=>{
		const newState = _.cloneDeep(state);
    newState.loading = action.data;
    return newState;
  }
);

reducerFactory.addAction(EXAMPLE_PARAMS_CHANGE.type, EXAMPLE_PARAMS_CHANGE.name,
  async data=> data,(state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.length > 0){
      action.data.forEach(d=>{
        const { key, value } = d;
        newState[key] = value;
      })
    }  
    return newState;
  } 
);

reducerFactory.addAction(EXAMPLE_DATA_CHANGE.type, EXAMPLE_DATA_CHANGE.name,
  data=> data,(state,action)=>{
    const newState = _.cloneDeep(state);
    const { key, value } = action.data;  
    newState[key] = value;
    return newState;
  } 
);

reducerFactory.addAction(CREATE_EXAMPLE.type, CREATE_EXAMPLE.name,
  async data=>{
    const modeData = data.mode;
    delete data.mode;
    const response = await TransliterationExampleApi.createExample(data);
    response.data["mode"] = modeData;
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.examples.unshift(action.data.data);
      const newExampleList = newState.examples.splice(0,newState.pageSize);
      newState.examples = newExampleList;
      newState.count++;
    }
    if(action && action.data){
      if(action.data.mode === "test"){
        toastr[action.data.success ? "success" : "warning"](action.data.message);
      }
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction(FETCH_EXAMPLES.type, FETCH_EXAMPLES.name,
  async(data)=>{
    if(data.loadFlag){
      reducerFactory.action(EXAMPLE_PARAMS_CHANGE.name, [{key: "exampleListLoading", value: true}]);
    }
    delete data.loadFlag;
    const response = await TransliterationExampleApi.fetchExamples(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      let {examples} = action.data.data;
      if(examples){
        newState.examples = action.data.data.examples;
        newState.count = action.data.data.count;
      }
    }
    newState.exampleListLoading = false;
    return newState;
  }
);

reducerFactory.addAction(UPDATE_EXAMPLE.type, UPDATE_EXAMPLE.name,
  async data=>{
    const response = await TransliterationExampleApi.updateExample(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      const { exampleId } = action.data.data;
      const findExampleIndex = newState.examples.findIndex(ex=>ex.exampleId === exampleId);
      if(findExampleIndex !== -1){
        newState.examples[findExampleIndex] = action.data.data;
      }
      if(newState.oneExample.exampleId === exampleId){
        newState.oneExample = action.data.data;
      }
    }
    return newState;
  }
);

reducerFactory.addAction(UPDATE_MULTIPLE_EXAMPLE.type, UPDATE_MULTIPLE_EXAMPLE.name,
  async data=>{
    const response = await TransliterationExampleApi.updateMultipleExamples(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data){
      action.data.success ? toastr.success(action.data.message) : toastr.error(action.data.message);
    }
    return newState;
  }
);



reducerFactory.addAction(DELETE_SINGLE_EXAMPLE.type, DELETE_SINGLE_EXAMPLE.name,
  async data => {
    const response = await TransliterationExampleApi.deleteExample(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    return newState;
  }
);

reducerFactory.addAction(DELETE_EXAMPLE.type, DELETE_EXAMPLE.name,
  async data=>{
    const response = await TransliterationExampleApi.deleteMultipleExample(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    return newState;
  }
);


reducerFactory.addAction(ADD_EXAMPLE_TO_DATASET.type, ADD_EXAMPLE_TO_DATASET.name,
  async data=>{
    const response = await TransliterationExampleApi.addExampleToDataset(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    return newState;
  } 
);

reducerFactory.addAction(DELETE_EXAMPLE_FEEDBACK.type, DELETE_EXAMPLE_FEEDBACK.name,
  async data => {
    const response = await TransliterationExampleApi.deleteFeedbackExample(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    return newState;
  }
);


export default reducerFactory;

