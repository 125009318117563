import { apiMethod } from "../index";
import { HTTP_METHOD } from "../../../constants/common-constant";
import { CREATE_ONE_EXAMPLE, GET_SINGLE_EXAMPLE, DELETE_EXAMPLE, GET_EXAMPLES, UPDATE_EXAMPLE, EXAMPLE_INTENT_DISTRIBUTION, UPDATE_ENTITY_IN_EXAMPLE, UPDATE_INTENT_IN_EXAMPLE ,
  CREATE_NEW_ENTITY_FROM_EXAMPLE, FETCH_ENTITY_LIST_FROM_BASKET, DELETE_ITEM_FROM_BASKET, GET_ENTITIES, UPDATE_TEXT_IN_EXAMPLE, DELETE_ONE_EXAMPLE,
  CREATE_NEW_INTENT_FROM_EXAMPLE, ADD_EXAMPLE_TO_DATASET, UPDATE_TYPE_IN_EXAMPLE, DELETE_EXAMPLE_FEEDBACK,
  UPDATE_MULTIPLE_EXAMPLES
} from "../../endpoints/nlu-endpoint";

const { GET, POST, PUT, DELETE } = HTTP_METHOD;

export const createExample = data => apiMethod(POST, CREATE_ONE_EXAMPLE, data);

export const fetchExamples = data => apiMethod(POST, GET_EXAMPLES, data);

export const fetchOneExample = data => apiMethod(GET, GET_SINGLE_EXAMPLE, {
  params:{
    exampleId: data.exampleId
  }
});

export const updateExample = data => apiMethod(PUT, UPDATE_EXAMPLE, data);

export const updateMultipleExamples = data => apiMethod(PUT, UPDATE_MULTIPLE_EXAMPLES, data);

export const updateTypeinExmaple = data => apiMethod(POST, UPDATE_TYPE_IN_EXAMPLE, data);

export const updateTextInExample = data => apiMethod(POST, UPDATE_TEXT_IN_EXAMPLE, data);

export const updateIntentinExample = data => apiMethod(POST, UPDATE_INTENT_IN_EXAMPLE, data);

export const createNewIntentFromExample = data => apiMethod(POST, CREATE_NEW_INTENT_FROM_EXAMPLE, data);

export const updateEntityinExample = data => apiMethod(POST, UPDATE_ENTITY_IN_EXAMPLE, data);

export const createNewEntityFromExample = data => apiMethod(POST, CREATE_NEW_ENTITY_FROM_EXAMPLE, data);

export const addExampleToDataset = data => apiMethod(POST, ADD_EXAMPLE_TO_DATASET, data);

export const deleteSingleExample = data => apiMethod(DELETE, DELETE_ONE_EXAMPLE, {
  params:{
    exampleId: data.exampleId
  }
});

export const deleteFeedbackExample = data => apiMethod(DELETE, DELETE_EXAMPLE_FEEDBACK, {
  params:{
    exampleId: data.exampleId
  }
});


export const deleteExample = data => apiMethod(DELETE, DELETE_EXAMPLE, {data});

export const intentExamples = data => apiMethod(POST, EXAMPLE_INTENT_DISTRIBUTION, data);

export const fetchBasketEntities = data => apiMethod(POST, FETCH_ENTITY_LIST_FROM_BASKET, data);

export const deleteBasketEntity = data => apiMethod(DELETE, DELETE_ITEM_FROM_BASKET, {data});

export const getSynonymList = data => apiMethod(POST, GET_ENTITIES, data);