import React from "react";
import { Row, Col, Card, Input, Button, Space } from "antd";
import Footer from "../../components/common/footer";

import NsLogo from "../../assets/images/logo.svg";
import ButtonLoading from "../../components/loading/button-loading";
import { API_URL } from "../../constants/common-constant";


const footerStyle = {
  position: "fixed",
  left: "50%",
  bottom: 0,
  transform: "translate(-50%, -50%)",
  margin: "0 auto"
}

const cardStyle = {
  width: "80vw",
  minHeight: "52vh"
}

class Translate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      loading: false,
      translation: "",
      error: ""
    }
  }

  onClickTranslate = () => {
    this.setState({ loading: true }, () => {
      const { text } = this.state;
      fetch(`${API_URL}/translation/es`, {
        method: "POST",
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({text})
      }).then(res => res.json())
        .then(res => {
          const { responseData } = res.data;
          if (responseData && responseData.translatedText) {
            this.setState({ translation: responseData.translatedText })
          }
          this.setState({ loading: false });
        }).catch(error => {
          this.setState({ loading: false })
          console.log(error);
        })
    })
  }

  onClear = () => {
    this.setState({
      text: "",
      loading: false,
      translation: "",
      error: ""
    })
  }

  onChangeText = (event) => {
    this.setState({ text: event.target.value });
  }

  render() {
    const { text, loading, translation } = this.state;

    return (
      <Row className="ns-auth">
        <Col className="ns-p-0 ns-m-0" xl={24} lg={24} md={24} sm={24} xs={24}>
          <div>
            <div className="ns-d-flex ns-justify-content-center ns-w-100" style={{ paddingTop: "6vh" }}>
              <div className="ns-login-header ns-center-align">
                <img className="ns-auth-logo" src={NsLogo} alt="logo" />
                <h4 className="ns-login-title">Translation</h4>
              </div>
            </div>
            <div className="ns-d-flex ns-justify-content-center ns-w-100">
              <Card className="ns-card" style={{ ...cardStyle }}>
                <Row gutter={[16, 16]}>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Input.TextArea
                      placeholder="Write something here.."
                      autoSize={{ minRows: 20, maxRows: 24 }}
                      onChange={this.onChangeText}
                      value={text}
                    />
                    <Space className="ns-mt-3">
                      <Button onClick={this.onClickTranslate} disabled={!text || loading} type="primary">
                        {loading && <ButtonLoading />}
                        Translate
                      </Button>
                      <Button type="primary" ghost onClick={this.onClear}>Clear</Button>
                    </Space>
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <p className="ns-text-center"><b>Result</b></p>
                    {translation && (
                      <div
                        style={{maxHeight: "52vh", overflow: "scroll"}}
                        dangerouslySetInnerHTML={{ __html: translation }}
                      />
                    )}
                  </Col>
                </Row>
              </Card>
            </div>
            <div style={{ ...footerStyle }}>
              <Footer />
            </div>
          </div>
        </Col>
      </Row>
    )
  }
}

export default Translate;