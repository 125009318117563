import { apiMethod } from "../index";
import { HTTP_METHOD } from "../../../constants/common-constant";
import { CREATE_PROJECT, GET_ALL_PROJECTS, GET_PROJECTS, UPDATE_PROJECT, DELETE_PROJECT, GET_SINGLE_PROJECT } from "../../endpoints/transliteration-endpoint";

const { GET, POST, PUT, DELETE } = HTTP_METHOD;

export const createProject = data => apiMethod(POST, CREATE_PROJECT, data);

export const fetchProjects = data => apiMethod(POST, GET_PROJECTS, data);

export const fetchAllProjects = () => apiMethod(GET, GET_ALL_PROJECTS);

export const fetchOneProject = data => apiMethod(GET, GET_SINGLE_PROJECT, {
  params:{
    projectId: data.projectId,
    targetLanguage: data.targetLanguage
  }
});

export const updateProject = data => apiMethod(PUT, UPDATE_PROJECT, data);

export const deleteProject = data => apiMethod(DELETE, DELETE_PROJECT, {data});