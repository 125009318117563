import React from "react";
import { Scrollbars } from "react-custom-scrollbars";

class Scrollbar extends React.Component{
  render(){
    return(
      <Scrollbars 
        {...this.props}
        autoHide
        renderTrackHorizontal={props=> <div {...props} style={{display: "none"}} className="track-horizontal" />}
      />
    )
  }
}

export default Scrollbar;