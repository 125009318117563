import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Divider, Menu } from "antd";
import { Link, withRouter } from "react-router-dom";

import SidebarLogo from "./sidebar-logo";
import { getMainSelectedMenu, validArray } from "../../../../helpers/common-helper";
import { THEME_TYPE_LIGHT } from "../../../../constants/theme-setting-constants";
// import NewLabel from "../../../../components/common/new-label";
import Scrollbar from "../../../../components/scrollbar/scrollbar";
import IntlMessage from "../../../../components/intl-message/intl-message";
import * as SettingsState from "../../../../redux/states/settings-state";
import * as AuthState from "../../../../redux/states/auth-state";
import { commonRoutes, appRoutes, homeRoutes, docRoutes } from "../../../../routes/common";
import AppLists from "../../app-containers/app-lists";
// import { NER, STT, TRANSLITERATION } from "../../../../constants/common-constant";
import { getServiceSelectedMenu } from "../../../../helpers/app-helper";

// const newApps = [NER, TRANSLITERATION, STT];

class SidebarContent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      installAppDrawer: false
    }
  }

  onToggleApps = () => {
    this.setState({ installAppDrawer: !this.state.installAppDrawer });
  }

  render() {
    const { sidebarCollapsed, onSidebarCollapsed, themeType, apps } = this.props;
    const { installAppDrawer } = this.state;
    const selectedMainMenuData = getMainSelectedMenu(this.props.location.pathname);
    const selectedServiceMenuData = getServiceSelectedMenu(this.props.location.pathname);
    return (
      <>
        <SidebarLogo sidebarCollapsed={sidebarCollapsed} onSidebarCollapsed={onSidebarCollapsed} />
        <div className="ns-sidebar-content">
          <Scrollbar className="ns-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[selectedMainMenuData.openSelectedMenu]}
              selectedKeys={[selectedMainMenuData.selected]}
              theme={themeType === THEME_TYPE_LIGHT ? 'light' : 'dark'}
              mode="inline"
              className="ns-mt-2"
            >
              {homeRoutes().map(route => {
                return (
                  <Menu.Item key={route.key} className="ns-menu-item-group">
                    <Link to={route.path}>
                      <i className={route.icon} />
                      <span>
                        <IntlMessage id={route.i18Message} />
                      </span>
                    </Link>
                  </Menu.Item>
                )
              })}
            </Menu>
            <Divider className="ns-app-sidebar-divider" />
            <Menu
              defaultOpenKeys={[selectedServiceMenuData.openSelectedMenu]}
              selectedKeys={[selectedServiceMenuData.selected]}
              theme={themeType === THEME_TYPE_LIGHT ? 'light' : 'dark'}
              mode="inline"
            >
              <Menu.ItemGroup key="Service" className="ns-menu-group ns-pt-3 ns-h-100" title={<IntlMessage id="sidebar.apps" />}>
                {appRoutes(apps).map(app => {
                  if (validArray(app.subRoutes)) {
                    return (
                      <Menu.SubMenu key={app.key} title={
                        <span>
                          <i className={app.icon} />
                          <span><IntlMessage id={app.i18Message} /></span>
                        </span>
                      }>
                        {app.subRoutes.map(sr => (
                          <Menu.Item key={sr.key} className="ns-menu-item-group">
                            <Link to={sr.path}>
                              <i className={sr.icon} />
                              <span>
                                <IntlMessage id={sr.i18Message} />
                              </span>
                            </Link>
                          </Menu.Item>
                        ))}
                      </Menu.SubMenu>
                    )
                  } else {
                    return (
                      <Menu.Item key={app.key} className="ns-menu-item-group">
                        <Link to={app.path}>
                          <i className={app.icon} />
                          <span>
                            <IntlMessage id={app.i18Message} />
                          </span>
                        </Link>
                      </Menu.Item>
                    )
                  }
                })}
              </Menu.ItemGroup>
            </Menu>
            <Divider className="ns-app-sidebar-divider" />
            <Menu
              defaultOpenKeys={[selectedMainMenuData.openSelectedMenu]}
              selectedKeys={[selectedMainMenuData.selected]}
              theme={themeType === THEME_TYPE_LIGHT ? 'light' : 'dark'}
              mode="inline"
            >
              <Menu.ItemGroup key="main" className="ns-menu-group ns-pt-3" title={<IntlMessage id="sidebar.main" />}>
                {commonRoutes().map(route => {
                  if (validArray(route.subRoutes)) {
                    return (
                      <Menu.SubMenu key={route.key} title={
                        <span>
                          <i className={route.icon} />
                          <span>
                            <IntlMessage id={route.i18Message} />
                          </span>
                        </span>
                      }>
                        {route.subRoutes.map(r => (
                          <Menu.Item key={r.key} className="ns-menu-item-group">
                            <Link to={r.path}>
                              <i className={r.icon} />
                              <span>
                                <IntlMessage id={r.i18Message} />
                              </span>
                            </Link>
                          </Menu.Item>
                        ))}
                      </Menu.SubMenu>
                    )
                  } else {
                    return (
                      <Menu.Item key={route.key} className="ns-menu-item-group">
                        <Link to={route.path}>
                          <i className={route.icon} />
                          <span>
                            <IntlMessage id={route.i18Message} />
                          </span>
                        </Link>
                      </Menu.Item>
                    )
                  }
                })}
              </Menu.ItemGroup>
            </Menu>
            <Divider className="ns-app-sidebar-divider" />
            <Menu
              defaultOpenKeys={[selectedMainMenuData.openSelectedMenu]}
              selectedKeys={[selectedMainMenuData.selected]}
              theme={themeType === THEME_TYPE_LIGHT ? 'light' : 'dark'}
              mode="inline"
            >
              {docRoutes().map(route=>(
                <Menu.Item key={route.key} className="ns-menu-item-group">
                <a href={route.link} target="_blank" rel="noreferrer">
                  <i className={route.icon} />
                  <span>
                    <IntlMessage id={route.i18Message} />
                    <i className="icon icon-growth ns-ml-2" />
                  </span>
                </a>
              </Menu.Item>
              ))}
            </Menu>
          </Scrollbar>
          <AppLists
            drawer={installAppDrawer}
            onToggleApps={this.onToggleApps}
          />
        </div>
      </>
    )
  }
}

SidebarContent.propTypes = {
  themeType: PropTypes.string.isRequired,
  sidebarCollapsed: PropTypes.bool.isRequired,
  onSidebarCollapsed: PropTypes.func.isRequired,
  apps: PropTypes.array.isRequired,
  credit: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired
}

const SidebarContentContainer = connect(
  state => ({
    themeType: state.get(SettingsState.reducerName).themeType,
    apps: state.get(AuthState.reducerName).apps,
    user: state.get(AuthState.reducerName).user,
    credit: state.get(AuthState.reducerName).credit
  }),
  dispatch => ({

  })
)(SidebarContent);

export default withRouter(SidebarContentContainer)

