import { apiMethod } from "../index";
import { HTTP_METHOD } from "../../../constants/common-constant";
import { TRANSLITERATION_DASHBOARD_COUNT, TRANSLITERATION_DASHBOARD_API_CALL_GRAPH, TRANSLITERATION_DASHBOARD_DEPLOYED_MODELS, TRANSLITERATION_DASHBOARD_EXAMPLE_COUNT_HISTOGRAM } from "../../endpoints/transliteration-endpoint";
import { MAIN_DASHOBOARD_APP_SPECIFIC_STATS } from "../../endpoints/common-endpoint";

const { GET, POST } = HTTP_METHOD;

export const getTransliterationDashboard = () => apiMethod(GET, TRANSLITERATION_DASHBOARD_COUNT);

export const getTransliterationDashboardApiCount = data => apiMethod(POST, TRANSLITERATION_DASHBOARD_API_CALL_GRAPH, data);

export const getTransliterationDeployedModels = () => apiMethod(GET, TRANSLITERATION_DASHBOARD_DEPLOYED_MODELS);

export const getTransliterationDashboardStats = data => apiMethod(GET, MAIN_DASHOBOARD_APP_SPECIFIC_STATS, {
  params:{
    appType: data.appType
  }
});

export const getExamplesPerProjectHistogram = () => apiMethod(GET, TRANSLITERATION_DASHBOARD_EXAMPLE_COUNT_HISTOGRAM);