import moment from "moment";
import Millify from "millify";
import _ from "lodash";

import { NER, NLU, LANGUAGE_DETECTION, AUGMENTATION, TRANSLATION, TRANSLITERATION, GENERIC_APP_TYPE, STT,
  NLU_NAME, NER_NAME, LANGUAGE_DETECTION_NAME, AUGMENTATION_NAME, TRANSLATION_NAME, TRANSLITERATION_NAME, PLATFORM_AUTH, STT_NAME,
  SPEAKER_IDENTIFICATION, SPEAKER_IDENTIFICATION_NAME, VOICE_EXTRACTOR, TTS, VOICE_EXTRACTOR_NAME, TTS_NAME
} from "../constants/common-constant";
import { bulkDatasetsFileFormats, languageMap } from "../utils/ui";
import { nluLanguages } from "../utils/apps/nlu-utils";
import { translationLanguages } from "../utils/apps/translation-utils";
import { transliterationLanguages } from "../utils/apps/transliteration-utils";
import { ldLanguages } from "../utils/apps/language-detection-util";
import { augmentationLanguages } from "../utils/apps/augmentation-util";
import { sttLanguages } from "../utils/apps/transcription-util";
import { applicationRoutes } from "../routes/common";
import { nerLanguages } from "../utils/apps/ner-utils";
const $ = window.$;

const getColors = {
  10: {
      outer: "color: #1d39c4; background: #f0f5ff; border-color: #adc6ff;",
      inner: "background: #adc6ff;",
      bg: "#f0f5ff"
  }
}

export const validObject = obj => obj && Object.keys(obj).length > 0;

export const validStringCheck = str => str && str.trim() && str.trim().length > 0;

export const validPasswordCheck = password => /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$%^&*-.]).{6,}$/.test(password)

export const validArray = arr => arr && arr.length > 0;

export const validStringCheckwithLength = (str, num) => str && str.trim() && str.trim().length >= num;

export const validStringCheckwithMaxLength = (str, num) => str && str.trim() && str.trim().length <= num;

export const escapeRegex = (term)=>{
  return term.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

export const getElipsisText = (text , value) => text && text.length > value ? `${text.substring(0,value)}...` : text;

export const isResponsive= (windowWidth) => windowWidth < 992;

export const getSearchedLanguage = (search, langList) => {
  search = search ? escapeRegex(search) : ".";
  let searchRegex = new RegExp(search, "i");
  const list = langList.filter(lang=>searchRegex.test(lang));
  return list
}

export const REMOVED_APPS = [STT, SPEAKER_IDENTIFICATION, LANGUAGE_DETECTION, TRANSLATION, TRANSLITERATION, AUGMENTATION, TTS, VOICE_EXTRACTOR]
export const encodeUriValueConvertor = value => value.replace(/&/, "X410suC0");
export const decodeUriValueConvertor = value => value.replace(/X410suC0/, "&");

export const getLanguageInfo = {
  [NLU] : {
    btnLabel: `${nluLanguages.length} Languages`,
    languageList: nluLanguages,
    modalTitle: `Supported Languages in ${NLU_NAME}`
  },
  [NER]: {
    btnLabel: `${nerLanguages.length} Languages`,
    languageList: nerLanguages,
    modalTitle: `Supported Languages in ${NER_NAME}`
  },
  [TRANSLATION]: {
    btnLabel: `${translationLanguages.length} Languages`,
    languageList: translationLanguages,
    modalTitle: `Supported Languages in ${TRANSLATION_NAME}`
  },
  [TRANSLITERATION]: {
    btnLabel: `${transliterationLanguages.length} Language Pairs`,
    languageList: transliterationLanguages,
    modalTitle: `Supported Languages in ${TRANSLITERATION_NAME}`
  },
  [LANGUAGE_DETECTION]: {
    btnLabel: `${ldLanguages.length} Languages`,
    languageList: ldLanguages,
    modalTitle: `Supported Languages in ${LANGUAGE_DETECTION_NAME}`
  },
  [AUGMENTATION]: {
    btnLabel: `${augmentationLanguages.length} Languages`,
    languageList: augmentationLanguages,
    modalTitle: `Supported Languages in ${AUGMENTATION_NAME}`
  },
  [STT]: {
    btnLabel: `${sttLanguages().length} Languages`,
    languageList: sttLanguages(),
    modalTitle: `Supported Languages in ${STT_NAME}`
  }
}

export const getMainSelectedMenu = pathname => {
  let selected  = pathname;
  let openSelectedMenu = selected.split("/")[2];
  if(selected === applicationRoutes.ROOT_ROUTE){
    selected = applicationRoutes.DASHBOARD_ROUTE;
  }
  return {selected, openSelectedMenu};
}

export const setAccessToken = token => {
  window.localStorage.setItem(PLATFORM_AUTH, token);
}

export const getAccessToken = () => window.localStorage.getItem(PLATFORM_AUTH);

export const isLoggedin = getAccessToken() ? true : false;

export const getUsername = user => {
  let username = "Platform user";
  if (user && user.name) {
    username = user.name.split(" ")[0];
  }
  return username;
}

export const getInstalledAppsText = (apps) =>{
  let text = "";
  if(apps){
    if(apps.length === 1) text = "1 Service installed";
    else text = `Total ${apps.length} Services installed`;
  }
  return text;
}

export const getLangCodes = (languages) => languages.map(lang => languageMap[lang]);

export const getSearchedString = (search, arr)=>{
  search = search ? escapeRegex(search) : ".";
  let searchRegex = new RegExp(search, "i");
  const stringData = arr.filter(a=>searchRegex.test(a));
  return stringData;
}

export const getSearchedStringWithOffset = (search, arr, offset)=>{
  search = search ? escapeRegex(search) : ".";
  let searchRegex = new RegExp(search, "i");
  const stringData = arr.filter(a=>searchRegex.test(a));
  return stringData.slice(0,offset);
}

export const findTextRange = (element) => {
  if (!element || (!window.getSelection().toString())) return;
  let start = 0, end = 0;
  let sel, range, priorRange, text;
  const html = element.innerHTML;
  element.querySelectorAll('.ne-c-inner').forEach((e) => e.remove());
  if (typeof window.getSelection != "undefined") {
    sel = window.getSelection();
    text = sel.toString();
    if (window.getSelection().rangeCount <= 0) {
      return;
    }
    range = window.getSelection().getRangeAt(0);
    priorRange = range.cloneRange();
    priorRange.selectNodeContents(element);
    priorRange.setEnd(range.startContainer, range.startOffset);
    start = priorRange.toString().length;
    end = start + (sel.toString()).length;
  } else if (typeof document.selection !== "undefined" &&
    (sel = document.selection).type !== "Control") {
    text = sel.toString();
    range = sel.createRange();
    priorRange = document.body.createTextRange();
    priorRange.moveToElementText(element);
    priorRange.setEndPoint("EndToStart", range);
    start = priorRange.text.length;
    end = start + (sel.toString()).length;
  }
  element.innerHTML = html;
  return { start, end, text: text ? text.trim() : "" };
}

export const getTextData = (elementId) => $(`#${elementId}`).clone().find(".ne-c-inner").remove().end().text()

export const secondsToString = second => {
  let customeTime = "";
  const hours = Math.floor(second/ (3600));
  const minutes = Math.floor((second % 3600)/60);
  customeTime = `${hours} hours ${minutes} minutes`;
  return customeTime;
}

export const appInstallFlag = (apps, appType) => apps && apps.includes(appType);

export const getPluralTexts = (count, singular, plural) =>{
  if(count && count > 1) return `${Millify(count || 0)} ${plural}`;
  else return `${count || 0} ${singular}`;
}

export const searchEntities = (entityList, search, offset) => {
  if (!validArray(entityList)) return [];
  search = search ? escapeRegex(search) : ".";
  let searchRegex = new RegExp(search, "i");
  const stringData = entityList.filter(a => {
      let condition = searchRegex.test(a.entity);
      return condition;
  });
  return stringData.slice(0, offset);
}

export const getConvertExampleData = exampleData => {
  let {entities, text} = exampleData;
  const deepEntities = _.cloneDeep(entities);
  deepEntities.sort((a,b)=> b.start - a.start);
  let newEntities = [];
  deepEntities.map(d=> newEntities.filter(a=> a.start === d.start && a.end === d.end).length > 0 ? null : newEntities.push(d));
  let inserted = [];
  newEntities.forEach(r=>{
    const { start, end, entity, value } = r;
    let modifiedStart = start, modifiedEnd = end;
    const color = getColors[10];
    for(let i in inserted){
      if(i < start){
        modifiedStart += inserted[i];
      }
      if(i<=end){
        modifiedEnd += inserted[i];
      }
    }
    let preText = `<span contenteditable="false" data-action="${value}---${start}---${end}---${color.bg}---${entity}" unselectable="on" onselectstart="return false;" name="entity" class="ne-c" style="${color.outer}">`;
    let postText = `<span class="ne-c-inner" unselectable="on" onselectstart="return false;" data-action="${value}---${start}---${end}---${color.bg}---${entity}" style="${color.inner}">${entity}</span></span>`;

    inserted[start] = (inserted[start] || 0) + preText.length;
    inserted[end] = (inserted[end] || 0) + postText.length;

    text = text.substring(0, modifiedStart) +
          preText +
          text.substring(modifiedStart, modifiedEnd) +
          postText +
          text.substring(modifiedEnd);
  });
  return text;
}

export const graphTimeRange = [
  {
    key: 1,
    label: "Last 1 hour",
    fromDate: moment().subtract(1, "hour"),
    toDate: moment()
  },
  {
    key: 2,
    label: "Last 12 hours",
    fromDate: moment().subtract(12, "hours"),
    toDate: moment()
  },
  {
    key: 3,
    label: "Last 24 hours",
    fromDate: moment().subtract(1, "day"),
    toDate: moment()
  },
  {
    key: 4,
    label: "Last week",
    fromDate: moment().subtract(7, "days"),
    toDate: moment()
  },
  {
    key: 5,
    label: "Last month",
    fromDate: moment().subtract(1, "month"),
    toDate: moment()
  },
  {
    key: 6,
    label: "Last 3 months",
    fromDate: moment().subtract(3, "months"),
    toDate: moment()
  },
  {
    key: 7,
    label: "Last 6 months",
    fromDate: moment().subtract(6, "months"),
    toDate: moment()
  },
  {
    key: 8,
    label: "Last year",
    fromDate: moment().subtract(1, "year"),
    toDate: moment()
  }
]
export const dashboardTimeRange = {
  "first": [
    {
      key: 0,
      label: "Last 1 minute",
      fromDate: moment().subtract(1, "minute"),
      toDate: moment()
    },
    {
      key: 1,
      label: "Last 30 minutes",
      fromDate: moment().subtract(30, "minutes"),
      toDate: moment()
    },
    {
      key: 2,
      label: "Last 1 hour",
      fromDate: moment().subtract(1, "hour"),
      toDate: moment()
    },
    {
      key: 3,
      label: "Last 12 hours",
      fromDate: moment().subtract(12, "hours"),
      toDate: moment()
    },
    {
      key: 4,
      label: "Last 24 hours",
      fromDate: moment().subtract(1, "day"),
      toDate: moment()
    },
  ],
  "second": [
    {
      key: 5,
      label: "Last week",
      fromDate: moment().subtract(7, "days"),
      toDate: moment()
    },
    {
      key: 6,
      label: "Last month",
      fromDate: moment().subtract(1, "month"),
      toDate: moment()
    },
    {
      key: 7,
      label: "Last 3 months",
      fromDate: moment().subtract(3, "months"),
      toDate: moment()
    },
    {
      key: 8,
      label: "Last 6 months",
      fromDate: moment().subtract(6, "months"),
      toDate: moment()
    },
    {
      key: 9,
      label: "Last year",
      fromDate: moment().subtract(1, "year"),
      toDate: moment()
    }
  ]
}

export const getShortcutList = () => {
  let macOS = false;
  const userAgent = navigator.userAgent;
  if (userAgent.indexOf("Mac") !== -1) macOS = true;
  return [
    {
      label: "Toggle Shortcuts",
      key: `${macOS ? "Cmd" : "Ctrl"} + k`
    },
    {
      label: "Copy apikey",
      key: `${macOS ? "Cmd" : "Ctrl"} + ${macOS ? "Opt" : "Alt"} + c`
    },
    {
      label: "Copy access token",
      key: `${macOS ? "Cmd" : "Ctrl"} + ${macOS ? "Opt" : "Alt"} + a`
    },
    {
      label: "Services",
      key: `${macOS ? "Cmd" : "Ctrl"} + o`
    },
    {
      label: "Create example",
      key: `${macOS ? "Cmd" : "Ctrl"} + enter`
    },
    {
      label: "Toggle intent",
      key: `${macOS ? "Opt" : "Alt"} + i`
    },
    {
      label: "Copy exampleId",
      key: `${macOS ? "Opt" : "Alt"} + e`
    },
    {
      label: "Delete example",
      key: `${macOS ? "Opt" : "Alt"} + d`
    },
    {
      label: "Save example",
      key: `${macOS ? "Opt" : "Alt"} + s`
    },
    {
      label: "Copy text",
      key: `${macOS ? "Opt" : "Alt"} + c`
    },
    {
      label: "Change example type",
      key: `${macOS ? "Opt" : "Alt"} + t`
    },
    {
      label: "Prepare example",
      key: `${macOS ? "Opt" : "Alt"} + p`
    },
    {
      label: "Toggle View Entities",
      key: `${macOS ? "Opt" : "Alt"} + b`
    },
    {
      label: "Toggle synonym",
      key: `${macOS ? "Opt" : "Alt"} + a`
    }
  ]
}

export const getSearchedShortcuts = search => {
  search = search ? escapeRegex(search) : ".";
  let searchRegex = new RegExp(search, "i");
  const shortcuts = getShortcutList().filter(s=>searchRegex.test(s.label));
  return shortcuts
}

export const appNameMap = {
  [NLU]: NLU_NAME,
  [NER]: NER_NAME,
  [TRANSLATION]: TRANSLATION_NAME,
  [TRANSLITERATION]: TRANSLITERATION_NAME,
  [AUGMENTATION]: AUGMENTATION_NAME,
  [LANGUAGE_DETECTION]: LANGUAGE_DETECTION_NAME,
  [GENERIC_APP_TYPE]: GENERIC_APP_TYPE,
  [STT]: STT_NAME,
  [SPEAKER_IDENTIFICATION]: SPEAKER_IDENTIFICATION_NAME,
  [VOICE_EXTRACTOR]: VOICE_EXTRACTOR_NAME,
  [TTS]: TTS_NAME
}

export const docsLink = {
  [NLU]: "https://docs.neuralspace.ai/natural-language-understanding/overview",
  [`${NLU}_PROJECT`]: "https://docs.neuralspace.ai/natural-language-understanding/concepts/project/",
  [NER]: "https://docs.neuralspace.ai/entity-recognition/overview",
  [TRANSLATION]: "https://docs.neuralspace.ai/machine-translation/overview",
  [TRANSLITERATION]: "https://docs.neuralspace.ai/transliteration/overview",
  [AUGMENTATION]: "https://docs.neuralspace.ai/augmentation/overview",
  [LANGUAGE_DETECTION]: "https://docs.neuralspace.ai/language-detection/overview",
  [GENERIC_APP_TYPE]: "https://docs.neuralspace.ai/",
  [SPEAKER_IDENTIFICATION]: "https://docs.neuralspace.ai/speaker-identification/overview",
  [STT]: "https://docs.neuralspace.ai/speech-to-text/overview",
  [VOICE_EXTRACTOR]: "https://docs.neuralspace.ai/voice-extraction/overview",
  [TTS]: "https://docs.neuralspace.ai/text-to-speech/overview"
}

export const supportFileFormats = fileType =>{
  let fileFormats = bulkDatasetsFileFormats;
  if(fileType === "yml"){
    fileFormats = ".yml, .yaml";
  }else if(fileType === "csv"){
    fileFormats = ".csv";
  }else if(fileType === "json"){
    fileFormats = ".json";
  }
  return fileFormats
}
