export const reducerName = "nerExample";
export const nerExampleState = {
  loading: false,
  exampleListLoading: false,
  exampleEntityDistLoading: false,
  oneExample: {},
  pageNumber: 1,
  pageSize: 15,
  search: "",
  examples: [],
  count: 0,
  unpreparedCount: 0,
  selectedExamples: [],
  exampleEntityDistribution: [],
  selectedLanguage: "",
  selectedEntityType: "all",
  selectedEntity: "",
  exampleType: "Train",
  autoDetectEntities: false,
  phoneticTyping: false,
  //Filter states

  filterPrepared: "all",
  filterEntityType: "all",
  filterExampleType: "all",
  filterEntity: "All",

  //basket item states
  basketLoading: false,
  basketEntities: [],
  basketEntitiesCount: 0,

  //create example states
  newExample: {
    text: "",
    entities: [],
    styledText: ""
  },

  error: null
}

export const EXAMPLE_LOADING = {
  type: "NER_EXAMPLE_LOADING",
  name: "nerexampleLoading"
};

export const EXAMPLE_PARAMS_CHANGE = {
  type: "NER_EXAMPLE_PARAMS_CHANGE",
  name: "nerexampleParamsChange"
};

export const EXAMPLE_DATA_CHANGE = {
  type: "NER_EXAMPLE_DATA_CHANGE",
  name: "nerexampleDataChange"
};

export const CREATE_EXAMPLE = {
  type: "NER_CREATE_EXAMPLE",
  name: "nercreateExample"
};

export const FETCH_EXAMPLES = {
  type: "NER_FETCH_EXAMPLES",
  name: "nerfetchExamples"
};

export const SINGLE_EXAMPLE = {
  type: "NER_SINGLE_EXAMPLE",
  name: "nersingleExample"
};

export const UPDATE_EXAMPLE = {
  type: "NER_UPDATE_EXAMPLE",
  name: "nerupdateExample"
};

export const UPDATE_MULTIPLE_EXAMPLE = {
  type: "NER_UPDATE_MULTIPLE_EXAMPLE",
  name: "nerUpdateMultipleExample"
}

export const UPDATE_EXAMPLE_FROM_SOCKET = {
  type: "NER_UPDATE_EXAMPLE_FROM_SOCKET",
  name: "nerupdateExampleFromSocket"
}

export const PREPARE_PROJECT_EXAMPLES = {
  type: "NER_PREPARE_PROJECT_EXAMPLES",
  name: "nerPrepareProjectExamples"
};

export const PREPARE_ONE_EXAMPLE = {
  type: "NER_PREPARE_ONE_EXAMPLE",
  name: "nerprepareOneExample"
};

export const UPDATE_TEXT_IN_EXAMPLE = {
  type: "NER_UPDATE_TEXT_IN_EXAMPLE",
  name: "nerupdateTextInExample"
}

export const UPDATE_TYPE_IN_EXAMPLE={
  type: "NER_UPDATE_TYPE_IN_EXAMPLE",
  name: "nerupdateTypeinExample"
}

export const UPDATE_ENTITY_IN_EXAMPLE={
  type: "NER_UPDATE_ENTITY_IN_EXAMPLE",
  name: "nerupdateEntityInExample"
};

export const CREATE_NEW_ENTITY_FROM_EXAMPLE={
  type: "NER_CREATE_NEW_ENTITY_FROM_EXAMPLE",
  name: "nercreateNewEntityFromExample"
};

export const DELETE_EXAMPLE = {
  type: "NER_DELETE_EXAMPLE",
  name: "nerdeleteExample"
};

export const DELETE_SINGLE_EXAMPLE = {
  type: "NER_DELETE_SINGLE_EXAMPLE",
  name: "nerdeleteSingleExample"
};

export const DELETE_EXAMPLE_FEEDBACK = {
  type: "NER_DELETE_EXAMPLE_FEEDBACK",
  name: "nerdeleteExampleFeedback"
}

export const ENTITY_EXAMPLES = {
  type: "NER_ENTITY_EXAMPLES",
  name: "nerentityExamples"
};

export const FETCH_BASKET_ENTITIES = {
  type: "NER_FETCH_BASKET_ENTITIES",
  name: "nerfetchBasketEntities"
};

export const DELETE_ENTITY_FROM_BASKET = {
  type: "NER_DELETE_ENTITY_FROM_BASKET",
  name: "nerdeleteEntityFromBasket"
};

export const ADD_EXAMPLE_TO_DATASET = {
  type: "NER_ADD_EXAMPLE_TO_DATASET",
  name: "neraddExampleToDataset"
}