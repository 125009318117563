import { apiMethod } from "../index";
import { HTTP_METHOD } from "../../../constants/common-constant";
import { CREATE_ONE_EXAMPLE, GET_SINGLE_EXAMPLE, DELETE_ONE_EXAMPLE, GET_EXAMPLES, UPDATE_EXAMPLE, ADD_EXAMPLE_TO_DATASET,
DELETE_EXAMPLE_FEEDBACK, UPDATE_MULTIPLE_EXAMPLES, DELETE_EXAMPLE
} from "../../endpoints/transliteration-endpoint";

const { GET, POST, PUT, DELETE } = HTTP_METHOD;

export const createExample = data => apiMethod(POST, CREATE_ONE_EXAMPLE, data);

export const fetchExamples = data => apiMethod(POST, GET_EXAMPLES, data);

export const fetchOneExample = data => apiMethod(GET, GET_SINGLE_EXAMPLE, {
  params:{
    exampleId: data.exampleId
  }
});

export const updateExample = data => apiMethod(PUT, UPDATE_EXAMPLE, data);

export const updateMultipleExamples = data => apiMethod(PUT, UPDATE_MULTIPLE_EXAMPLES, data);

export const deleteExample = data => apiMethod(DELETE, DELETE_ONE_EXAMPLE, {data});

export const deleteMultipleExample = data => apiMethod(DELETE, DELETE_EXAMPLE, {data});

export const addExampleToDataset = data => apiMethod(POST, ADD_EXAMPLE_TO_DATASET, data);

export const deleteFeedbackExample = data => apiMethod(DELETE, DELETE_EXAMPLE_FEEDBACK, {
  params:{
    exampleId: data.exampleId
  }
});
