import { apiMethod, axios } from "../index";
import { HTTP_METHOD } from "../../../constants/common-constant";
import { FETCH_NS_DATASETS, FETCH_ALL_DATATSET_UPLOADS, UPLOAD_DATASET, IMPORT_DATASET, GET_ONE_UPLOAD } from "../../endpoints/transliteration-endpoint";

const { POST } = HTTP_METHOD;

export async function uploadDataset(data){
  try{
    const response = await axios[POST](UPLOAD_DATASET, data, {
      headers: { 'content-type': 'multipart/form-data' }
    });
    return response;
  }catch(error){
    return error.response
  }
}

export const fetchOneUpload = data => apiMethod(POST, GET_ONE_UPLOAD, data);

export const importDataset = data => apiMethod(POST, IMPORT_DATASET, data);

export const fetchDatasets = data => apiMethod(POST, FETCH_NS_DATASETS, data);

export const fetchUploadLists = data => apiMethod(POST, FETCH_ALL_DATATSET_UPLOADS, data);