export const reducerName = "auth";
export const authState = {
  resetPasswordTokenText: "",
  resetPasswordText: "",
  confirmEmailStatus: false,
  confirmEmailText: "Please wait while we are confirming your email id",
  loading: false,
  user: {},
  credit: 0,
  currentUsage: 0,
  apps: [],
  profileLoading: false,
  licenseInfo:{
    usage:{},
    apps: []
  },
  error: null,
  webhooks: [],
  webhookLoading: false
}

export const AUTH_LOADING = {
  type: "AUTH_LOADING",
  name: "authLoading"
};
export const AUTH_PARAMS_CHANGE = {
  type: "AUTH_PARAMS_CHANGE",
  name: "authParamsChange"
}
export const CHECK_AUTH = {
  type: "CHECK_AUTH",
  name: "checkAuth"
};
export const AUTH_USER_CREDIT = {
  type: "AUTH_USER_CREDIT",
  name: "authUserCredit"
}
export const LOGIN = {
  type: "LOGIN",
  name: "login"
};
export const SIGNUP = {
  type: "SIGNUP",
  name: "signup"
};
export const CONFIRM_EMAIL={
  type: "CONFIRM_EMAIL",
  name: "confirmEmail"
};
export const FORGOT_PASSWORD_LINK={
  type: "FORGOT_PASSWORD_LINK",
  name: "forgotPasswordLink"
};
export const VERIFY_RESET_PASSWORD_TOKEN={
  type: "VERIFY_RESET_PASSWORD_TOKEN",
  name: "verifyPasswordToken"
};
export const RESET_PASSWORD={
  type: "RESET_PASSWORD",
  name: "resetPassword"
};
export const GET_USER_LICENSE_INFO={
  type: "GET_USER_LICENSE_INFO",
  name: "getUserlicenseInfo"
};
export const UPDATE_USER_PROFILE={
  type: "UPDATE_USER_PROFILE",
  name: "updateUserProfile"
};
export const ADD_WEBHOOK={
  type: "ADD_WEBHOOK",
  name: "addWebhook"
};
export const GET_WEBHOOK_LIST={
  type: "GET_WEBHOOK_LIST",
  name: "getWebhookList"
};
export const UPDATE_WEBHOOK={
  type: "UPDATE_WEBHOOK",
  name: "updateWebhook"
};
export const DELETE_WEBHOOK={
  type: "DELETE_WEBHOOK",
  name: "deleteWebhook"
}