import { axios } from "./index";
import { HTTP_METHOD } from "../../constants/common-constant";
import { FILE_UPLOAD } from "../endpoints/common-endpoint";

const { POST } = HTTP_METHOD;

export const uploadFile = async(data) => {
  try{
      const response = await axios[POST](FILE_UPLOAD, data, {
          headers: { 'content-type': 'multipart/form-data' }
      });
      return response;
  }catch(error){
      return error.response
  }
}