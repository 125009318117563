import { TRANSLITERATION, VERSION1 } from "../../constants/common-constant";

export const TRANSLITERATION_DASHBOARD_COUNT = `${TRANSLITERATION}/${VERSION1}/dashboard/count`;
export const TRANSLITERATION_DASHBOARD_EXAMPLE_COUNT_HISTOGRAM = `${TRANSLITERATION}/${VERSION1}/dashboard/examples-per-project-histogram`;
export const TRANSLITERATION_DASHBOARD_API_CALL_GRAPH = `${TRANSLITERATION}/${VERSION1}/dashboard/api-count`;
export const TRANSLITERATION_DASHBOARD_DEPLOYED_MODELS = `${TRANSLITERATION}/${VERSION1}/dashboard/models`;

export const CREATE_PROJECT = `${TRANSLITERATION}/${VERSION1}/project`;
export const GET_PROJECTS = `${TRANSLITERATION}/${VERSION1}/list/projects`;
export const GET_ALL_PROJECTS = `${TRANSLITERATION}/${VERSION1}/all/list/projects`;
export const GET_SINGLE_PROJECT = `${TRANSLITERATION}/${VERSION1}/project`;
export const GET_PROJECT_DETAILS = `${TRANSLITERATION}/${VERSION1}/project/details`;
export const UPDATE_PROJECT = `${TRANSLITERATION}/${VERSION1}/project`;
export const DELETE_PROJECT = `${TRANSLITERATION}/${VERSION1}/project`;

export const CREATE_ONE_EXAMPLE = `${TRANSLITERATION}/${VERSION1}/single/example`;
export const GET_SINGLE_EXAMPLE = `${TRANSLITERATION}/${VERSION1}/example`;
export const DELETE_ONE_EXAMPLE = `${TRANSLITERATION}/${VERSION1}/single/example`;
export const DELETE_EXAMPLE = `${TRANSLITERATION}/${VERSION1}/example`;
export const GET_EXAMPLES = `${TRANSLITERATION}/${VERSION1}/list/example`;
export const UPDATE_EXAMPLE = `${TRANSLITERATION}/${VERSION1}/example`;
export const UPDATE_MULTIPLE_EXAMPLES = `${TRANSLITERATION}/${VERSION1}/multiple/example`;
export const ADD_EXAMPLE_TO_DATASET = `${TRANSLITERATION}/${VERSION1}/add/dataset/example`;
export const DELETE_EXAMPLE_FEEDBACK = `${TRANSLITERATION}/${VERSION1}/feedback/example`;

export const TRAIN_MODEL = `${TRANSLITERATION}/${VERSION1}/model/train/queue`;
export const GET_SINGLE_MODEL = `${TRANSLITERATION}/${VERSION1}/model`;
export const GET_MODELS = `${TRANSLITERATION}/${VERSION1}/list/model`;
export const UPDATE_MODEL = `${TRANSLITERATION}/${VERSION1}/model`;
export const DELETE_MODEL = `${TRANSLITERATION}/${VERSION1}/model`;

export const DEPLOY_MODEL = `${TRANSLITERATION}/${VERSION1}/model/deploy`;
export const PARSE_MODEL = `${TRANSLITERATION}/${VERSION1}/model/parse`;
export const MODEL_FEEDBACK = `${TRANSLITERATION}/${VERSION1}/model/feedback`;

export const UPLOAD_DATASET = `${TRANSLITERATION}/${VERSION1}/upload/bulk/examples`;
export const IMPORT_DATASET = `${TRANSLITERATION}/${VERSION1}/import/bulk/dataset`;
export const DOWNLOAD_FAILED_FILES = `${TRANSLITERATION}/${VERSION1}/download/bulk/failed`;
export const GET_ONE_UPLOAD = `${TRANSLITERATION}/${VERSION1}/upload/one/dataset`;

export const FETCH_NS_DATASETS = `${TRANSLITERATION}/${VERSION1}/datasets`;
export const FETCH_ALL_DATATSET_UPLOADS = `${TRANSLITERATION}/${VERSION1}/all/uploads`;

export const GET_TRANSLITERATIONS = `${TRANSLITERATION}/${VERSION1}/transliterate`;