import { STT, STT_NAME } from "../constants/common-constant";

export const STT_ROUTE = `/platform/${STT}`;
export const STT_DASHBOARD = `/platform/${STT}/dashboard`;
export const FILE_TRANSCRIBER = `/platform/${STT}/file-transcription`;
export const DICTATION = `/platform/${STT}/dictation`;

export const sttAppRoutes = [
  {
    key: STT_DASHBOARD,
    name: STT_NAME,
    path: STT_DASHBOARD,
    icon: "icon icon-apps ns-mr-2 ns-vertical-align-middle",
    i18Message: `sidebar.dashboard`
  }
];
