import { apiMethod } from "./index";
import { HTTP_METHOD } from "../../constants/common-constant";
import { MAIN_DASHBOARD, MAIN_DASHBOARD_API_COUNT, MAIN_DASHOBOARD_GENERIC_STATS, MAIN_DASHOBOARD_APP_SPECIFIC_STATS } from "../endpoints/common-endpoint";

const { GET, POST } = HTTP_METHOD;

export const getMaindashboard = () => apiMethod(GET, MAIN_DASHBOARD);

export const getMaindashboardApiCount = data => apiMethod(POST, MAIN_DASHBOARD_API_COUNT, data);

export const getMainDashboardGenericStats = () => apiMethod(GET, MAIN_DASHOBOARD_GENERIC_STATS);

export const getMainDashboardAppSpecificStats = data => apiMethod(GET, MAIN_DASHOBOARD_APP_SPECIFIC_STATS, {
  params:{
    appType: data.appType
  }
});