
import { AUGMENTATION, AUGMENTATION_NAME } from "../constants/common-constant";

export const AUGMENTATION_ROUTE = `/platform/${AUGMENTATION}`;
export const AUGMENTATION_DASHBOARD = `/platform/${AUGMENTATION}/dashboard`;

export const augAppRoutes = [
  {
    key: AUGMENTATION_DASHBOARD,
    name: AUGMENTATION_NAME,
    path: AUGMENTATION_DASHBOARD,
    icon: "icon icon-apps ns-mr-2 ns-vertical-align-middle",
    i18Message: "sidebar.dashboard"
  }
];