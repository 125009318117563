import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";


import { NLU, TRANSLATION, TRANSLITERATION, NER, AUGMENTATION, LANGUAGE_DETECTION, STT } from "../../../constants/common-constant";
import { appInstallFlag } from "../../../helpers/common-helper";
import * as AuthState from "../../../redux/states/auth-state";
import { applicationRoutes } from "../../../routes/common";
import { NLU_ROUTE } from "../../../routes/nlu";
import { NER_ROUTE } from "../../../routes/ner";
import { LANGUAGE_DETECTION_ROUTE } from "../../../routes/language-detection";
import { AUGMENTATION_ROUTE } from "../../../routes/augmentation";
import { TRANSLATION_ROUTE } from "../../../routes/translation";
import { TRANSLITERATION_ROUTE } from "../../../routes/transliteration";
import { STT_ROUTE } from "../../../routes/stt";
import {
  Dashboard,
  Profile,
  Settings,
  Subscription,
  NluApp,
  NerApp,
  TranslationApp,
  TransliterationApp,
  AugmentationApp,
  LanguageDetectionApp,
  SttApp
} from "../../page-list";
import { ENABLE_PAYMENT } from "../../../utils/ui";

class MainApp extends React.Component {
  render() {
    const { apps } = this.props;
    return (
      <div className="ns-main-content-wrapper">
        <Switch>
          <Route exact path={applicationRoutes.ROOT_ROUTE} component={Dashboard} />
          <Route exact path={applicationRoutes.DASHBOARD_ROUTE} component={Dashboard} />
          <Route exact path={applicationRoutes.PROFILE_ROUTE} component={Profile} />
          <Route exact path={applicationRoutes.SETTINGS_ROUTE} component={Settings} />

          {ENABLE_PAYMENT === "YES" && <Route path={applicationRoutes.SUBSCRIPTION_ROUTE} component={Subscription} />}
          {appInstallFlag(apps, NLU) && <Route path={NLU_ROUTE} component={NluApp} />}
          {appInstallFlag(apps, NER) && <Route path={NER_ROUTE} component={NerApp} />}
          {appInstallFlag(apps, LANGUAGE_DETECTION) && <Route path={LANGUAGE_DETECTION_ROUTE} component={LanguageDetectionApp} />}
          {appInstallFlag(apps, AUGMENTATION) && <Route path={AUGMENTATION_ROUTE} component={AugmentationApp} />}
          {appInstallFlag(apps, TRANSLITERATION) && <Route path={TRANSLITERATION_ROUTE} component={TransliterationApp} />}
          {appInstallFlag(apps, TRANSLATION) && <Route path={TRANSLATION_ROUTE} component={TranslationApp} />}
          {appInstallFlag(apps, STT) && <Route path={STT_ROUTE} component={SttApp} />}
        </Switch>
      </div>
    )
  }
}

MainApp.propTypes = {
  apps: PropTypes.array.isRequired
}

const MainAppContainer = connect(
  state => ({
    apps: state.get(AuthState.reducerName).apps
  }),
  dispatch => ({

  })
)(MainApp)

export default MainAppContainer