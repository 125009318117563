export const reducerName = "nluDatasets";
export const nluDatasetState = {
  loading: false,
  count: 0,
  datasets: [],
  datasetIntents: [],
  datasetEntities: [],
  datasetIntentCounts: [],
  datasetEntityCounts: [],
  datasetDomains: [],
  datasetLoading: false,
  uploadCount: 0,
  uploads: [],
  importCount: 0,
  imports: [],
  importsListLoading: false,
  uploadsListLoading: false,
  oneUpload: {},
  createProjectDatasetLoading: false,
  createProjectDatasetCount: 0,
  createProjectDataset: []
}


export const DATASET_PARAMS_CHANGE = {
  type: "DATASET_PARAMS_CHANGE",
  name: "datasetParamsChange"
};

export const UPLOAD_DATASET = {
  type: "UPLOAD_DATASET",
  name: "uploadDataset"
}

export const IMPORT_DATASET = {
  type: "IMPORT_DATASET",
  name: "importDataset"
}

export const ONE_UPLOAD = {
  type: "ONE_UPLOAD",
  name: "oneUpload"
}

export const DATASET_PARAMS_CHANGE_WITHOUT_ASYNC = {
  type: "DATASET_PARAMS_CHANGE_WITHOUT_ASYNC",
  name: "datasetParamsChangeWithoutAsync"
};

export const FETCH_DATASETS_CREATE_PROJECT = {
  type: "FETCH_DATASETS_CREATE_PROJECT",
  name: "fetchDatasetsCreateProject"
};

export const FETCH_DATASETS = {
  type: "FETCH_DATASETS",
  name: "fetchDatasets"
};

export const FETCH_UPLOAD_LISTS = {
  type: "FETCH_UPLOAD_LISTS",
  name: "fetchUploadLists"
}

export const FETCH_IMPORT_LISTS = {
  type: "FETCH_IMPORT_LISTS",
  name: "fetchImportList"
}