import React from "react";
import { Divider } from "antd";

import { FOOTER_TEXT } from "../../../../utils/ui";
import { PRIVACY_URL, TERMS_URL, SLACK_URL, LINKEDIN_URL, TWITTER_URL, YOUTUBE_URL, MEDIUM_URL } from "../../../../constants/common-constant";

import Youtube from "../../../../assets/images/yt.svg";
import Linkedin from "../../../../assets/images/li.svg";
import Twitter from "../../../../assets/images/tw.svg";
import Medium from "../../../../assets/images/me.svg";

class Footer extends React.Component {
  render() {
    return (
      <div className="ns-layout-footer-content">
        {FOOTER_TEXT}
        <Divider type="vertical" />
        <a className="ns-link-underline" href={PRIVACY_URL} target="_blank" rel="noreferrer">Privacy Policy</a>
        <Divider type="vertical" />
        <a className="ns-link-underline" href={TERMS_URL} target="_blank" rel="noreferrer">Terms</a>
        <Divider type="vertical" />
        <a className="ns-link-underline" href={SLACK_URL} target="_blank" rel="noreferrer">Need Help? Slack us</a>
        <Divider type="vertical" />
        <a className="ns-p-1" href={LINKEDIN_URL} target="_blank" rel="noreferrer"><img className="ns-footer-social-icons" src={Linkedin} alt="linkedin icon" /></a>
					<a className="ns-p-1" href={MEDIUM_URL} target="_blank" rel="noreferrer"><img className="ns-footer-social-icons" src={Medium} alt="medium icon" /></a>
					<a className="ns-p-1" href={YOUTUBE_URL} target="_blank" rel="noreferrer"><img className="ns-footer-social-icons" src={Youtube} alt="youtube icon" /></a>
					<a className="ns-p-1" href={TWITTER_URL} target="_blank" rel="noreferrer"><img className="ns-footer-social-icons" src={Twitter} alt="twitter icon" /></a>
        <Divider type="vertical" />
      </div>
    )
  }
}

export default Footer;