import toastr from "toastr";
import _ from "lodash";

import { authState, reducerName, AUTH_LOADING, CHECK_AUTH, LOGIN, SIGNUP, CONFIRM_EMAIL, FORGOT_PASSWORD_LINK, RESET_PASSWORD,
VERIFY_RESET_PASSWORD_TOKEN, UPDATE_USER_PROFILE, GET_USER_LICENSE_INFO, AUTH_PARAMS_CHANGE, AUTH_USER_CREDIT,
ADD_WEBHOOK, GET_WEBHOOK_LIST, UPDATE_WEBHOOK, DELETE_WEBHOOK
} from "../states/auth-state";
import * as AuthApi from "../apis/auth-api";
import ReducerFactory from "../../utils/reducer-factory";
import { setAccessToken, validArray } from "../../helpers/common-helper";

const initialState = {...authState};
const reducerFactory = new ReducerFactory(reducerName, initialState);

reducerFactory.addAction(AUTH_LOADING.type, AUTH_LOADING.name,
  status => status, (state,action)=>{
		const newState = _.cloneDeep(state);
    newState.loading = action.data;
    return newState;
  }
);

reducerFactory.addAction(AUTH_PARAMS_CHANGE.type, AUTH_PARAMS_CHANGE.name,
  async data=> data,(state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.length > 0){
      action.data.forEach(d=>{
        const { key, value } = d;
        newState[key] = value;
      });
    }  
    return newState;
  } 
);

reducerFactory.addAction(CHECK_AUTH.type, CHECK_AUTH.name,
	async()=>{
		reducerFactory.action(AUTH_LOADING.name, true);
		const response = await AuthApi.checkAuth();
		return response.data ? response.data : undefined;
	}, (state,action)=>{
		const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      setAccessToken(action.data.data.auth);
      newState.user = action.data.data.user;
      newState.apps = action.data.data.apps;
    }
    newState.loading = false;
    return newState;
	}
);

reducerFactory.addAction(AUTH_USER_CREDIT.type, AUTH_USER_CREDIT.name,
	async()=>{
		const response = await AuthApi.fetchUserCredit();
		return response.data ? response.data : null;
	}, (state,action)=>{
		const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.credit = action.data.data.credit;
      newState.currentUsage = action.data.data.currentUsage || 0;
    }
    newState.loading = false;
    return newState;
	}
);

reducerFactory.addAction(LOGIN.type, LOGIN.name,
	async(data)=>{
    reducerFactory.action(AUTH_LOADING.name, true);
    const response = await AuthApi.login(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      setAccessToken(action.data.data.auth);
      newState.user = action.data.data.user;
      newState.apps = action.data.data.apps;
    }else{
      toastr.error(action.data.message)
    }
    newState.loading = false;
    return newState;
  }	
);

reducerFactory.addAction(SIGNUP.type, SIGNUP.name,
  async(data)=>{
    reducerFactory.action(AUTH_LOADING.name, true);
    const response = await AuthApi.signup(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data){
      toastr[action.data.success ? "success" : "error"](action.data.message);
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction(CONFIRM_EMAIL.type, CONFIRM_EMAIL.name,
  async(data)=>{
    reducerFactory.action(AUTH_LOADING.name, true);
    const response = await AuthApi.confirmEmail(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data){
      newState.confirmEmailText = action.data.message;
      newState.confirmEmailStatus = action.data.success;
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction(VERIFY_RESET_PASSWORD_TOKEN.type, VERIFY_RESET_PASSWORD_TOKEN.name,
  async(data)=>{
    reducerFactory.action(AUTH_LOADING.name, true);
    const response = await AuthApi.verifyPasswordToken(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && !action.data.success){
      newState.resetPasswordTokenText = action.data.message;
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction(FORGOT_PASSWORD_LINK.type, FORGOT_PASSWORD_LINK.name,
  async(data)=>{
    reducerFactory.action(AUTH_LOADING.name, true);
    const response = await AuthApi.forgotPasswordLink(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data){
      toastr[action.data.success ? "success" : "error"](action.data.message);
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction(RESET_PASSWORD.type, RESET_PASSWORD.name,
  async(data)=>{
    reducerFactory.action(AUTH_LOADING.name, true);
    const response = await AuthApi.resetPassword(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data){
      toastr[action.data.success ? "success" : "error"](action.data.message);
      newState.resetPasswordText = action.data.message;
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction(GET_USER_LICENSE_INFO.type, GET_USER_LICENSE_INFO.name,
  async()=>{
    reducerFactory.action(AUTH_LOADING.name, true);
    const response = await AuthApi.getUserLicenses();
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.licenseInfo = action.data.data;
    }
    newState.loading = false;
    return newState;
  }
);


reducerFactory.addAction(UPDATE_USER_PROFILE.type, UPDATE_USER_PROFILE.name,
  async(data)=>{
    reducerFactory.action(AUTH_PARAMS_CHANGE.name, [{key: "profileLoading", value: true}]);
    const response = await AuthApi.updateUserProfile(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data){
      // toastr[action.data.success ? "success" : "error"](action.data.message);
      if(action.data.success){
        newState.user = action.data.data;
      }
    }
    newState.profileLoading = false;
    return newState;
  }
);

reducerFactory.addAction(ADD_WEBHOOK.type, ADD_WEBHOOK.name,
  async(data)=>{
    reducerFactory.action(AUTH_PARAMS_CHANGE.name, [{key: "webhookLoading", value: true}]);
    const response = await AuthApi.addWebhook(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data){
      toastr[action.data.success ? "success" : "error"](action.data.message);
    }
    newState.webhookLoading = false;
    return newState;
  }
);

reducerFactory.addAction(GET_WEBHOOK_LIST.type, GET_WEBHOOK_LIST.name,
  async(data)=>{
    reducerFactory.action(AUTH_PARAMS_CHANGE.name, [{key: "webhookLoading", value: true}]);
    const response = await AuthApi.fetchWebhooks(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && validArray(action.data.data)){
      newState.webhooks = action.data.data;
    }else{
      newState.webhooks = []
    }
    newState.webhookLoading = false;
    return newState;
  }
);

reducerFactory.addAction(UPDATE_WEBHOOK.type, UPDATE_WEBHOOK.name,
  async(data)=>{
    reducerFactory.action(AUTH_PARAMS_CHANGE.name, [{key: "webhookLoading", value: true}]);
    const response = await AuthApi.updateWebhooks(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data){
      toastr[action.data.success ? "success" : "error"](action.data.message);
    }
    newState.webhookLoading = false;
    return newState;
  }
);

reducerFactory.addAction(DELETE_WEBHOOK.type, DELETE_WEBHOOK.name,
  async(data)=>{
    reducerFactory.action(AUTH_PARAMS_CHANGE.name, [{key: "webhookLoading", value: true}]);
    const response = await AuthApi.deleteWebhook(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data){
      toastr[action.data.success ? "warning" : "error"](action.data.message);
    }
    newState.webhookLoading = false;
    return newState;
  }
);

export default reducerFactory;