import React from "react";
import PropTypes from "prop-types";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

class SectionLoading extends React.Component{
  render(){
    const { size, opacity, loadingText } = this.props;
    return(
      <div className="ns-overlay-section" style={{opacity: opacity?opacity:1}}>
        <Spin 
          indicator={<LoadingOutlined style={{ fontSize: size }} spin />}
          className="ns-spin-loading"
        />
        {loadingText && <div style={{textAlign:"center", padding: "7px"}}>{loadingText}</div>}
        
      </div>
    )
  }
}

SectionLoading.propTypes={
  size: PropTypes.number.isRequired
}

export default SectionLoading