import React from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { Layout } from "antd";

import {
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_DRAWER,
  TAB_SIZE,
  THEME_TYPE_DARK
} from "../../constants/theme-setting-constants";
import { BlockUser } from "../page-list";
import MainApp from "./layout";
import Sidebar from "./layout/sidebar";
import HeaderBar from "./layout/header";
import * as SettingsState from "../../redux/states/settings-state";
import * as AuthState from "../../redux/states/auth-state";
import SettingReducer from "../../redux/modules/settings-module";
import AuthReducer from "../../redux/modules/auth-module";
import Footer from "./layout/footer";

let styleSheetLink = document.createElement('link');
styleSheetLink.type = 'text/css';
styleSheetLink.rel = 'stylesheet';
document.body.appendChild(styleSheetLink);

class Application extends React.Component {
  constructor(props) {
    super(props);
    window.addEventListener("resize", this.onWindowWidthChange);
  }

  componentDidMount() {
    const { authReducer } = this.props;
    authReducer[AuthState.CHECK_AUTH.name]();
    authReducer[AuthState.AUTH_USER_CREDIT.name]();
    this.onUpdateTheme();
  }

  componentDidUpdate(prevProps) {
    if (this.props.themeType !== prevProps.themeType) {
      this.onUpdateTheme();
    }
    if(this.props.location !== prevProps.location){
      ReactGA.pageview(window.location.hash);
    }
  }

  onUpdateTheme = () => {
    const { themeType } = this.props;
    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add('dark-theme');
      styleSheetLink.href = "/css/dark_theme.css";
    } else if (document.body.classList.contains('dark-theme')) {
      document.body.classList.remove('dark-theme');
      styleSheetLink.href = "";
    }
  }

  onWindowWidthChange = () => {
    const { settingsReducer } = this.props;
    settingsReducer[SettingsState.SET_WINDOW_WIDTH.name](window.innerWidth);
  }

  render() {
    const { windowWidth, navStyle, user } = this.props;
    const SIDEBAR_VISIBLE_ON = [NAV_STYLE_FIXED, NAV_STYLE_DRAWER, NAV_STYLE_MINI_SIDEBAR];
    const userStatus = localStorage.getItem("status");
    return (
      <>
        {user && user.blockStatus && (
          <div className="ns-payment-warning-div">
            {user.blockStatus.message}
          </div>
        )}
        {userStatus === "block" ? (
          <BlockUser {...this.props} />
        ) : (
          <Layout className="ns-app-layout">
            {(windowWidth < TAB_SIZE || SIDEBAR_VISIBLE_ON.includes(navStyle)) && <Sidebar />}
            <Layout>
              <HeaderBar {...this.props} />
              <Layout.Content className={`ns-layout-content`}>
                <MainApp {...this.props} />
              </Layout.Content>
              <Layout.Footer>
                <Footer />
              </Layout.Footer>
            </Layout>
          </Layout>
        )}
      </>
    )
  }
}

Application.propTypes = {
  windowWidth: PropTypes.number.isRequired,
  navStyle: PropTypes.string.isRequired,
  settingsReducer: PropTypes.object.isRequired,
  themeType: PropTypes.string.isRequired,
  authReducer: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

const ApplicationConatiner = connect(
  state => ({
    windowWidth: state.get(SettingsState.reducerName).windowWidth,
    navStyle: state.get(SettingsState.reducerName).navStyle,
    themeType: state.get(SettingsState.reducerName).themeType,
    user: state.get(AuthState.reducerName).user
  }),
  dispatch => ({
    settingsReducer: SettingReducer.getActions(dispatch),
    authReducer: AuthReducer.getActions(dispatch)
  })
)(Application)

export default ApplicationConatiner;