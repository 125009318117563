
import { LANGUAGE_DETECTION, LANGUAGE_DETECTION_NAME } from "../constants/common-constant";

export const LANGUAGE_DETECTION_ROUTE = `/platform/${LANGUAGE_DETECTION}`;
export const LANGUAGE_DETECTION_DASHBOARD = `/platform/${LANGUAGE_DETECTION}/dashboard`;

export const ldAppRoutes = [
  {
    key: LANGUAGE_DETECTION_DASHBOARD,
    name: LANGUAGE_DETECTION_NAME,
    path: LANGUAGE_DETECTION_DASHBOARD,
    icon: "icon icon-apps ns-mr-2 ns-vertical-align-middle",
    i18Message: "sidebar.dashboard"
  }
];
