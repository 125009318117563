export const reducerName = "transcriptionReducer";
export const transcriptionFileState = {
  loading: false,
  isFetchingList: false,
  uploadedFileDataList:[],
  totalFilesUploaded: 0,
  totalFileSize:0,
  error: null,
  blobUrlMap: {}
}

export const TRANSCRIBE_FILE = {
  type: "TRANSCRIBE_FILE",
  name: "transcribeFile"
}

export const GET_TRANSCRIPTION_LIST = {
  type: "GET_TRANSCRIPTION_LIST",
  name: "getTranscriptionList"
}

export const DELETE_TRANSCRIPTION_FILE={
  type: "DELETE_TRANSCRIPTION_FILE",
  name: "deleteTranscriptionFile"
}


export const UPLOAD_FILE_FOR_TRANSCRIPTION={
  type: "TRANSCRIPTION_UPLOAD_FILE_FOR_TRANSCRIPTION",
  name: "transcriptionUploadFileForTranscription"
}

export const GET_BLOB_URL={
  type: "GET_BLOB_URL",
  name: "getBlobUrl"
}

export const IS_LOADING={
  type: "IS_LOADING",
  name: "isLoading"
}

export const IS_FETCHING_LIST={
  type: "IS_FETCHING_LIST",
  name: "isFetchingList"
}


