import toastr from "toastr";
import _ from "lodash";

import { usageState, reducerName, INSTALL_APP, USAGE_LOADING } from "../states/usage-state";
import * as UsageApi from "../apis/usage-api";
import ReducerFactory from "../../utils/reducer-factory";


const initialState = {...usageState};
const reducerFactory = new ReducerFactory(reducerName, initialState);

reducerFactory.addAction(USAGE_LOADING.type, USAGE_LOADING.name,
  status => status, (state,action)=>{
		const newState = _.cloneDeep(state);
    newState.loading = action.data;
    return newState;
  }
);

reducerFactory.addAction(INSTALL_APP.type, INSTALL_APP.name,
	async(data)=>{
    reducerFactory.action(USAGE_LOADING.name, true);
    const response = await UsageApi.installApp(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      toastr.success(action.data.message);
    }
    newState.loading = false;
    return newState;
  }	
);

export default reducerFactory;