import { apiMethod } from "../index";
import { HTTP_METHOD } from "../../../constants/common-constant";
import { CREATE_INTENT, GET_SINGLE_INTENT, DELETE_INTENT, GET_INTENTS, FETCH_ALL_INTENTS } from "../../endpoints/nlu-endpoint";

const { GET, POST, DELETE } = HTTP_METHOD;

export const createIntent = data => apiMethod(POST, CREATE_INTENT, data);

export const fetchIntents = data => apiMethod(POST, GET_INTENTS, data);

export const fetchAllIntents = data => apiMethod(POST, FETCH_ALL_INTENTS, data);

export const fetchOneIntent = data => apiMethod(GET, GET_SINGLE_INTENT, {
  params:{
    intentId: data.intentId
  }
});

export const deleteIntent = data => apiMethod(DELETE, DELETE_INTENT, {data});