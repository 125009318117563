import { apiMethod } from "../index";
import { HTTP_METHOD } from "../../../constants/common-constant";
import { TRAIN_MODEL, GET_SINGLE_MODEL, GET_MODELS, UPDATE_MODEL, DELETE_MODEL, PARSE_MODEL, DEPLOY_MODEL, MODEL_FEEDBACK } from "../../endpoints/transliteration-endpoint";

const { GET, POST, PUT, DELETE } = HTTP_METHOD;

export const trainModel = data => apiMethod(POST, TRAIN_MODEL, data);

export const fetchModels = data => apiMethod(POST, GET_MODELS, data);

export const fetchOneModel = data => apiMethod(GET, GET_SINGLE_MODEL, {
  params:{
    modelId: data.modelId
  }
});

export const updateModel = data => apiMethod(PUT, UPDATE_MODEL, data);

export const parseModel = data => apiMethod(POST, PARSE_MODEL, data);

export const getFeedbackData = data => apiMethod(POST, MODEL_FEEDBACK, data);

export const deployModel = data => apiMethod(POST, DEPLOY_MODEL, data);

export const deleteModel = data => apiMethod(DELETE, DELETE_MODEL, {data});