import _ from "lodash";

import { nluDashboardState, reducerName, NLU_DASHBOARD_PARAMS_CHANGE, GET_NLU_DASHBOARD, GET_NLU_DASHBOARD_API_COUNT,
  GET_NLU_DASHBOARD_DEPLOYED_MODELS,
  GET_NLU_DASHBOARD_STATS,
  GET_NLU_DASHBOARD_EXAMPLES_PER_DATASET_HISTOGRAM,
  NLU_DASHBOARD_DATA_CHANGE
} from "../../states/nlu/nlu-dashboard-state";
import * as NluDashboardApi from "../../apis/nlu/nlu-dashboard-api";
import ReducerFactory from "../../../utils/reducer-factory";


const initialState = {...nluDashboardState};
const reducerFactory = new ReducerFactory(reducerName, initialState);

reducerFactory.addAction(NLU_DASHBOARD_PARAMS_CHANGE.type, NLU_DASHBOARD_PARAMS_CHANGE.name,
  async data=> data,(state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.length > 0){
      action.data.forEach(d=>{
        const { key, value } = d;
        newState[key] = value;
      });
    }  
    return newState;
  } 
);

reducerFactory.addAction(NLU_DASHBOARD_DATA_CHANGE.type, NLU_DASHBOARD_DATA_CHANGE.name,
  data=> data,(state,action)=>{
    const newState = _.cloneDeep(state);
    const { key, value } = action.data;
    newState[key] = value;
    return newState;
  } 
);

reducerFactory.addAction(GET_NLU_DASHBOARD.type, GET_NLU_DASHBOARD.name,
  async ()=>{
    reducerFactory.action(NLU_DASHBOARD_PARAMS_CHANGE.name, [{key: "nluDashboardCountsLoading", value: true}]);
    const response = await NluDashboardApi.getNluDashboard();
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.nluCountData = action.data.data.counts;
      newState.nluAppUsage = action.data.data.appUsage;
    }
    newState.nluDashboardCountsLoading = false;
    return newState;
  }
);

reducerFactory.addAction(GET_NLU_DASHBOARD_API_COUNT.type, GET_NLU_DASHBOARD_API_COUNT.name,
  async (data)=>{
    reducerFactory.action(NLU_DASHBOARD_PARAMS_CHANGE.name, [{key: "nluDashboardGarphLoading", value: true}]);
    const response = await NluDashboardApi.getNluDashboardApiCount(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.count = action.data.data.count;
      newState.apiCalls = action.data.data.apiCalls;
    }
    newState.nluDashboardGarphLoading = false;
    return newState;
  }
);

reducerFactory.addAction(GET_NLU_DASHBOARD_DEPLOYED_MODELS.type, GET_NLU_DASHBOARD_DEPLOYED_MODELS.name,
  async ()=>{
    reducerFactory.action(NLU_DASHBOARD_PARAMS_CHANGE.name, [{key: "nluDashboardDeployedModelsLoading", value: true}]);
    const response = await NluDashboardApi.getNluDeployedModels();
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.deployedModels = action.data.data;
    }
    newState.nluDashboardDeployedModelsLoading = false;
    return newState;
  }
);

reducerFactory.addAction(GET_NLU_DASHBOARD_STATS.type, GET_NLU_DASHBOARD_STATS.name,
  async (data)=>{
    reducerFactory.action(NLU_DASHBOARD_PARAMS_CHANGE.name, [{key: "nluStatsLoading", value: true}]);
    const response = await NluDashboardApi.getNluDashboardStats(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.nluStats = action.data.data || [];
    }
    newState.nluStatsLoading = false;
    return newState;
  }
);

reducerFactory.addAction(GET_NLU_DASHBOARD_EXAMPLES_PER_DATASET_HISTOGRAM.type, GET_NLU_DASHBOARD_EXAMPLES_PER_DATASET_HISTOGRAM.name,
  async ()=>{
    reducerFactory.action(NLU_DASHBOARD_PARAMS_CHANGE.name, [{key: "nluExamplesPerProjectLoading", value: true}]);
    const response = await NluDashboardApi.getExamplesPerProjectHistogram();
    return response.data;
  }, (state, action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.examplesPerProjectHistogram = action.data.data || [];
    }
    newState.nluExamplesPerProjectLoading = false;
    return newState;
  }
);

export default reducerFactory;