import { apiMethod } from "../index";
import { HTTP_METHOD } from "../../../constants/common-constant";
import { NER_DASHBOARD_COUNT, NER_DASHBOARD_API_CALL_GRAPH, NER_DASHBOARD_DEPLOYED_MODELS, NER_DASHBOARD_EXAMPLE_COUNT_HISTOGRAM } from "../../endpoints/ner-endpoint";
import { MAIN_DASHOBOARD_APP_SPECIFIC_STATS } from "../../endpoints/common-endpoint";

const { GET, POST } = HTTP_METHOD;

export const getNerDashboard = () => apiMethod(GET, NER_DASHBOARD_COUNT);

export const getNerDashboardApiCount = data => apiMethod(POST, NER_DASHBOARD_API_CALL_GRAPH, data);

export const getNerDeployedModels = () => apiMethod(GET, NER_DASHBOARD_DEPLOYED_MODELS);

export const getNerDashboardStats = data => apiMethod(GET, MAIN_DASHOBOARD_APP_SPECIFIC_STATS, {
  params:{
    appType: data.appType
  }
});

export const getExamplesPerProjectHistogram = () => apiMethod(GET, NER_DASHBOARD_EXAMPLE_COUNT_HISTOGRAM);