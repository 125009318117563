import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  Signin,
  SignUp,
  EmailVerfication,
  EmailConfirmation,
  ForgotPassword,
  ResetPassword
} from '../page-list';
import { authRoutes } from "../../routes/common";

class Auth extends React.Component{
  render(){
    return(
      <Switch>
        <Route exact path={authRoutes.LOGIN_ROUTE} component={Signin} />
        <Route exact path={authRoutes.ROOT_ROUTE} component={Signin} />
        <Route exact path={authRoutes.SIGNUP_ROUTE} component={SignUp} />
        <Route exact path={authRoutes.VERIFY_EMAIL_ROUTE} component={EmailVerfication} />
        <Route exact path={authRoutes.CONFIRM_EMAIL_ROUTE} component={EmailConfirmation} />
        <Route exact path={authRoutes.FORGOT_PASSWORD_ROUTE} component={ForgotPassword} />
        <Route exact path={authRoutes.RESET_PASSWORD_ROUTES} component={ResetPassword} />
        <Redirect to={authRoutes.ROOT_ROUTE} />
      </Switch>
    )
  }
}

export default Auth;