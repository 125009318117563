import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Translate from './translate';

class TranslationIndex extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path={"/translate"} component={Translate} />
        <Redirect to={"/translate"} />
      </Switch>
    )
  }
}

export default TranslationIndex;