import toastr from "toastr";
import _ from "lodash";

import { nluIntentState, reducerName, INTENT_LOADING, INTENT_PARAMS_CHANGE, CREATE_INTENT, FETCH_INTENTS,
  SINGLE_INTENT, DELETE_INTENT, SEARCH_INTENTS_IN_EXAMPLE
} from "../../states/nlu/nlu-intent-state";
import * as NluIntentApi from "../../apis/nlu/nlu-intent-api";
import ReducerFactory from "../../../utils/reducer-factory";

const initialState = {...nluIntentState};
const reducerFactory = new ReducerFactory(reducerName, initialState);

reducerFactory.addAction(INTENT_LOADING.type, INTENT_LOADING.name,
  status => status, (state,action)=>{
		const newState = _.cloneDeep(state);
    newState.loading = action.data;
    return newState;
  }
);

reducerFactory.addAction(INTENT_PARAMS_CHANGE.type, INTENT_PARAMS_CHANGE.name,
  async data=> data,(state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.length > 0){
      action.data.forEach(d=>{
        const { key, value } = d;
        newState[key] = value;
      })
    }  
    return newState;
  } 
);

reducerFactory.addAction(CREATE_INTENT.type, CREATE_INTENT.name,
	async(data)=>{
    reducerFactory.action(INTENT_LOADING.name, true);
    const response = await NluIntentApi.createIntent(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data){
      if(action.data.success){
        const { intent, intentId } = action.data.data;
        newState.intents.unshift(action.data.data);
        const newIntentLists = newState.intents.splice(0,newState.pageSize);
        newState.intents = newIntentLists;
        newState.count++;
        newState.searchedIntents.push({intent, intentId});
      }else{
        toastr.warning(action.data.message)
      }
    }
    newState.loading = false;
    return newState;
  }	
);

reducerFactory.addAction(FETCH_INTENTS.type, FETCH_INTENTS.name,
	async(data)=>{
    reducerFactory.action(INTENT_LOADING.name, true);
    const response = await NluIntentApi.fetchIntents(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.intents = action.data.data.intents;
      newState.count = action.data.data.count;
    }
    newState.loading = false;
    return newState;
  }	
);


reducerFactory.addAction(SEARCH_INTENTS_IN_EXAMPLE.type, SEARCH_INTENTS_IN_EXAMPLE.name,
	async(data)=>{
    const response = await NluIntentApi.fetchAllIntents(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.searchedIntents = action.data.data
    }
    return newState;
  }	
);

reducerFactory.addAction(SINGLE_INTENT.type, SINGLE_INTENT.name,
	async(data)=>{
    // reducerFactory.action(INTENT_LOADING.name, true);
    const response = await NluIntentApi.fetchOneIntent(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.oneIntent = action.data.data;
    }
    newState.loading = false;
    return newState;
  }	
);

reducerFactory.addAction(DELETE_INTENT.type, DELETE_INTENT.name,
	async(data)=>{
    // reducerFactory.action(INTENT_LOADING.name, true);
    const response = await NluIntentApi.deleteIntent(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data){
      toastr[action.data.success ? "warning" : "error"](action.data.message);
    }
    newState.loading = false;
    return newState;
  }	
);

export default reducerFactory;