export const reducerName = "transliterationDatasets";
export const transliterationDatasetState = {
  loading: false,
  count: 0,
  datasets: [],
  datasetIntents: [],
  datasetEntities: [],
  datasetIntentCounts: [],
  datasetEntityCounts: [],
  datasetDomains: [],
  datasetLoading: false,
  uploadCount: 0,
  uploads: [],
  importCount: 0,
  imports: [],
  importsListLoading: false,
  uploadsListLoading: false,
  oneUpload: {},
  createProjectDatasetLoading: false,
  createProjectDataset: [],
  createProjectDatasetCount: 0
}


export const DATASET_PARAMS_CHANGE = {
  type: "TRANSLITERATION_DATASET_PARAMS_CHANGE",
  name: "transliterationdatasetParamsChange"
};

export const UPLOAD_DATASET = {
  type: "TRANSLITERATION_UPLOAD_DATASET",
  name: "transliterationuploadDataset"
}

export const IMPORT_DATASET = {
  type: "TRANSLITERATION_IMPORT_DATASET",
  name: "transliterationimportDataset"
}

export const ONE_UPLOAD = {
  type: "TRANSLITERATION_ONE_UPLOAD",
  name: "transliterationoneUpload"
}

export const DATASET_PARAMS_CHANGE_WITHOUT_ASYNC = {
  type: "TRANSLITERATION_DATASET_PARAMS_CHANGE_WITHOUT_ASYNC",
  name: "transliterationdatasetParamsChangeWithoutAsync"
};

export const FETCH_DATASETS_CREATE_PROJECT = {
  type: "TRANSLITERATION_FETCH_DATASETS_CREATE_PROJECT",
  name: "transliterationfetchDatasetsCreateProject"
};

export const FETCH_DATASETS = {
  type: "TRANSLITERATION_FETCH_DATASETS",
  name: "transliterationfetchDatasets"
};

export const FETCH_UPLOAD_LISTS = {
  type: "TRANSLITERATION_FETCH_UPLOAD_LISTS",
  name: "transliterationfetchUploadLists"
}

export const FETCH_IMPORT_LISTS = {
  type: "TRANSLITERATION_FETCH_IMPORT_LISTS",
  name: "transliterationfetchImportList"
}