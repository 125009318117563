export const reducerName = "nerProject";
export const nerProjectState = {
  loading: false,
  singleProjectLoading: false,
  oneProject: {},
  oneProjectId: "",
  pageNumber: 1,
  pageSize: 15,
  search: "",
  sortBy:{
    key: "createdAt",
    value: "asc"
  },
  sortByKeys:{
    createdAt: "asc",
    noOfExamples: "asc",
    noOfModels: "asc"
  },
  languages: [],
  selectedLanguage: "",
  projects: [],
  count: 0,
  allProjects: [],

  //Project synonyms
  projectSynonymLoading: false,
  projectSynonyms: [],
  projectSynonymCount: 0,
  error: null
}

export const PROJECT_LOADING = {
  type: "NER_PROJECT_LOADING",
  name: "nerprojectLoading"
};

export const PROJECT_PARAMS_CHANGE = {
  type: "NER_PROJECT_PARAMS_CHANGE",
  name: "nerprojectParamsChange"
}

export const PROJECT_PARAMS_CHANGE_WITHOUT_ASYNC = {
  type: "NER_PROJECT_PARAMS_CHANGE_WITHOUT_ASYNC",
  name: "nerprojectParamsChangeWithoutAsync"
}

export const SORT_PROJECTS = {
  type: "NER_SORT_PROJECTS",
  name: "nersortProjects"
}

export const CREATE_PROJECT = {
  type: "NER_CREATE_PROJECT",
  name: "nercreateProject"
};

export const FETCH_PROJECTS = {
  type: "NER_FETCH_PROJECTS",
  name: "nerfetchProjects"
};

export const FETCH_ALL_PROJECTS = {
  type: "NER_FETCH_ALL_PROJECTS",
  name: "nerfetchAllProjects"
}

export const SINGLE_PROJECT = {
  type: "NER_SINGLE_PROJECT",
  name: "nersingleProject"
};

export const UPDATE_PROJECT = {
  type: "NER_UPDATE_PROJECT",
  name: "nerupdateProject"
};

export const DELETE_PROJECT = {
  type: "NER_DELETE_PROJECT",
  name: "nerdeleteProject"
};

export const ADD_SYNONYM_TO_PROJECT = {
  type: "NER_ADD_SYNONYM_TO_PROJECT",
  name: "neraddSynonymToProject"
};

export const FETCH_PROJECT_SYNONYMS = {
  type: "NER_FETCH_PROJECT_SYNONYMS",
  name: "nerfetchProjectSynonyms"
};

export const REMOVE_SYNONYM_FROM_PROJECT = {
  type: "NER_REMOVE_SYNONYM_FROM_PROJECT",
  name: "nerremoveSynonymFromProject"
};
