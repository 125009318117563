import React from "react";
import ReactDOM from "react-dom";
import toastr from "toastr";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router/immutable";
import History from "./utils/history";
import "toastr/build/toastr.css";
import configureStore from "./redux/store";
import App from "./app";

toastr.options = {
  positionClass: "toast-top-center"
}
toastr.options.showMethod = "slideDown";
toastr.options.hideMethod = "slideUp";
toastr.options.progressBar = true;
toastr.options.closeButton = true;
toastr.options.preventDuplicates = true;
toastr.options.closeDuration = 500;

const initialState = {};
const store = configureStore(initialState, History);
const MOUNT_NODE = document.getElementById("root");

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={History}>
        <App history={History}/>
      </ConnectedRouter>
    </Provider>,
    MOUNT_NODE
  )
}

render();