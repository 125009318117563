import toastr from "toastr";
import _ from "lodash";

import {
  nerModelState, reducerName, MODEL_LOADING, MODEL_PARAMS_CHANGE, TRAIN_MODEL, UPDATE_MODEL,
  FETCH_MODELS, SINGLE_MODEL, DELETE_MODEL, DEPLOY_MODEL, PARSE_MODEL, GET_FEEDBACK_DATA, MODEL_ACTION_PARAMS_CHANGE,
  MODEL_DATA_CHANGE, GET_MODEL_ANALYTICS
} from "../../states/ner/ner-model-state";
import * as NerModelApi from "../../apis/ner/ner-model-api";
import ReducerFactory from "../../../utils/reducer-factory";
import { getConvertExampleData } from "../../../helpers/ner/ner-project-helper";

const initialState = { ...nerModelState };
const reducerFactory = new ReducerFactory(reducerName, initialState);

reducerFactory.addAction(MODEL_LOADING.type, MODEL_LOADING.name,
  status => status, (state, action) => {
    const newState = _.cloneDeep(state);
    newState.loading = action.data;
    return newState;
  }
);

reducerFactory.addAction(MODEL_PARAMS_CHANGE.type, MODEL_PARAMS_CHANGE.name,
  async data => data, (state, action) => {
    const newState = _.cloneDeep(state);
    if (action && action.data && action.data.length > 0) {
      action.data.forEach(d => {
        const { key, value } = d;
        newState[key] = value;
      });
    }
    return newState;
  }
);

reducerFactory.addAction(MODEL_DATA_CHANGE.type, MODEL_DATA_CHANGE.name,
  data => data, (state, action) => {
    const newState = _.cloneDeep(state);
    const { key, value } = action.data;
    newState[key] = value;
    return newState;
  }
);

reducerFactory.addAction(MODEL_ACTION_PARAMS_CHANGE.type, MODEL_ACTION_PARAMS_CHANGE.name,
  data => data, (state,action) =>{
    const newState = _.cloneDeep(state);
    if(action && action.data){
      const index = newState[action.data.actionType].findIndex(i=>i === action.data.modelId);
      if(index === -1){
        newState[action.data.actionType].push(action.data.modelId);
      }else{
        newState[action.data.actionType].splice(index, 1);
      }
    }
    return newState;
  }  
)

reducerFactory.addAction(TRAIN_MODEL.type, TRAIN_MODEL.name,
  async (data) => {
    reducerFactory.action(MODEL_LOADING.name, true);
    const response = await NerModelApi.trainModel(data);
    return response.data;
  }, (state, action) => {
    const newState = _.cloneDeep(state);
    if (action && action.data) {
      toastr[action.data.success ? "success" : "error"](action.data.message);
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction(FETCH_MODELS.type, FETCH_MODELS.name,
  async (data) => {
    if(data.loadFlag)
      reducerFactory.action(MODEL_PARAMS_CHANGE.name, [{key:"modelListLoading", value: true}]);
    delete data.loadFlag;
    const response = await NerModelApi.fetchModels(data);
    return response.data;
  }, (state, action) => {
    const newState = _.cloneDeep(state);
    if (action && action.data && action.data.success) {
      newState.models = action.data.data.models;
      newState.count = action.data.data.count;
    }
    newState.modelListLoading = false;
    return newState;
  }
);

reducerFactory.addAction(SINGLE_MODEL.type, SINGLE_MODEL.name,
  async (data) => {
    reducerFactory.action(MODEL_DATA_CHANGE.name, {key: "singleModelLoading", value: true })
    const response = await NerModelApi.fetchOneModel(data);
    return response.data;
  }, (state, action) => {
    const newState = _.cloneDeep(state);
    if (action && action.data && action.data.success) {
      newState.lastModel = action.data.data;
    }
    newState.singleModelLoading = false;
    return newState;
  }
);

reducerFactory.addAction(UPDATE_MODEL.type, UPDATE_MODEL.name,
  async (data) => {
    const response = await NerModelApi.updateModel(data);
    return response.data;
  }, (state, action) => {
    const newState = _.cloneDeep(state);
    if (action && action.data && action.data.success) {
      const { modelId, modelName } = action.data.data;
      const findModelIndex = newState.models.findIndex(m=>m.modelId === modelId);
      if(findModelIndex > -1){
        newState.models[findModelIndex]["modelName"] = modelName;
      }
      if(newState.lastModel.modelId === modelId){
        newState.lastModel.modelName = modelName;
      }
    }
    return newState;
  }
);

reducerFactory.addAction(DELETE_MODEL.type, DELETE_MODEL.name,
  async (data) => {
    reducerFactory.action(MODEL_ACTION_PARAMS_CHANGE.name, {actionType: "deleteModelId", modelId: data.modelId});
    const response = await NerModelApi.deleteModel(data);
    return response.data;
  }, (state, action) => {
    const newState = _.cloneDeep(state);
    if (action && action.data) {
      if(action.data.data){
        const index = newState["deleteModelId"].findIndex(i=>i === action.data.data.modelId);
        if(index !== -1){        
          newState["deleteModelId"].splice(index, 1);
        }
      }
      toastr[action.data.success ? "warning" : "error"](action.data.message);
    }
    return newState;
  }
);

reducerFactory.addAction(DEPLOY_MODEL.type, DEPLOY_MODEL.name,
  async (data) => {
    reducerFactory.action(MODEL_ACTION_PARAMS_CHANGE.name, {actionType: "deployModelId", modelId: data.modelId});
    const response = await NerModelApi.deployModel(data);
    return response.data;
  }, (state, action) => {
    const newState = _.cloneDeep(state);
    if (action && action.data) {
      if (action.data.success) {
        toastr.success(action.data.message);
      } else {
        toastr.error(action.data.message);
      }
    }
    return newState;
  }
);

reducerFactory.addAction(PARSE_MODEL.type, PARSE_MODEL.name,
  async (data) => {
    const response = await NerModelApi.parseModel(data);
    return response.data;
  }, (state, action) => {
    const newState = _.cloneDeep(state);
    if (action && action.data) {
      if (action.data.success) {
        newState.parsedResponse = action.data.data.neuralspace;
        toastr.success(action.data.message);
      } else {
        toastr.error(action.data.message);
      }
    }
    return newState;
  }
);

reducerFactory.addAction(GET_FEEDBACK_DATA.type, GET_FEEDBACK_DATA.name,
  async (data) => {
    if(data.loadFlag){
      reducerFactory.action(MODEL_LOADING.name, true);
    }
    delete data.loadFlag;
    const response = await NerModelApi.getFeedbackData(data);
    return response.data;
  }, (state, action) => {
    const newState = _.cloneDeep(state);
    if (action && action.data && action.data.success) {
      let { examples } = action.data.data;
      if (examples) {
        for (let i = 0; i < examples.length; i++) {
          examples[i]["styledExample"] = getConvertExampleData(examples[i])
        }
      }
      newState.feedbackData = action.data.data.examples;
      newState.feedbackDataCount = action.data.data.count;
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction(GET_MODEL_ANALYTICS.type, GET_MODEL_ANALYTICS.name,
  async data => {
    reducerFactory.action(MODEL_DATA_CHANGE.name, {key: "modelAnalyticsLoading", value: true });
    const response = await NerModelApi.fetchModelAnalytics(data);
    return response.data;
  }, (state,action) => {
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.modelAnalytics = action.data.data;
    }else{
      newState.modelAnalytics = {};
    }
    newState.modelAnalyticsLoading = false;
    return newState;
  }
);



export default reducerFactory;