export const nluLanguages = [
  'Multilingual/Code-Mixed', 'Afrikaans', 'Albanian', 'Arabic', "Arabic (Egyptian)", 'Arabic (Levantine)', 'Arabic (Maghrebi)', 'Arabic (Mesopotamian)', 'Arabic (Kuwaiti)', 'Arabic (Sudanese)', 'Arabic (Gulf)', 'Aragonese', 'Armenian', 'Assamese', 'Azerbaijani', 'Bari', 'Bashkir', 'Basque', 'Belarusian', 'Bengali', 'Bosnian', 'Breton', 'Bulgarian', 'Burmese', 'Catalan', 'Chechen', 'Chinese', 'Chuvash', 'Croatian', 'Czech', 'Danish', 'Dutch', 'English', 'Estonian', 'Finnish', 'French', 'Galician', 'Georgian', 'German', 'Greek', 'Gujarati', 'Haitian', 'Hebrew', 'Hindi', 'Hungarian', 'Icelandic', 'Ido', 'Indonesian', 'Irish', 'Italian', 'Japanese', 'Javanese', 'Kannada', 'Kazakh', 'Kirghiz', 'Korean', 'Lahnda', 'Latin', 'Latvian', 'Lithuanian', 'Luxembourgish', 'Macedonian', 'Malagasy', 'Malay', 'Malayalam', 'Maltese', 'Marathi', 'Nepali', 'Norwegian Bokmål', 'Occitan', 'Persian', 'Polish', 'Portuguese', 'Punjabi', 'Romanian', 'Russian', 'Serbian', 'Slovak', 'Slovenian', 'Spanish', 'Sundanese', 'Swahili', 'Swedish', 'Tagalog', 'Tajik', 'Tamil', 'Tatar', 'Telugu', 'Turkish', 'Uighur', 'Ukrainian', 'Urdu', 'Uzbek', 'Vietnamese', 'Volapük', 'Welsh', 'Yoruba'
]

export const trainingModes = ["autonlpner", "autonlp", "fastautonlp"];
export const defaultTrainingJob = 5;
export const replicaRps = 5;
export const uploadFileSupport = ["json", "csv", "yml"];

export const uploadFileSizeLimit = {
  value: 10485760,
  key: "10MB"
}

export const fileSamplesList = {
  json: "https://raw.githubusercontent.com/Neural-Space/neuralspace-examples/main/datasets/nlu/Sample%20formats/neuralspace/nlu.json",
  json_lookup: "https://raw.githubusercontent.com/Neural-Space/neuralspace-examples/main/datasets/nlu/Sample%20formats/neuralspace/lookup.json",
  json_regex: "https://raw.githubusercontent.com/Neural-Space/neuralspace-examples/main/datasets/nlu/Sample%20formats/neuralspace/regex.json",
  json_synonym: "https://raw.githubusercontent.com/Neural-Space/neuralspace-examples/main/datasets/nlu/Sample%20formats/neuralspace/synonym.json",
  csv: "https://raw.githubusercontent.com/Neural-Space/neuralspace-examples/main/datasets/nlu/Sample%20formats/csv/nlu.csv",
  csv_lookup: "https://raw.githubusercontent.com/Neural-Space/neuralspace-examples/main/datasets/nlu/Sample%20formats/csv/lookup.csv",
  csv_regex: "https://raw.githubusercontent.com/Neural-Space/neuralspace-examples/main/datasets/nlu/Sample%20formats/csv/regex.csv",
  csv_synonym: "https://raw.githubusercontent.com/Neural-Space/neuralspace-examples/main/datasets/nlu/Sample%20formats/csv/synonym.csv",
  yml: "https://raw.githubusercontent.com/Neural-Space/neuralspace-examples/main/datasets/nlu/Sample%20formats/rasa/nlu-with-entities.yml",
  yaml: "https://raw.githubusercontent.com/Neural-Space/neuralspace-examples/main/datasets/nlu/Sample%20formats/rasa/nlu-with-entities.yml",
}