import { apiMethod } from "../index";
import { HTTP_METHOD } from "../../../constants/common-constant";
import { CREATE_ENTITY, GET_SINGLE_ENTITY, DELETE_ENTITY, GET_ENTITIES, UPDATE_EXAMPLES_IN_ENTITY, GET_SEARCHED_ENTITIES } from "../../endpoints/nlu-endpoint";

const { GET, POST, DELETE } = HTTP_METHOD;

export const createEntity = data => apiMethod(POST, CREATE_ENTITY, data);

export const fetchEntities = data => apiMethod(POST, GET_ENTITIES, data);

export const fetchSearchedEntities = data => apiMethod(POST, GET_SEARCHED_ENTITIES, data);

export const fetchOneEntity = data => apiMethod(GET, GET_SINGLE_ENTITY, {
  params:{
    entityId: data.entityId
  }
});

export const updateExamplesInEntity = data => apiMethod(POST, UPDATE_EXAMPLES_IN_ENTITY, data);

export const deleteEntity = data => apiMethod(DELETE, DELETE_ENTITY, {data});