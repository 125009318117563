import { BRAND_NAME } from "../../constants/brand-constant";

export const transliterationLanguages = [
  "English <=> Hindi",
  "Hindi <=> English",

  "Arabic <=> English",
  "English <=> Arabic",

  "Bengali <=> English",
  "English <=> Bengali",

  "English <=> Gujarati",
  "Gujarati <=> English",

  "English <=> Kannada",
  "Kannada <=> English",

  "English <=> Malayalam",
  "Malayalam <=> English",

  "English <=> Marathi",
  "Marathi <=> English",

  "English <=> Punjabi",
  "Punjabi <=> English",

  "English <=> Sinhala",
  "Sinhala <=> English",

  "English <=> Tamil",
  "Tamil <=> English",

  "English <=> Telugu",
  "Telugu <=> English",

  "English <=> Urdu",
  "Urdu <=> English",

  "English <=> Bulgarian",
  "Bulgarian <=> English",

  "English <=> Greek",
  "Greek <=> English",

  "English <=> Armenian",
  "Armenian <=> English",

  "English <=> Georgian",
  "Georgian <=> English",

  "English <=> Macedonian",
  "Macedonian <=> English",

  "English <=> Mongolian",
  "Mongolian <=> English",

  "English <=> Russian",
  "Russian <=> English",

  "English <=> Serbian",
  "Serbian <=> English",

  "English <=> Ukrainian",
  "Ukrainian <=> English",

  "English <==> Odia (Oriya)",
	"Odia (Oriya) <==> English",

	"English <==> Assamese",
	"Assamese <==> English"

]

export const phoneticLanguages = [
  "Hindi",
  "Arabic",
  "Arabic (Egyptian)", 
  "Arabic (Levantine)",
  "Arabic (Maghrebi)",
  "Arabic (Mesopotamian)",
  "Arabic (Kuwaiti)",
  "Arabic (Sudanese)",
  "Arabic (Gulf)",
  "Bengali",
  "Gujarati",
  "Kannada",
  "Malayalam",
  "Marathi",
  "Punjabi",
  "Sinhala",
  "Tamil",
  "Telugu",
  "Urdu",
  "Bulgarian",
  "Greek",
  "Armenian",
  "Georgian",
  "Macedonian",
  "Mongolian",
  "Russian",
  "Serbian",
  "Ukrainian",
  "Odia (Oriya)",
  "Assamese"
];

export const transliterationLangs = [
  "English",
  "Hindi",
  "Arabic",
  "Bengali",
  "Gujarati",
  "Kannada",
  "Malayalam",
  "Marathi",
  "Punjabi",
  "Sinhala",
  "Tamil",
  "Telugu",
  "Urdu",
  "Bulgarian",
  "Greek",
  "Armenian",
  "Georgian",
  "Macedonian",
  "Mongolian",
  "Russian",
  "Serbian",
  "Ukrainian",
  "Odia (Oriya)",
  "Assamese"
];

export const minExampleToTrainTransliteration = 500;
export const defaultTrainingJob = 5;

export const transliterationBulkDatasetsFileTypes = [
  {
      label: `${BRAND_NAME} JSON`,
      value: "json"
  },
  {
      label: "CSV",
      value: "csv",
  }
];
export const uploadFileSupport = ["json", "csv"];

export const fileSamplesList = {
  json: "https://raw.githubusercontent.com/Neural-Space/neuralspace-examples/main/datasets/transliteration/Sample%20formats/neuralspace/transliteration.json",
  csv: "https://raw.githubusercontent.com/Neural-Space/neuralspace-examples/main/datasets/transliteration/Sample%20formats/csv/transliteration.csv",
}