import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  PricingPage
} from '../page-list';
import { pricingRoute } from "../../routes/common";

class Pricing extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path={pricingRoute.PRICING_ROUTE} component={PricingPage} />
        <Redirect to={pricingRoute.PRICING_ROUTE} />
      </Switch>
    )
  }
}

export default Pricing;