import _ from "lodash";

import {reducerName, augmentationState, TEST_AUGMENTATION, AUGMENTATION_LOADING, AUGMENTATON_PARAMS_CHANGE } from "../../states/augmentation/augmentation-state";
import * as AugmentationApi from "../../apis/augmentation/augmentation-api";
import ReducerFactory from "../../../utils/reducer-factory";

const initialState = {...augmentationState};
const reducerFactory = new ReducerFactory(reducerName, initialState);

reducerFactory.addAction(AUGMENTATION_LOADING.type, AUGMENTATION_LOADING.name,
  status => status, (state,action)=>{
		const newState = _.cloneDeep(state);
    newState.loading = action.data;
    return newState;
  }
);

reducerFactory.addAction(AUGMENTATON_PARAMS_CHANGE.type, AUGMENTATON_PARAMS_CHANGE.name,
  data=> data,(state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.length > 0){
      action.data.forEach(d=>{
        const { key, value } = d;
        newState[key] = value;
      });
    }  
    return newState;
  } 
);

reducerFactory.addAction(TEST_AUGMENTATION.type, TEST_AUGMENTATION.name,
  async data => {
    reducerFactory.action(AUGMENTATION_LOADING.name, true);
    const response = await AugmentationApi.testAugmentation(data);
    return response;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action?.data?.data?.success){
      newState.testResult = action.data.data.data;
    }
    newState.loading = false;
    return newState;
  }
);

export default reducerFactory;