import React from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import "./assets/data/style";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";

import AppLocale from "./lng-provider";
import Auth from "./containers/auth";
import Application from "./containers/application";
import PricingPage from "./containers/pricing";
import * as SettingsState from "./redux/states/settings-state";
import { applicationRoutes, authRoutes, pricingRoute } from "./routes/common";
import { isLoggedin } from "./helpers/common-helper";
import TranslationIndex from "./containers/translation";


class App extends React.Component {

  componentDidMount() {
    this.onInitializaGa();
    const { location } = this.props.history;
    if (location && location.pathname && location.pathname.includes("auth")) {
      if (isLoggedin) {
        this.props.history.push(authRoutes.ROOT_ROUTE);
        window.location.reload();
      }
      window.localStorage.clear();
    }
  }

  onInitializaGa = () =>{
    ReactGA.initialize("UA-221917698-1");
  }
  
  render() {
    const { locale, isDirectionRTL } = this.props;
    const currentAppLocale = AppLocale[locale.locale];
    return (
      <ConfigProvider locale={currentAppLocale.antd} direction={isDirectionRTL ? "rtl" : "ltr"}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <Switch>
            <Route path={"/translate"} component={TranslationIndex} />
            <Route path={pricingRoute.PRICING_ROOT} component={PricingPage} />
            {isLoggedin && <Route path={applicationRoutes.ROOT_ROUTE} component={Application} />}
            {!isLoggedin && <Route path={authRoutes.ROOT_ROUTE} component={Auth} />}
          </Switch>
        </IntlProvider>
      </ConfigProvider>
    )
  }
}

App.propTypes = {
  isDirectionRTL: PropTypes.bool.isRequired,
  locale: PropTypes.object.isRequired
}

const AppContainer = connect(
  state => ({
    isDirectionRTL: state.get(SettingsState.reducerName).isDirectionRTL,
    locale: state.get(SettingsState.reducerName).locale
  }),
  dispatch => ({

  })
)(App)

export default AppContainer