import { apiMethod } from "../index";
import { HTTP_METHOD } from "../../../constants/common-constant";
import { 
    TRANSCRIBE_FILE,
    TRANSCRIPTION_LIST,
    TRANSCRIPTION_DETAILS,
    TRANSCRIPTION_DELETE,
} from "../../endpoints/transcription-endpoint";

const { GET, POST, DELETE } = HTTP_METHOD;

export const transcribeFile = data => apiMethod(POST, TRANSCRIBE_FILE, data);

export const transcriptionList = data => apiMethod(POST, TRANSCRIPTION_LIST, data);

export const transcriptionDetails = data => apiMethod(GET, TRANSCRIPTION_DETAILS, {
    params:{
        transcribeId: data.transcribeId
    }
});

export const transcriptionDelete = data => apiMethod(DELETE, TRANSCRIPTION_DELETE, {
    params:{
        transcribeId: data.transcribeId
    }
});