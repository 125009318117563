export const reducerName = "translation";

export const translationState={
  loading: false,
  testResult: {},
  newExample: {
    text: "",
    entities: [],
    styledText: ""
  },
}

export const TRANSLATION_LOADING = {
  type: "TRANSLATION_LOADING",
  name: "translationLoading"
};

export const TRANSLATION_PARAMS_CHANGE = {
  type: "TRANSLATION_PARAMS_CHANGE",
  name: "translationParamsChange"
};

export const TEST_TRANSLATION={
  type: "TEST_TRANSLATION",
  name: "testTranslation"
};