export const reducerName = "augmentation";

export const augmentationState={
  loading: false,
  testResult: {},
  newExample: {
    text: "",
    entities: [],
    styledText: ""
  },
}

export const AUGMENTATION_LOADING = {
  type: "AUGMENTATION_LOADING",
  name: "augmentationLoading"
};

export const AUGMENTATON_PARAMS_CHANGE = {
  type: "AUGMENTATON_PARAMS_CHANGE",
  name: "augmentationParamsChange"
};

export const TEST_AUGMENTATION={
  type: "TEST_AUGMENTATION",
  name: "testAugmentation"
};