import { BRAND_NAME } from "../constants/brand-constant";
import { THEME_TYPE_DARK, THEME_TYPE_LIGHT, THEME_TYPE_SEMI_DARK } from "../constants/theme-setting-constants";
export const countryList = [
    "Afghanistan",
    "Åland Islands",
    "Albania",
    "Algeria",
    "American Samoa",
    "AndorrA",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo",
    "Congo, The Democratic Republic of the",
    "Cook Islands",
    "Costa Rica",
    "Cote D'Ivoire",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands (Malvinas)",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island and Mcdonald Islands",
    "Holy See (Vatican City State)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran, Islamic Republic Of",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, Democratic People'S Republic of",
    "Korea, Republic of",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People'S Democratic Republic",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libyan Arab Jamahiriya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Macedonia, The Former Yugoslav Republic of",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia, Federated States of",
    "Moldova, Republic of",
    "Monaco",
    "Mongolia",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "Netherlands Antilles",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestinian Territory, Occupied",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Reunion",
    "Romania",
    "Russian Federation",
    "RWANDA",
    "Saint Helena",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia and Montenegro",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan, Province of China",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "United States Minor Outlying Islands",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Viet Nam",
    "Virgin Islands, British",
    "Virgin Islands, U.S.",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe"
]

export const languages = [
    'Multilingual/Code-Mixed', 'Afrikaans', 'Albanian', 'Arabic', "Arabic (Egyptian)", 'Arabic (Levantine)', 'Arabic (Maghrebi)', 'Arabic (Mesopotamian)', 'Arabic (Kuwaiti)', 'Arabic (Sudanese)', 'Arabic (Gulf)', 'Aragonese', 'Armenian', 'Assamese', 'Azerbaijani', 'Bari', 'Bashkir', 'Basque', 'Belarusian', 'Bengali', 'Bosnian', 'Breton', 'Burmese', 'Catalan', 'Chechen', 'Chinese', 'Chuvash', 'Croatian', 'Czech', 'Danish', 'Dutch', 'English', 'Estonian', 'Finnish', 'French', 'Galician', 'Georgian', 'German', 'Greek', 'Gujarati', 'Haitian', 'Hebrew', 'Hindi', 'Hungarian', 'Icelandic', 'Ido', 'Indonesian', 'Irish', 'Italian', 'Japanese', 'Javanese', 'Kannada', 'Kazakh', 'Kirghiz', 'Korean', 'Lahnda', 'Latin', 'Lithuanian', 'Luxembourgish', 'Macedonian', 'Malagasy', 'Malay', 'Malayalam', 'Maltese', 'Marathi', 'Nepali', 'Norwegian Bokmål', 'Occitan', 'Persian', 'Polish', 'Portuguese', 'Punjabi', 'Romanian', 'Russian', 'Serbian', 'Slovak', 'Slovenian', 'Spanish', 'Sundanese', 'Swahili', 'Swedish', 'Tagalog', 'Tajik', 'Tamil', 'Tatar', 'Telugu', 'Turkish', 'Uighur', 'Ukrainian', 'Urdu', 'Uzbek', 'Vietnamese', 'Volapük', 'Welsh', 'Yoruba', 'Bulgarian', 'Latvian'
]

export const languageMap = {
    "Amharic": "am",
    "Central Khmer": "km",
    "Lao": "lo",
    "Mongolian": "mn",
    "Norwegian": "no",
    "Sinhala": "si",
    "Thai": "th",
    "Zulu": "zu",

    "Chinese (Simplified)": "zh-CN",
    "Chinese (Traditional)": "zh-TW",
    "Bari": "bfa",
    "Basque": "eu",
    "Belarusian": "be",
    "Catalan": "ca",
    "Croatian": "hr",
    "Czech": "cs",
    "Estonian": "et",
    "Galician": "gl",
    "Hungarian": "hu",
    "Irish": "ga",
    "Latin": "la",
    "Latvian": "lv",
    "Lahnda": "lah",
    "Serbian": "sr",
    "Slovak": "sk",
    "Slovenian": "sl",
    "Bulgarian": "bg",
    "Armenian": "hy",
    "Turkish": "tr",
    "Ukrainian": "uk",
    "Hebrew": "he",
    "Kazakh": "kk",
    "Maltese": "mt",
    "Uighur": "ug",
    "Finnish": "fi",
    "Swedish": "sv",
    "Indonesian": "id",
    "Korean": "ko",
    "Vietnamese": "vi",
    "Afrikaans": "af",
    "Hindi": "hi",
    "Bengali": "bn",
    "Telugu": "te",
    "Tamil": "ta",
    "Marathi": "mr",
    "Urdu": "ur",
    "Gujarati": "gu",
    "Kannada": "kn",
    "Malayalam": "ml",
    "Assamese": "as",
    "Punjabi": "pa",
    "Persian": "fa",
    "Arabic": "ar",
    "Arabic (Egyptian)": "arz",
	"Arabic (Levantine)": "apc",
	"Arabic (Maghrebi)": "ama",
	"Arabic (Mesopotamian)": "acm",
	"Arabic (Kuwaiti)": "akw",
	"Arabic (Sudanese)": "apd",
	"Arabic (Gulf)": "afb",
    "Greek": "el",
    "Danish": "da",
    "English": "en",
    "Norwegian Bokmål": "nb",
    "Chinese": "zh",
    "Dutch": "nl",
    "French": "fr",
    "German": "de",
    "Italian": "it",
    "Japanese": "ja",
    "Lithuanian": "lt",
    "Polish": "pl",
    "Portuguese": "pt",
    "Romanian": "ro",
    "Russian": "ru",
    "Spanish": "es",

    "Albanian": "sq",
    "Aragonese": "an",
    "Azerbaijani": "az",
    "Odia (Oriya)": "or",
    "Bashkir": "ba",
    "Bosnian": "bs",
    "Breton": "br",
    "Burmese": "my",
    "Chechen": "ce",
    "Chuvash": "cv",
    "Georgian": "ka",
    "Haitian": "ht",
    "Icelandic": "is",
    "Ido": "io",
    "Javanese": "jv",
    "Kirghiz": "ky",
    "Luxembourgish": "lb",
    "Macedonian": "mk",
    "Malagasy": "mg",
    "Malay": "ms",
    "Nepali": "ne",
    "Occitan": "oc",
    "Sundanese": "su",
    "Swahili": "sw",
    "Tagalog": "tl",
    "Tajik": "tg",
    "Tatar": "tt",
    "Uzbek": "uz",
    "Volapük": "vo",
    "Welsh": "cy",
    "Yoruba": "yo",
    "Multilingual/Code-Mixed": "multilingual"
};

export const reverseLangMap = {
    "am": "Amharic",
    "km": "Central Khmer",
    "lo": "Lao",
    "mn": "Mongolian",
    "no": "Norwegian",
    "si": "Sinhala",
    "th": "Thai",
    "zu": "Zulu",

    "bfa": "Bari",
    "eu": "Basque",
    "be": "Belarusian",
    "ca": "Catalan",
    "hr": "Croatian",
    "cs": "Czech",
    "et": "Estonian",
    "gl": "Galician",
    "hu": "Hungarian",
    "ga": "Irish",
    "lah": "Lahnda",
    "la": "Latin",
    "lv": "Latvian",
    "sr": "Serbian",
    "sk": "Slovak",
    "sl": "Slovenian",
    "bg": "Bulgarian",
    "hy": "Armenian",
    "tr": "Turkish",
    "uk": "Ukrainian",
    "he": "Hebrew",
    "kk": "Kazakh",
    "mt": "Maltese",
    "ug": "Uighur",
    "fi": "Finnish",
    "sv": "Swedish",
    "id": "Indonesian",
    "ko": "Korean",
    "vi": "Vietnamese",
    "af": "Afrikaans",
    "hi": "Hindi",
    "bn": "Bengali",
    "te": "Telugu",
    "ta": "Tamil",
    "mr": "Marathi",
    "ur": "Urdu",
    "gu": "Gujarati",
    "kn": "Kannada",
    "ml": "Malayalam",
    "as": "Assamese",
    "pa": "Punjabi",
    "fa": "Persian",
    "ar": "Arabic",
    "arz": "Arabic (Egyptian)",
	"apc": "Arabic (Levantine)",
	"ama": "Arabic (Maghrebi)",
	"acm": "Arabic (Mesopotamian)",
	"akw": "Arabic (Kuwaiti)",
	"apd": "Arabic (Sudanese)",
	"afb": "Arabic (Gulf)",
    "el": "Greek",
    "da": "Danish",
    "en": "English",
    "nb": "Norwegian Bokmål",
    "zh": "Chinese",
    "nl": "Dutch",
    "fr": "French",
    "de": "German",
    "it": "Italian",
    "ja": "Japanese",
    "lt": "Lithuanian",
    "pl": "Polish",
    "pt": "Portuguese",
    "ro": "Romanian",
    "ru": "Russian",
    "es": "Spanish",
    "ph": "Filipino",
    "sq": "Albanian",
    "an": "Aragonese",
    "az": "Azerbaijani",
    "ba": "Bashkir",
    "bs": "Bosnian",
    "br": "Breton",
    "my": "Burmese",
    "ce": "Chechen",
    "cv": "Chuvash",
    "ka": "Georgian",
    "ht": "Haitian",
    "is": "Icelandic",
    "io": "Ido",
    "jv": "Javanese",
    "ky": "Kirghiz",
    "lb": "Luxembourgish",
    "mk": "Macedonian",
    "mg": "Malagasy",
    "ms": "Malay",
    "ne": "Nepali",
    "oc": "Occitan",
    "su": "Sundanese",
    "sw": "Swahili",
    "tl": "Tagalog",
    "tg": "Tajik",
    "tt": "Tatar",
    "uz": "Uzbek",
    "vo": "Volapük",
    "cy": "Welsh",
    "yo": "Yoruba",
    "eo": "Esperanto",
    "kz": "Kazakh/Qazaq",
    "vn": "Vietnamese",
    "cn": "Mandarin",
    "or": "Odia (Oriya)",
    "multilingual": "Multilingual/Code-Mixed"
};

export const entityTypes = [
    "Pre-trained",
    "Lookup",
    "Regex",
    "Synonym"
];

export const entityOption = [
    "All",
    "Pre-trained",
    "Lookup",
    "Regex",
    "Synonym",
    "Trainable"
]

export const exampleEntityTypes = [
    "Pre-trained",
    "Lookup",
    "Regex",
    "Trainable",
]

export const entityTypeMap = {
    "All": "all",
    "Pre-trained": "pre-trained",
    "Regex": "regex",
    "Lookup": "lookup",
    "Synonym": "synonym",
    "Trainable": "trainable"
}

export const reverseEntityMap = {
    "all": "All",
    "pre-trained": "Pre-trained",
    "regex": "Regex",
    "lookup": "Lookup",
    "synonym": "Synonym",
    "trainable": "Trainable"
}

export const trainingStatusList = [
    "Initiated",
    "Queued",
    "Pipeline Building",
    "Pipeline Built",
    "Preparing Data",
    "Data Prepared",
    "Training",
    "Trained",
    "Saved",
    "Completed",
    "Failed",
    "Timed Out",
    "Dead"
];

export const deployedStatusList = [
    "All",
    "Deployed",
    "Not deployed"
]

export const keyLength = {
    "projectName": 4,
    "modelName": 4,
    "maxExample": 15000,
    "maxEntity": 30,
    "minEntity": 1
};

export const preparedOptions = [
    {
        label: "All",
        value: "all"
    },
    {
        label: "Prepared",
        value: "prepared"
    },
    {
        label: "Not Prepared",
        value: "notPrepared"
    }
];

export const exampleTypeOptions = [
    {
        label: "All",
        value: "all"
    },
    {
        label: "Train",
        value: "train"
    },
    {
        label: "Test",
        value: "test"
    }
];

export const uploadFileSize = {
    value: 5242880,
    key: "5MB"
}
export const rtlLanguages = ["ar", "ur", "he", "fa", "arz", "apc", "ama", "acm", "akw", "apd", "afb"];
export const transactionStatusList = [
    {
        label: "Pending",
        value: "pending"
    },
    {
        label: "Invoiced",
        value: "invoiced"
    }
];
export const parameterNames = ["deployedModels", "examplesPerDataset", "projectCount"]
export const defaultCredit = 200;
export const langProviders = ["en", "es", "zh", "fr", "it", "ar"];
export const APP_PRIMARY_COLOR = "#45988E";
export const APP_SECONDARY_COLOR = "#F26419";
export const ENABLE_INVITE_CODE = "NO";
export const ENABLE_PAYMENT = "YES";
export const SUPPORT_EMAIL = "hello@nuralspace.ai";
export const PROD_URL = "https://platform.neuralspace.ai";
export const STRIPE_PUBLIC_KEY_TEST = "pk_test_51JCpOBHz9YLCt8Osy2l8GwLhlL0ZaMGD9e7a5iGdV6B0zcm1ySdZTSqpwsP4YREoDllCs2ojj9IYipO7xuThjaJu001lFCtpIV";
export const STRIPE_PUBLIC_KEY_LIVE = "pk_live_51JCpOBHz9YLCt8Os3FoBXiMHsYZ7NDzUOyxxJ4fXGYffv2KNbnSAFOToyyHpSxKkLoaUGRxOMGf5tVrbYDXHJxZd00ecW5Wz87";
export const THEMES = [
    {
        key: "light",
        value: THEME_TYPE_LIGHT,
        label: "Light"
    },
    {
        key: "semi-dark",
        value: THEME_TYPE_SEMI_DARK,
        label: "Semi dark"
    },
    {
        key: "dark",
        value: THEME_TYPE_DARK,
        label: "Dark"
    }
];
export const codeSnippetLanguages = [
    {
        key: "bash",
        value: "bash",
        label: "cURL",
        icon: "icon icon-curl ns-btn-icon"
    },
    {
        key: "node-axios",
        value: "node-axios",
        label: "Nodejs-axios",
        icon: "icon icon-nodejs ns-btn-icon"
    },
    {
        key: "node-request",
        value: "node-request",
        label: "Nodejs-request",
        icon: "icon icon-nodejs ns-btn-icon"
    },
    {
        key: "python-http.client",
        value: "python-http.client",
        label: "Python - http.client",
        icon: "icon icon-python ns-btn-icon"
    },
    {
        key: "python-Requests",
        value: "python-Requests",
        label: "Python - Requests",
        icon: "icon icon-python ns-btn-icon"
    }
]
export const searchPlanType = [
    {
        label: "All",
        value: "All"
    },
    {
        label: "Free",
        value: "Free"
    },
    {
        label: "Pro",
        value: "Pro"
    }
]

export const bulkDatasetsFileTypes = [
    {
        label: `${BRAND_NAME} JSON`,
        value: "json"
    },
    {
        label: "CSV",
        value: "csv",
    },
    {
        label: "Rasa YML",
        value: "yml"
    }
];

export const bulkDatasetsFileFormats = ".json, .yaml, .yml, .csv";
export const bulkDatasetsFileFormatArr = ['json', 'csv', 'yml', 'yaml'];
export const getCodeSnippetLanguage = {
    "bash": {
        value: "bash",
        lineHeight: "28px"
    },
    "node-axios": {
        value: "javascript",
        lineHeight: "24px"
    },
    "node-request": {
        value: "javascript",
        lineHeight: "24px"
    },
    "python-http.client": {
        value: "python",
        lineHeight: "24px"
    },
    "python-Requests": {
        value: "python",
        lineHeight: "24px"
    }
}
export const FOOTER_TEXT = `Copyright NeuralSpace © ${new Date().getFullYear()}`;

