import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Layout, Drawer } from "antd";

import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LIGHT
} from "../../../../constants/theme-setting-constants";
import * as SettingsState from "../../../../redux/states/settings-state";
import SettingsReducer from "../../../../redux/modules/settings-module";
import SidebarContent from "./sidebar-content";

class Sidebar extends React.Component{
  constructor(props){
    super(props);
    this.state={
      sidebarCollapsed: false
    }
  }

  onSidebarCollapsed = () => this.setState({sidebarCollapsed: !this.state.sidebarCollapsed});
  onNavCollapsed = () => {
    const { navCollapsed, settingsReducer } = this.props;
    settingsReducer[SettingsState.SET_NAVCOLLAPSED.name](!navCollapsed);
  }

  render(){
    const { windowWidth, navStyle, themeType, navCollapsed } = this.props;
    const { sidebarCollapsed } = this.state;
    let drawerStyle = "ns-collapsed-sidebar";

    if (navStyle === NAV_STYLE_FIXED) {
      drawerStyle = "";
    } else if (navStyle === NAV_STYLE_MINI_SIDEBAR) {
      drawerStyle = "ns-mini-sidebar";
    } else if (navStyle === NAV_STYLE_DRAWER) {
      drawerStyle = "ns-collapsed-sidebar"
    }
    if ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR
      || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) && windowWidth < TAB_SIZE) {
      drawerStyle = "ns-collapsed-sidebar"
    }
    return(
      <Layout.Sider
        className={`ns-app-sidebar ${drawerStyle} ${themeType !== THEME_TYPE_LIGHT ? 'ns-layout-sider-dark' : null}`}
        trigger={null}
        collapsed={(windowWidth < TAB_SIZE ? false : sidebarCollapsed || navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR)}
        theme={themeType === THEME_TYPE_LIGHT ? "light" : "dark"}
        collapsible
      >
        {
          navStyle === NAV_STYLE_DRAWER || windowWidth < TAB_SIZE ?
          <Drawer
            className={`ns-drawer-sidebar ${themeType !== THEME_TYPE_LIGHT ? 'ns-drawer-sidebar-dark' : null}`}
            placement="left"
            closable={false}
            onClose={this.onNavCollapsed}
            open={navCollapsed}
          >
            <SidebarContent sidebarCollapsed={sidebarCollapsed} onSidebarCollapsed={this.onSidebarCollapsed} />
          </Drawer>:
          <SidebarContent sidebarCollapsed={sidebarCollapsed} onSidebarCollapsed={this.onSidebarCollapsed} />
        }
      </Layout.Sider>
    )
  }
}

Sidebar.propTypes = {
  windowWidth: PropTypes.number.isRequired,
  themeType: PropTypes.string.isRequired,
  navStyle: PropTypes.string.isRequired,
  navCollapsed: PropTypes.bool.isRequired,
  settingsReducer: PropTypes.object.isRequired
}

const SidebarContainer = connect(
  state => ({
    windowWidth: state.get(SettingsState.reducerName).windowWidth,
    themeType: state.get(SettingsState.reducerName).themeType,
    navStyle: state.get(SettingsState.reducerName).navStyle,
    navCollapsed: state.get(SettingsState.reducerName).navCollapsed
  }),
  dispatch => ({
    settingsReducer: SettingsReducer.getActions(dispatch)
  })
)(Sidebar);

export default SidebarContainer
