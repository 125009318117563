import { NLU, VERSION1 } from "../../constants/common-constant";

export const NLU_DASHBOARD_COUNT = `${NLU}/${VERSION1}/dashboard/count`;
export const NLU_DASHBOARD_API_CALL_GRAPH = `${NLU}/${VERSION1}/dashboard/api-count`;
export const NLU_DASHBOARD_DEPLOYED_MODELS = `${NLU}/${VERSION1}/dashboard/models`;
export const NLU_DASHBOARD_EXAMPLE_COUNT_HISTOGRAM = `${NLU}/${VERSION1}/dashboard/examples-per-project-histogram`;

export const CREATE_PROJECT = `${NLU}/${VERSION1}/project`;
export const GET_ALL_PROJECTS = `${NLU}/${VERSION1}/all/list/projects`;
export const GET_PROJECTS = `${NLU}/${VERSION1}/list/projects`;
export const GET_SINGLE_PROJECT = `${NLU}/${VERSION1}/project`;
export const UPDATE_PROJECT = `${NLU}/${VERSION1}/project`;
export const DELETE_PROJECT = `${NLU}/${VERSION1}/project`;

export const SUPPORTED_LANGUAGE = `${NLU}/${VERSION1}/supported-languages`;

export const CREATE_INTENT = `${NLU}/${VERSION1}/intent`;
export const GET_SINGLE_INTENT = `${NLU}/${VERSION1}/intent`;
export const DELETE_INTENT = `${NLU}/${VERSION1}/intent`;
export const GET_INTENTS = `${NLU}/${VERSION1}/list/intent`;
export const FETCH_ALL_INTENTS = `${NLU}/${VERSION1}/all/intents`; 

export const CREATE_ONE_EXAMPLE = `${NLU}/${VERSION1}/single/example`;
export const GET_SINGLE_EXAMPLE = `${NLU}/${VERSION1}/example`;
export const DELETE_EXAMPLE = `${NLU}/${VERSION1}/example`;
export const DELETE_EXAMPLE_FEEDBACK = `${NLU}/${VERSION1}/feedback/example`;
export const GET_EXAMPLES = `${NLU}/${VERSION1}/list/example`
export const UPDATE_EXAMPLE = `${NLU}/${VERSION1}/example`;
export const UPDATE_MULTIPLE_EXAMPLES = `${NLU}/${VERSION1}/multiple/example`;
export const EXAMPLE_INTENT_DISTRIBUTION = `${NLU}/${VERSION1}/example-intent/distribution`;
export const UPDATE_TYPE_IN_EXAMPLE = `${NLU}/${VERSION1}/type/example`;
export const UPDATE_ENTITY_IN_EXAMPLE = `${NLU}/${VERSION1}/entity/example`;
export const UPDATE_INTENT_IN_EXAMPLE = `${NLU}/${VERSION1}/intent/example`;
export const CREATE_NEW_INTENT_FROM_EXAMPLE = `${NLU}/${VERSION1}/intent/new/example`; 
export const UPDATE_TEXT_IN_EXAMPLE = `${NLU}/${VERSION1}/text/example`; 
export const DELETE_ONE_EXAMPLE = `${NLU}/${VERSION1}/single/example`;
export const CREATE_NEW_ENTITY_FROM_EXAMPLE = `${NLU}/${VERSION1}/entity/new/example`;
export const ADD_EXAMPLE_TO_DATASET = `${NLU}/${VERSION1}/add/dataset/example`;


export const DELETE_ITEM_FROM_BASKET = `${NLU}/${VERSION1}/entity-basket/delete`;
export const FETCH_ENTITY_LIST_FROM_BASKET = `${NLU}/${VERSION1}/list/entities/entity-basket`;

export const CREATE_ENTITY = `${NLU}/${VERSION1}/entity`;
export const GET_SINGLE_ENTITY = `${NLU}/${VERSION1}/entity`;
export const DELETE_ENTITY = `${NLU}/${VERSION1}/entity`;
export const UPDATE_EXAMPLES_IN_ENTITY = `${NLU}/${VERSION1}/update/examples/entity`;
export const GET_ENTITIES = `${NLU}/${VERSION1}/list/entity`;
export const GET_SEARCHED_ENTITIES = `${NLU}/${VERSION1}/list/search/entity`;


export const TRAIN_MODEL = `${NLU}/${VERSION1}/model/train/queue`;
export const RE_TRAIN_MODEL = `${NLU}/${VERSION1}/model/re-train/queue`;
export const GET_SINGLE_MODEL = `${NLU}/${VERSION1}/model`;
export const GET_MODELS = `${NLU}/${VERSION1}/list/model`;
export const UPDATE_MODEL = `${NLU}/${VERSION1}/model`;
export const DELETE_MODEL = `${NLU}/${VERSION1}/model`;
export const GET_MODEL_ANALYTICS = `${NLU}/${VERSION1}/model/analytics`;

export const DEPLOY_MODEL = `${NLU}/${VERSION1}/model/deploy`;
export const PARSE_MODEL = `${NLU}/${VERSION1}/model/parse`;
export const MODEL_FEEDBACK = `${NLU}/${VERSION1}/model/feedback`;

export const ADD_SYNONYMS_TO_PROJECT = `${NLU}/${VERSION1}/project/add/synonym`;
export const GET_SYNONYMS_OF_PROJECT = `${NLU}/${VERSION1}/project/list/synonym`;
export const REMOVE_SYNONYMS_FROM_PROJECT = `${NLU}/${VERSION1}/project/remove/synonym`;


export const UPLOAD_DATASET = `${NLU}/${VERSION1}/upload/bulk/examples`;
export const IMPORT_DATASET = `${NLU}/${VERSION1}/import/bulk/dataset`;
export const DOWNLOAD_FAILED_FILES = `${NLU}/${VERSION1}/download/bulk/failed`;
export const GET_ONE_UPLOAD = `${NLU}/${VERSION1}/upload/one/dataset`;

export const FETCH_NS_DATASETS = `${NLU}/${VERSION1}/datasets`;
export const FETCH_ALL_DATATSET_UPLOADS = `${NLU}/${VERSION1}/all/uploads`;