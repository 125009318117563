export const reducerName = "nerModel";
export const nerModelState = {
  loading: false,
  modelListLoading: false,
  singleModelLoading: false,
  deleteModelId: [],
  deployModelId: [],
  pageNumber: 1,
  pageSize: 15,
  search: "",
  count: 0,
  models: [],
  lastModel: {},
  trainingStatus: [],
  deployStatus: "All",
  parsedResponse: {},
  feedbackData:[],
  feedbackDataCount: 0,
  modelAnalyticsLoading: false,
  modelAnalytics: {},
  error: null
}

export const MODEL_LOADING = {
  type: "NER_MODEL_LOADING",
  name: "nermodelLoading"
};

export const MODEL_PARAMS_CHANGE = {
  type: "NER_MODEL_PARAMS_CHANGE",
  name: "nermodelParamsChange"
};

export const MODEL_ACTION_PARAMS_CHANGE = {
  type: "NER_MODEL_ACTION_PARAMS_CHANGE",
  name: "nermodelActionParamsChange"
}

export const MODEL_DATA_CHANGE = {
  type: "NER_MODEL_DATA_CHANGE",
  name: "nermodelDataChange"
}

export const TRAIN_MODEL = {
  type: "NER_TRAIN_MODEL",
  name: "nertrainModel"
};

export const FETCH_MODELS = {
  type: "NER_FETCH_MODELS",
  name: "nerfetchModels"
};

export const SINGLE_MODEL = {
  type: "NER_SINGLE_MODEL",
  name: "nersingleModel"
};

export const UPDATE_MODEL = {
  type: "NER_UPDATE_MODEL",
  name: "nerupdateModel"
};

export const DELETE_MODEL={
  type: "NER_DELETE_MODEL",
  name: "nerdeleteModel"
};

export const DEPLOY_MODEL={
  type: "NER_DEPLOY_MODEL",
  name: "nerdeployModel"
};

export const PARSE_MODEL={
  type: "NER_PARSE_MODEL",
  name: "nerparseModel"
};

export const GET_FEEDBACK_DATA={
  type: "NER_GET_FEEDBACK_DATA",
  name: "nergetFeedbackData"
}

export const GET_MODEL_ANALYTICS={
  type: "NER_GET_MODEL_ANALYTICS",
  name: "nerGetModelAnalytics"
}