export const ldLanguages = [
  "Afrikaans",
  "Tosk Albanian",
  "Amharic",
  "Aragonese",
  "Arabic",
  "Egyptian Arabic",
  "Assamese",
  "Asturian",
  "Avaric",
  "Azerbaijani",
  "South Azerbaijani",
  "Bashkir",
  "Bavarian",
  "Central Bikol",
  "Belarusian",
  "Bulgarian",
  "Bengali",
  "Tibetan",
  "Bishnupriya",
  "Breton",
  "Bosnian",
  "Russia Buriat",
  "Catalan",
  "Chavacano",
  "Chechen",
  "Cebuano",
  "Central Kurdish",
  "Corsican",
  "Czech",
  "Chuvash",
  "Welsh",
  "Danish",
  "German",
  "Dimli (individual language)",
  "Lower Sorbian",
  "Dotyali",
  "Dhivehi",
  "Modern Greek (1453-)",
  "English",
  "Esperanto",
  "Spanish",
  "Estonian",
  "Basque",
  "Persian",
  "Finnish",
  "French",
  "Northern Frisian",
  "Western Frisian",
  "Irish",
  "Scottish Gaelic",
  "Galician",
  "Guarani",
  "Goan Konkani",
  "Gujarati",
  "Manx",
  "Hebrew",
  "Hindi",
  "Fiji Hindi",
  "Croatian",
  "Upper Sorbian",
  "Haitian",
  "Hungarian",
  "Armenian",
  "Interlingua (International Auxiliary Language Association)",
  "Indonesian",
  "Interlingue",
  "Iloko",
  "Ido",
  "Icelandic",
  "Italian",
  "Japanese",
  "Lojban",
  "Javanese",
  "Georgian",
  "Kazakh",
  "Central Khmer",
  "Kannada",
  "Korean",
  "Karachay-Balkar",
  "Kurdish",
  "Komi",
  "Cornish",
  "Kirghiz",
  "Latin",
  "Luxembourgish",
  "Lezghian",
  "Limburgan",
  "Lombard",
  "Lao",
  "Northern Luri",
  "Lithuanian",
  "Latvian",
  "Maithili",
  "Malagasy",
  "Eastern Mari",
  "Minangkabau",
  "Macedonian",
  "Malayalam",
  "Mongolian",
  "Marathi",
  "Western Mari",
  "Malay (macrolanguage)",
  "Maltese",
  "Mirandese",
  "Burmese",
  "Erzya",
  "Mazanderani",
  "Neapolitan",
  "Low German",
  "Nepali (macrolanguage)",
  "Newari",
  "Dutch",
  "Norwegian Nynorsk",
  "Norwegian",
  "Occitan (post 1500)",
  "Oriya (macrolanguage)",
  "Ossetian",
  "Panjabi",
  "Pampanga",
  "Pfaelzisch",
  "Polish",
  "Piemontese",
  "Western Panjabi",
  "Pushto",
  "Portuguese",
  "Quechua",
  "Romansh",
  "Romanian",
  "Russian",
  "Rusyn",
  "Sanskrit",
  "Yakut",
  "Sardinian",
  "Sicilian",
  "Scots",
  "Sindhi",
  "Serbo-Croatian",
  "Sinhala",
  "Slovak",
  "Slovenian",
  "Somali",
  "Albanian",
  "Serbian",
  "Sundanese",
  "Swedish",
  "Swahili (macrolanguage)",
  "Tamil",
  "Telugu",
  "Tajik",
  "Thai",
  "Turkmen",
  "Tagalog",
  "Turkish",
  "Tatar",
  "Tuvinian",
  "Uighur",
  "Ukrainian",
  "Urdu",
  "Uzbek",
  "Venetian",
  "Veps",
  "Vietnamese",
  "Vlaams",
  "Volapük",
  "Walloon",
  "Waray (Philippines)",
  "Wu Chinese",
  "Kalmyk",
  "Mingrelian",
  "Yiddish",
  "Yoruba",
  "Yue Chinese"
]