export const reducerName = "dashboard";
export const dashboardState = {
  dashboardGarphLoading: false,
  dashboardCountsLoading: false,
  usage: {},
  appUsage: [],
  count: 0,
  apiCalls: [],
  generic: [],
  genericLoading: false,
  appSpecific: [],
  appSpecificLoading: false
}

export const DASHBOARD_PARAMS_CHANGE = {
  type: "DASHBOARD_PARAMS_CHANGE",
  name: "dashboardParamsChange"
}

export const GET_MAIN_DASHBOARD = {
  type: "GET_MAIN_DASHBOARD",
  name: "getMainDashboard"
}

export const GET_MAIN_DASHBOARD_API_COUNT = {
  type: "GET_MAIN_DASHBOARD_API_COUNT",
  name: "getMainDashboardApiCount"
}

export const GET_MAIN_DASHBOARD_GENERIC_STATS = {
  type: "GET_MAIN_DASHBOARD_GENERIC_STATS",
  name: "getMainDashboardGenericStats"
}

export const GET_MAIN_DASHBOARD_APP_SPECIFIC_STATS = {
  type: "GET_MAIN_DASHBOARD_APP_SPECIFIC_STATS",
  name: "getMainDashboardAppSpecificStats"
}
