import toastr from "toastr";
import _ from "lodash";

import { 
  transliterationProjectState,
  reducerName, 
  PROJECT_PARAMS_CHANGE,
  PROJECT_PARAMS_CHANGE_WITHOUT_ASYNC,
  SORT_PROJECTS,
  CREATE_PROJECT,
  UPDATE_PROJECT,
  FETCH_ALL_PROJECTS,
  FETCH_PROJECTS,
  SINGLE_PROJECT,
  DELETE_PROJECT,
  PROJECT_LOADING
} from "../../states/transliteration/transliteration-project-state";
import * as TransliterationProjectApi from "../../apis/transliteration/transliteration-project-api";
import ReducerFactory from "../../../utils/reducer-factory";

const initialState = {...transliterationProjectState};
const reducerFactory = new ReducerFactory(reducerName, initialState);

reducerFactory.addAction(PROJECT_LOADING.type, PROJECT_LOADING.name,
  status => status, (state,action)=>{
		const newState = _.cloneDeep(state);
    newState.loading = action.data;
    return newState;
  }
);

reducerFactory.addAction(PROJECT_PARAMS_CHANGE.type, PROJECT_PARAMS_CHANGE.name,
  async data=> data,(state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.length > 0){
      action.data.forEach(d=>{
        const { key, value } = d;
        newState[key] = value;
      })
    }    
    return newState;
  } 
);

reducerFactory.addAction(PROJECT_PARAMS_CHANGE_WITHOUT_ASYNC.type, PROJECT_PARAMS_CHANGE_WITHOUT_ASYNC.name,
  data=> data,(state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.length > 0){
      action.data.forEach(d=>{
        const { key, value } = d;
        newState[key] = value;
      })
    }    
    return newState;
  } 
);

reducerFactory.addAction(SORT_PROJECTS.type, SORT_PROJECTS.name,
  async data=> data, (state,action)=>{
    const {sortBy} = action.data;
    const newState = _.cloneDeep(state);
    newState.sortBy = sortBy;
    newState["sortByKeys"][sortBy.key] = sortBy.value;
    return newState; 
  }
)

reducerFactory.addAction(CREATE_PROJECT.type, CREATE_PROJECT.name,
	async(data)=>{
    reducerFactory.action(PROJECT_LOADING.name, true);
    const response = await TransliterationProjectApi.createProject(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data){
      toastr[action.data.success ? "success" : "error"](action.data.message);
    }
    newState.loading = false;
    return newState;
  }	
);

reducerFactory.addAction(FETCH_PROJECTS.type, FETCH_PROJECTS.name,
	async(data)=>{
    reducerFactory.action(PROJECT_LOADING.name, true);
    const response = await TransliterationProjectApi.fetchProjects(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.projects = action.data.data.projects;
      newState.count = action.data.data.count;
    }
    newState.loading = false;
    return newState;
  }	
);

reducerFactory.addAction(FETCH_ALL_PROJECTS.type, FETCH_ALL_PROJECTS.name,
	async()=>{
    const response = await TransliterationProjectApi.fetchAllProjects();
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.allProjects = action.data.data;
    }
    return newState;
  }	
);

reducerFactory.addAction(SINGLE_PROJECT.type, SINGLE_PROJECT.name,
	async(data)=>{
    reducerFactory.action(PROJECT_PARAMS_CHANGE_WITHOUT_ASYNC.name, [{
      key: "singleProjectLoading", value: true
    }]);
    const response = await TransliterationProjectApi.fetchOneProject(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.oneProject = action.data.data;
      newState.oneProjectId = action.data.data.projectId;
    }
    newState.singleProjectLoading = false;
    return newState;
  }	
);

reducerFactory.addAction(UPDATE_PROJECT.type, UPDATE_PROJECT.name,
	async(data)=>{
    const response = await TransliterationProjectApi.updateProject(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    return newState;
  }	
);

reducerFactory.addAction(DELETE_PROJECT.type, DELETE_PROJECT.name,
	async(data)=>{
    reducerFactory.action(PROJECT_LOADING.name, true);
    const response = await TransliterationProjectApi.deleteProject(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data){
      toastr[action.data.success ? "warning" : "error"](action.data.message);
    }
    newState.loading = false;
    return newState;
  }	
);


export default reducerFactory;

