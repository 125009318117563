import _ from "lodash";

import {
	transcriptionFileState,
	reducerName,
	TRANSCRIBE_FILE,
	GET_TRANSCRIPTION_LIST,
	DELETE_TRANSCRIPTION_FILE,

	IS_LOADING,
	IS_FETCHING_LIST,
} from "../../states/transcription/transcription-file-transcription-state";
import * as TranscriptionFileApi from "../../apis/transcription/transcription-file-api";
import ReducerFactory from "../../../utils/reducer-factory";

const initialState = { ...transcriptionFileState };
const reducerFactory = new ReducerFactory(reducerName, initialState);

reducerFactory.addAction(IS_LOADING.type, IS_LOADING.name,
	status => status, (state, action) => {
		const newState = _.cloneDeep(state);
		newState.loading = action.data;
		return newState;
	}
);

reducerFactory.addAction(IS_FETCHING_LIST.type, IS_FETCHING_LIST.name,
	status => status, (state, action) => {
		const newState = _.cloneDeep(state);
		newState.isFetchingList = action.data;
		return newState;
	}
);

reducerFactory.addAction(TRANSCRIBE_FILE.type, TRANSCRIBE_FILE.name,
	async data =>{
		reducerFactory.action(IS_LOADING.name, true);
		const response = await TranscriptionFileApi.transcribeFile(data);
		return response.data;
	}, (state, action) =>{
		const newState = _.cloneDeep(state);
		newState.loading = false;
		return newState;
	}
);

reducerFactory.addAction(GET_TRANSCRIPTION_LIST.type, GET_TRANSCRIPTION_LIST.name,
	async data => {
		if(data.loadFlag){
			reducerFactory.action(IS_FETCHING_LIST.name, true);
		}
		delete data.loadFlag;		
		const response = await TranscriptionFileApi.transcriptionList(data);
		return response.data;
	}, (state, action) => {
		const newState = _.cloneDeep(state);
		if (action && action.data && action.data.success) {
			newState.uploadedFileDataList = action.data.data.fileListData;
			newState.totalFilesUploaded = action.data.data.totalFileCount;
			newState.totalFileSize = action.data.data.totalFileSize;
		} else {
			newState.uploadedFileDataList = [];
			newState.totalFilesUploaded = 0;
			newState.totalFileSize = 0;
		}
		newState.isFetchingList = false;
		return newState;
	}
);

reducerFactory.addAction(DELETE_TRANSCRIPTION_FILE.type, DELETE_TRANSCRIPTION_FILE.name,
	async (data) => {
		reducerFactory.action(IS_FETCHING_LIST.name, true);
		const response = await TranscriptionFileApi.transcriptionDelete(data);
		return response.data;
	}, (state, action) => {
		const newState = _.cloneDeep(state);
		return newState;
	}
);


export default reducerFactory;