import { apiMethod } from "./index";
import { HTTP_METHOD } from "../../constants/common-constant";
import { CREATE_PAYMENT_INTENT, ATTACH_PAYMENT_TO_USER, FETCH_ALL_PAYMENT_METHODS, FETCH_USER_BILLABLE_PARAMS, FETCH_INVOICE_BREAKDOWN, SET_PARAMETER_LIMIT,
FETCH_USER_INVOICES, FETCH_ALL_BILLABLE_PARAMS
} from "../endpoints/common-endpoint";

const { GET, POST } = HTTP_METHOD;

export const createPaymentIntent = () => apiMethod(POST, CREATE_PAYMENT_INTENT);

export const attachPaymentToUser = data => apiMethod(POST, ATTACH_PAYMENT_TO_USER, data);

export const fetchPaymentMethods = () => apiMethod(GET, FETCH_ALL_PAYMENT_METHODS);

export const fetchUserBillableParams = data => apiMethod(POST, FETCH_USER_BILLABLE_PARAMS, data);

export const fetchAllBillableParams = data => apiMethod(POST, FETCH_ALL_BILLABLE_PARAMS, data);

export const fetchInvoiceBreakdown = () => apiMethod(GET, FETCH_INVOICE_BREAKDOWN);

export const setParameterLimit = data => apiMethod(POST, SET_PARAMETER_LIMIT, data);

export const fetchUserInvoices = data => apiMethod(POST, FETCH_USER_INVOICES, data);