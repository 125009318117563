import { apiMethod } from "./index";
import { HTTP_METHOD } from "../../constants/common-constant";
import { AUTH_CHECK, AUTH_FORGOT_PASSWORD_LINK, AUTH_LOGIN, AUTH_RESET_PASSWORD, AUTH_SIGNUP, AUTH_VERIFY_EMAIL, 
	AUTH_VERIFY_RESET_PASSWORD_TOKEN, AUTH_USER_LICENSE, AUTH_UPDATE_PROFILE, AUTH_USER_CREDIT, ADD_WEBHOOK,
	UPDATE_WEBHOOK, GET_WEBHOOK_LIST, DELETE_WEBHOOK
} from "../endpoints/common-endpoint";
import { getAccessToken } from "../../helpers/common-helper";

const { GET, POST, PUT, DELETE } = HTTP_METHOD;

export const checkAuth = () => {
	const authToken = getAccessToken();
	if (authToken && authToken !== "null") {
		return apiMethod(GET, AUTH_CHECK);
	}
	return null;
}

export const fetchUserCredit = () => {
	const authToken = getAccessToken();
	if (authToken && authToken !== "null") {
		return apiMethod(GET, AUTH_USER_CREDIT);
	}
	return null;
}

export const login = data => apiMethod(POST, AUTH_LOGIN, data);

export const signup = data => apiMethod(POST, AUTH_SIGNUP, data);

export const confirmEmail = data => apiMethod(POST, AUTH_VERIFY_EMAIL, data);

export const verifyPasswordToken = data => apiMethod(POST, AUTH_VERIFY_RESET_PASSWORD_TOKEN, data);

export const forgotPasswordLink = data => apiMethod(POST, AUTH_FORGOT_PASSWORD_LINK, data);

export const resetPassword = data => apiMethod(POST, AUTH_RESET_PASSWORD, data);

export const getUserLicenses = () => apiMethod(GET, AUTH_USER_LICENSE);

export const updateUserProfile = data => apiMethod(PUT, AUTH_UPDATE_PROFILE, data);

export const addWebhook = data => apiMethod(POST, ADD_WEBHOOK, data);

export const fetchWebhooks = () => apiMethod(GET, GET_WEBHOOK_LIST);

export const updateWebhooks = data => apiMethod(PUT, UPDATE_WEBHOOK, data);

export const deleteWebhook = data => apiMethod(DELETE, DELETE_WEBHOOK, {data});