import _ from "lodash";

import {reducerName, translationState, TEST_TRANSLATION, TRANSLATION_LOADING, TRANSLATION_PARAMS_CHANGE } from "../../states/translation/translation-state";
import * as TranslationApi from "../../apis/translation/translation-api";
import ReducerFactory from "../../../utils/reducer-factory";

const initialState = {...translationState};
const reducerFactory = new ReducerFactory(reducerName, initialState);

reducerFactory.addAction(TRANSLATION_LOADING.type, TRANSLATION_LOADING.name,
  status => status, (state,action)=>{
		const newState = _.cloneDeep(state);
    newState.loading = action.data;
    return newState;
  }
);

reducerFactory.addAction(TRANSLATION_PARAMS_CHANGE.type, TRANSLATION_PARAMS_CHANGE.name,
  data=> data,(state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.length > 0){
      action.data.forEach(d=>{
        const { key, value } = d;
        newState[key] = value;
      });
    }  
    return newState;
  } 
);

reducerFactory.addAction(TEST_TRANSLATION.type, TEST_TRANSLATION.name,
  async data => {
    reducerFactory.action(TRANSLATION_LOADING.name, true);
    const response = await TranslationApi.testTranslation(data);
    return response;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action?.data?.data?.success){
      newState.testResult = action.data.data.data;
    }
    newState.loading = false;
    return newState;
  }
);

export default reducerFactory;