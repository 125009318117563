import _ from "lodash";
import toastr from "toastr";
import { 
  reducerName, nerEntityState, TEST_SYSTEM_ENTITY, ENTITY_LOADING, ENTITY_PARAMS_CHANGE, SEARCH_ENTITIES_IN_EXAMPLE, CREATE_ENTITY, CREATE_ENTITY_LOADING,
  FETCH_ENTITIES, SINGLE_ENTITY, UPDATE_EXAMPLES_IN_ENTITY, DELETE_ENTITY
} from "../../states/ner/ner-entity-state";
import * as NerEntityApi from "../../apis/ner/ner-entity-api";
import ReducerFactory from "../../../utils/reducer-factory";

const initialState = {...nerEntityState};
const reducerFactory = new ReducerFactory(reducerName, initialState);

reducerFactory.addAction(ENTITY_LOADING.type, ENTITY_LOADING.name,
  status => status, (state,action)=>{
		const newState = _.cloneDeep(state);
    newState.loading = action.data;
    return newState;
  }
);

reducerFactory.addAction(ENTITY_PARAMS_CHANGE.type, ENTITY_PARAMS_CHANGE.name,
  async data=> data,(state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.length > 0){
      action.data.forEach(d=>{
        const { key, value } = d;
        newState[key] = value;
      });
    }  
    return newState;
  } 
);

reducerFactory.addAction(TEST_SYSTEM_ENTITY.type, TEST_SYSTEM_ENTITY.name,
  async data => {
    reducerFactory.action(ENTITY_LOADING.name, true);
    const response = await NerEntityApi.testSystemEntity(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.testResult = action.data.data.entities;
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction(SEARCH_ENTITIES_IN_EXAMPLE.type, SEARCH_ENTITIES_IN_EXAMPLE.name,
	async(data)=>{
    const response = await NerEntityApi.fetchSearchedEntities(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.searchedEntities = action.data.data.searchedEntities;
      newState.synonyms = action.data.data.synonyms;
    }
    return newState;
  }	
);

reducerFactory.addAction(CREATE_ENTITY.type, CREATE_ENTITY.name,
	async(data)=>{
    reducerFactory.action(CREATE_ENTITY_LOADING.name, true);
    const response = await NerEntityApi.createEntity(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data){
      toastr[action.data.success ? "success" : "error"](action.data.message);
    }
    newState.createEntityLoading = false;
    return newState;
  }	
);

reducerFactory.addAction(FETCH_ENTITIES.type, FETCH_ENTITIES.name,
	async(data)=>{
    if(data.loadFlag){
      reducerFactory.action(ENTITY_LOADING.name, true);
    }
    delete data.loadFlag;
    const response = await NerEntityApi.fetchEntities(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.entities = action.data.data.entities;
      newState.count = action.data.data.count;
    }
    newState.loading = false;
    return newState;
  }	
);

reducerFactory.addAction(SINGLE_ENTITY.type, SINGLE_ENTITY.name,
	async(data)=>{
    const response = await NerEntityApi.fetchOneEntity(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.oneEntity = action.data.data;
    }
    return newState;
  }	
);

reducerFactory.addAction(UPDATE_EXAMPLES_IN_ENTITY.type, UPDATE_EXAMPLES_IN_ENTITY.name,
	async(data)=>{
    reducerFactory.action(CREATE_ENTITY_LOADING.name, true);
    const response = await NerEntityApi.updateExamplesInEntity(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data){
      if(action.data.success){
        const { entityId, examples } = action.data.data;
        const index = newState.entities.findIndex(en=>en.entityId === entityId);
        if(index !== -1){
          newState.entities[index].examples = examples;
        }
      }else{
        toastr.error(action.data.message);
      }
    }
    newState.createEntityLoading = false;
    return newState;
  }	
);

reducerFactory.addAction(DELETE_ENTITY.type, DELETE_ENTITY.name,
	async(data)=>{
    reducerFactory.action(ENTITY_LOADING.name, true);
    const response = await NerEntityApi.deleteEntity(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data){
      toastr[action.data.success ? "warning" : "error"](action.data.message);
    }
    newState.loading = false;
    return newState;
  }	
);

export default reducerFactory;