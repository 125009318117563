export const reducerName = "transaction";
export const transactionState = {
  transactionsLoading: false,
  count: 0,
  transactions: [],
  error: null
}

export const TRANSACTION_PARAMS_CHANGE = {
  type: "TRANSACTION_PARAMS_CHANGE",
  name: "transactionParamsChange"
}

export const FETCH_USER_TRANSACTIONS = {
  type: "FETCH_USER_TRANSACTIONS",
  name: "fetchUserTransactions"
}