import toastr from "toastr";
import _ from "lodash";

import { nluProjectState, reducerName, PROJECT_LOADING, CREATE_PROJECT, FETCH_PROJECTS, SINGLE_PROJECT, UPDATE_PROJECT, DELETE_PROJECT, PROJECT_PARAMS_CHANGE,
  SORT_PROJECTS, ADD_SYNONYM_TO_PROJECT, REMOVE_SYNONYM_FROM_PROJECT, FETCH_PROJECT_SYNONYMS, PROJECT_PARAMS_CHANGE_WITHOUT_ASYNC, FETCH_ALL_PROJECTS,
} from "../../states/nlu/nlu-project-state";
import * as NluProjectApi from "../../apis/nlu/nlu-project-api";
import ReducerFactory from "../../../utils/reducer-factory";

const initialState = {...nluProjectState};
const reducerFactory = new ReducerFactory(reducerName, initialState);

reducerFactory.addAction(PROJECT_LOADING.type, PROJECT_LOADING.name,
  status => status, (state,action)=>{
		const newState = _.cloneDeep(state);
    newState.loading = action.data;
    return newState;
  }
);

reducerFactory.addAction(PROJECT_PARAMS_CHANGE.type, PROJECT_PARAMS_CHANGE.name,
  async data=> data,(state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.length > 0){
      action.data.forEach(d=>{
        const { key, value } = d;
        newState[key] = value;
      })
    }    
    return newState;
  } 
);

reducerFactory.addAction(PROJECT_PARAMS_CHANGE_WITHOUT_ASYNC.type, PROJECT_PARAMS_CHANGE_WITHOUT_ASYNC.name,
  data=> data,(state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.length > 0){
      action.data.forEach(d=>{
        const { key, value } = d;
        newState[key] = value;
      })
    }    
    return newState;
  } 
);

reducerFactory.addAction(SORT_PROJECTS.type, SORT_PROJECTS.name,
  async data=> data, (state,action)=>{
    const {sortBy} = action.data;
    const newState = _.cloneDeep(state);
    newState.sortBy = sortBy;
    newState["sortByKeys"][sortBy.key] = sortBy.value;
    return newState; 
  }
)

reducerFactory.addAction(CREATE_PROJECT.type, CREATE_PROJECT.name,
	async(data)=>{
    reducerFactory.action(PROJECT_LOADING.name, true);
    const response = await NluProjectApi.createProject(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data){
      toastr[action.data.success ? "success" : "error"](action.data.message);
    }
    newState.loading = false;
    return newState;
  }	
);

reducerFactory.addAction(FETCH_PROJECTS.type, FETCH_PROJECTS.name,
	async(data)=>{
    reducerFactory.action(PROJECT_LOADING.name, true);
    const response = await NluProjectApi.fetchProjects(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.projects = action.data.data.projects;
      newState.count = action.data.data.count;
    }
    newState.loading = false;
    return newState;
  }	
);

reducerFactory.addAction(FETCH_ALL_PROJECTS.type, FETCH_ALL_PROJECTS.name,
	async()=>{
    const response = await NluProjectApi.fetchAllProjects();
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.allProjects = action.data.data;
    }
    return newState;
  }	
);

reducerFactory.addAction(SINGLE_PROJECT.type, SINGLE_PROJECT.name,
	async(data)=>{
    reducerFactory.action(PROJECT_PARAMS_CHANGE_WITHOUT_ASYNC.name, [{
      key: "singleProjectLoading", value: true
    }]);
    const response = await NluProjectApi.fetchOneProject(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.oneProject = action.data.data;
      newState.oneProjectId = action.data.data.projectId;
    }
    newState.singleProjectLoading = false;
    return newState;
  }	
);

reducerFactory.addAction(UPDATE_PROJECT.type, UPDATE_PROJECT.name,
	async(data)=>{
    const response = await NluProjectApi.updateProject(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    return newState;
  }	
);

reducerFactory.addAction(DELETE_PROJECT.type, DELETE_PROJECT.name,
	async(data)=>{
    reducerFactory.action(PROJECT_LOADING.name, true);
    const response = await NluProjectApi.deleteProject(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data){
      toastr[action.data.success ? "warning" : "error"](action.data.message);
    }
    newState.loading = false;
    return newState;
  }	
);

reducerFactory.addAction(ADD_SYNONYM_TO_PROJECT.type, ADD_SYNONYM_TO_PROJECT.name,
	async(data)=>{
    const response = await NluProjectApi.addSynonymToProject(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.projectSynonyms.unshift(action.data.data);
      const newSynonymList = newState.projectSynonyms.splice(0,newState.pageSize);
      newState.projectSynonyms = newSynonymList;
      newState.projectSynonymCount++;
    }
    newState.loading = false;
    return newState;
  }	
);

reducerFactory.addAction(FETCH_PROJECT_SYNONYMS.type, FETCH_PROJECT_SYNONYMS.name,
	async(data)=>{
    if(data.loadFlag){
      reducerFactory.action(PROJECT_PARAMS_CHANGE, [{key: "projectSynonymLoading", value: true }]);
    }
    delete data.loadFlag;
    const response = await NluProjectApi.fetchProjectSynonyms(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.projectSynonyms = action.data.data.synonyms;
      newState.projectSynonymCount = action.data.data.count;
    }
    newState.loading = false;
    return newState;
  }	
);

reducerFactory.addAction(REMOVE_SYNONYM_FROM_PROJECT.type, REMOVE_SYNONYM_FROM_PROJECT.name,
	async(data)=>{
    const response = await NluProjectApi.removeSynonymFromProject(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    newState.loading = false;
    return newState;
  }	
);


export default reducerFactory;

