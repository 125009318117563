export const reducerName = "usage";
export const usageState = {
  loading: false,
  usage: {},
  appUsage:{},
  error: null
}

export const USAGE_LOADING = {
  type: "USAGE_LOADING",
  name: "usageLoading"
}

export const INSTALL_APP = {
  type: "INSTALL_APP",
  name: "installApp"
}