import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import NSBlackIcon from "../../../../assets/images/icon.svg";
import NSBlackLogo from "../../../../assets/images/logo.svg";
import NSWhiteIcon from "../../../../assets/images/w_icon.svg";
import NSWhiteLogo from "../../../../assets/images/w_logo.svg";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LIGHT,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR
} from "../../../../constants/theme-setting-constants";
import * as SettingsState from "../../../../redux/states/settings-state";

class SidebarLogo extends React.Component {
  render() {
    const { onSidebarCollapsed, windowWidth, themeType, navStyle, sidebarCollapsed } = this.props;
    let navigationStyle = navStyle;
    if (windowWidth < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
      navigationStyle = NAV_STYLE_DRAWER;
    }
    return (
      <div className="ns-layout-sider-header">
        {(navigationStyle === NAV_STYLE_FIXED || navigationStyle === NAV_STYLE_MINI_SIDEBAR) ? (
          <div className="ns-linebar">
            <i
              className={`ns-icon-btn icon ${sidebarCollapsed ? "icon-menu" : "icon-arrow-left"} ${themeType !== THEME_TYPE_LIGHT ? 'ns-text-white' : ''}`}
              onClick={onSidebarCollapsed}
            />
          </div>
        ) : null}
        <Link to="/" className="ns-site-logo">
          {navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR &&  windowWidth >= TAB_SIZE ? (
            themeType === THEME_TYPE_LIGHT ?
            <img alt="lo" src={NSBlackIcon}/>: <img alt="lo" src={NSWhiteIcon}/>
          ):(
            themeType === THEME_TYPE_LIGHT ?
            <img alt="logo1" src={NSBlackLogo}/>
            :<img alt="logo1" src={NSWhiteLogo}/>
          )}
        </Link>
      </div>
    )
  }
}

SidebarLogo.propTypes = {
  windowWidth: PropTypes.number.isRequired,
  themeType: PropTypes.string.isRequired,
  navStyle: PropTypes.string.isRequired,
  sidebarCollapsed: PropTypes.bool.isRequired,
  onSidebarCollapsed: PropTypes.func.isRequired
}

const SidebarLogoContainer = connect(
  state => ({
    windowWidth: state.get(SettingsState.reducerName).windowWidth,
    themeType: state.get(SettingsState.reducerName).themeType,
    navStyle: state.get(SettingsState.reducerName).navStyle
  }),
  dispatch => ({

  })
)(SidebarLogo);

export default SidebarLogoContainer