import _ from "lodash";
import toastr from "toastr";

import { paymentState, reducerName, PAYMENT_PARAMS_CHANGE, CREATE_PAYMENT_INTENT, ATTACH_PAYMENT_WITH_USER, FETCH_USER_PAYMENT_METHODS,
FETCH_USER_BILLABLE_PARAMETERS, FETCH_INVOICE_BREAKDOWN, SET_PARAMS_LIMIT, FETCH_INVOICES,
FETCH_ALL_BILLABLE_PARAMETERS
} from "../states/payment-state";
import * as PaymentApi from "../apis/payment-api";
import ReducerFactory from "../../utils/reducer-factory";


const initialState = {...paymentState};
const reducerFactory = new ReducerFactory(reducerName, initialState);

reducerFactory.addAction(PAYMENT_PARAMS_CHANGE.type, PAYMENT_PARAMS_CHANGE.name,
  async data=> data,(state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.length > 0){
      action.data.forEach(d=>{
        const { key, value } = d;
        newState[key] = value;
      });
    }  
    return newState;
  } 
);

reducerFactory.addAction(CREATE_PAYMENT_INTENT.type, CREATE_PAYMENT_INTENT.name,
  async()=>{
    reducerFactory.action(PAYMENT_PARAMS_CHANGE.name, [{key: "createPaymentIntentLoading", value: true}]);
    const response = await PaymentApi.createPaymentIntent();
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data){
      newState.clientSecret = action.data.data || "";
    }
    newState.createPaymentIntentLoading = false;
    return newState;
  }
);

reducerFactory.addAction(ATTACH_PAYMENT_WITH_USER.type, ATTACH_PAYMENT_WITH_USER.name,
  async(data)=>{
    const response = await PaymentApi.attachPaymentToUser(data);
    return response.data;
  }, (state,action)=>{
    if(action && action.data){
      toastr[action.data.success ? "success": "error"](action.data.message);
    }
    return state;
  }
);

reducerFactory.addAction(FETCH_USER_PAYMENT_METHODS.type, FETCH_USER_PAYMENT_METHODS.name,
  async()=>{
    reducerFactory.action(PAYMENT_PARAMS_CHANGE.name, [{key: "paymentMethodsLoading", value: true}]);
    const response = await PaymentApi.fetchPaymentMethods();
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.paymentMethods = action.data.data;
    }else{
      newState.paymentMethods = []
    }
    newState.paymentMethodsLoading = false;
    return newState;
  }
);

reducerFactory.addAction(FETCH_USER_BILLABLE_PARAMETERS.type, FETCH_USER_BILLABLE_PARAMETERS.name,
  async(data)=>{
    reducerFactory.action(PAYMENT_PARAMS_CHANGE.name, [{key: "paymentBillableParamsLoading", value: true}]);
    const response = await PaymentApi.fetchUserBillableParams(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.billableParams = action.data.data;
    }else{
      newState.billableParams= {payg: [], pfwyn: []}
    }
    newState.paymentBillableParamsLoading = false;
    return newState;
  }
);

reducerFactory.addAction(FETCH_ALL_BILLABLE_PARAMETERS.type, FETCH_ALL_BILLABLE_PARAMETERS.name,
  async(data)=>{
    reducerFactory.action(PAYMENT_PARAMS_CHANGE.name, [{key: "allBillableParamsLoading", value: true}]);
    const response = await PaymentApi.fetchAllBillableParams(data);
    return response.data;
  }, (state, action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.billableAllParams = action.data.data;
    }else{
      newState.billableAllParams= {payg: [], pfwyn: []}
    }
    newState.allBillableParamsLoading = false;
    return newState;
  }
);

reducerFactory.addAction(FETCH_INVOICE_BREAKDOWN.type, FETCH_INVOICE_BREAKDOWN.name,
  async(data)=>{
    reducerFactory.action(PAYMENT_PARAMS_CHANGE.name, [{key: "invoiceBreakdownLoading", value: true}]);
    const response = await PaymentApi.fetchInvoiceBreakdown(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.invoiceBreakdown = action.data.data;
    }
    newState.invoiceBreakdownLoading = false;
    return newState;
  }
);

reducerFactory.addAction(SET_PARAMS_LIMIT.type, SET_PARAMS_LIMIT.name,
  async(data)=>{
    const response = await PaymentApi.setParameterLimit(data);
    return response.data;
  }, (state,action)=>{
    return state;
  }
);

reducerFactory.addAction(FETCH_INVOICES.type, FETCH_INVOICES.name,
  async(data)=>{
    reducerFactory.action(PAYMENT_PARAMS_CHANGE.name, [{key: "invoiceLoading", value: true}]);
    const response = await PaymentApi.fetchUserInvoices(data);
    return response.data;
  }, (state,action)=>{
    const newState = _.cloneDeep(state);
    if(action && action.data && action.data.success){
      newState.invoices = action.data.data;
    }
    newState.invoiceLoading = false;
    return newState;
  }
);
export default reducerFactory;