// Auth endpoints
export const AUTH_CHECK = "auth/check";
export const AUTH_USER_CREDIT = "auth/credit";
export const AUTH_LOGIN = "auth/login";
export const AUTH_SIGNUP = "auth/signup";
export const AUTH_VERIFY_EMAIL = "auth/verify/email";
export const AUTH_VERIFY_RESET_PASSWORD_TOKEN = "auth/verify/password/token";
export const AUTH_FORGOT_PASSWORD_LINK = "auth/forgot/password";
export const AUTH_RESET_PASSWORD = "auth/reset/password";
export const AUTH_USER_LICENSE = "auth/license";
export const AUTH_UPDATE_PROFILE = "auth/update/profile";

//Dashboard endpoints
export const MAIN_DASHBOARD = "main/dashboard";
export const MAIN_DASHBOARD_API_COUNT = "main/dashboard/api-count";
export const MAIN_DASHOBOARD_GENERIC_STATS = "main/dashboard/generic-stats";
export const MAIN_DASHOBOARD_APP_SPECIFIC_STATS = "main/dashboard/app-specific-stats";

//Usage endpoints
export const INSTALL_APP = "app/install";

//Payment endpoints
export const CREATE_PAYMENT_INTENT = "payment/intent/create";
export const ATTACH_PAYMENT_TO_USER = "payment/attach/user";
export const FETCH_ALL_PAYMENT_METHODS = "payment/list/user";
export const FETCH_USER_BILLABLE_PARAMS = "payment/list/billable-params";
export const FETCH_ALL_BILLABLE_PARAMS = "payment/fetch/all/params";
export const FETCH_INVOICE_BREAKDOWN = "payment/invoice/breakdown";
export const SET_PARAMETER_LIMIT = "payment/parameter/set";
export const FETCH_USER_INVOICES = "payment/invoice/user";


//Transaction endpoints
export const FETCH_USER_TRANSACTIONS = "transaction/list";

//Webhook enpoints
export const ADD_WEBHOOK = "webhook/add";
export const GET_WEBHOOK_LIST = "webhook/list";
export const UPDATE_WEBHOOK = "webhook/update";
export const DELETE_WEBHOOK = "webhook/delete";

//File Upload endpoints
export const FILE_UPLOAD = "file/upload";
