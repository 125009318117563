export const reducerName = "nerDashboard";
export const nerDashboardState = {
  nerDashboardGarphLoading: false,
  nerDashboardCountsLoading: false,
  nerDashboardDeployedModelsLoading: false,
  nerStatsLoading: false,
  nerExamplesPerProjectLoading: false,
  nerCountData:{},
  nerAppUsage: {},
  count: 0,
  apiCalls: [],
  deployedModels: [],
  nerStats: [],
  examplesPerProjectHistogram: []
}

export const NER_DASHBOARD_PARAMS_CHANGE = {
  type: "NER_DASHBOARD_PARAMS_CHANGE",
  name: "nerDashboardParamsChange"
}

export const NER_DASHBOARD_DATA_CHANGE = {
  type: "NER_DASHBOARD_DATA_CHANGE",
  name: "nerDashboardParamsChange"
}

export const GET_NER_DASHBOARD = {
  type: "GET_NER_DASHBOARD",
  name: "getNerDashboard"
}

export const GET_NER_DASHBOARD_API_COUNT = {
  type: "GET_NER_DASHBOARD_API_COUNT",
  name: "getNerDashboardApiCount"
}

export const GET_NER_DASHBOARD_DEPLOYED_MODELS = {
  type: "GET_NER_DASHBOARD_DEPLOYED_MODELS",
  name: "getNerDashboardDeployedModels"
}

export const GET_NER_DASHBOARD_STATS = {
  type: "GET_NER_DASHBOARD_STATS",
  name: "getNerDashboardStats"
}

export const GET_NER_DASHBOARD_EXAMPLES_PER_DATASET_HISTOGRAM = {
  type: "GET_NER_DASHBOARD_EXAMPLES_PER_DATASET_HISTOGRAM",
  name: "getExamplesPerProjectHistogram"
}