
import { TRANSLATION, TRANSLATION_NAME } from "../constants/common-constant";

export const TRANSLATION_ROUTE = `/platform/${TRANSLATION}`;
export const TRANSLATION_DASHBOARD = `/platform/${TRANSLATION}/dashboard`;

export const translationAppRoutes = [
  {
    key: TRANSLATION_DASHBOARD,
    name: TRANSLATION_NAME,
    path: TRANSLATION_DASHBOARD,
    icon: "icon icon-apps ns-mr-2 ns-vertical-align-middle",
    i18Message: "sidebar.dashboard"
  }
];
