export const reducerName = "payment";
export const paymentState = {
  createPaymentIntentLoading: false,
  clientSecret: "",
  paymentMethodsLoading: false,
  paymentBillableParamsLoading: false,
  allBillableParamsLoading: false,
  invoiceBreakdownLoading: false,
  invoiceLoading: false,
  paymentMethods: [],
  invoiceBreakdown: {},
  billableParams: {payg: [], pfwyn: []},
  billableAllParams: {payg: [], pfwyn: []},
  invoices: [],
  error: null
}

export const PAYMENT_PARAMS_CHANGE = {
  type: "PAYMENT_PARAMS_CHANGE",
  name: "paymentParamsChange"
}

export const CREATE_PAYMENT_INTENT = {
  type: "CREATE_PAYMENT_INTENT",
  name: "createPaymentIntent"
}

export const ATTACH_PAYMENT_WITH_USER = {
  type: "ATTACH_PAYMENT_WITH_USER",
  name: "attachPaymentWithUser"
}

export const FETCH_USER_PAYMENT_METHODS = {
  type: "FETCH_USER_PAYMENT_METHODS",
  name: "fetchUserPaymentMthods"
}

export const FETCH_USER_BILLABLE_PARAMETERS = {
  type: "FETCH_USER_BILLABLE_PARAMETERS",
  name: "fetchBillableParameters"
}

export const FETCH_ALL_BILLABLE_PARAMETERS = {
  type: "FETCH_ALL_BILLABLE_PARAMETERS",
  name: "fetchAllBillableParameters"
}

export const FETCH_INVOICE_BREAKDOWN = {
  type: "FETCH_INVOICE_BREAKDOWN",
  name: "fetchInvoiceBreakdown"
}

export const SET_PARAMS_LIMIT = {
  type: "SET_PARAMS_LIMIT",
  name: "setParamLimit"
}

export const FETCH_INVOICES = {
  type: "FETCH_INVOICES",
  name: "fetchInvoices"
}