export const reducerName = "transliterationModel";
export const transliterationModelState = {
  loading: false,
  modelListLoading: false,
  singleModelLoading: false,
  deleteModelId: [],
  deployModelId: [],
  pageNumber: 1,
  pageSize: 15,
  search: "",
  count: 0,
  models: [],
  lastModel: {},
  trainingStatus: [],
  deployStatus: "All",
  parsedResponse: {},
  feedbackData:[],
  feedbackDataCount: 0,
  error: null
}

export const MODEL_LOADING = {
  type: "TRANSLITERATION_MODEL_LOADING",
  name: "transliterationmodelLoading"
};

export const MODEL_PARAMS_CHANGE = {
  type: "TRANSLITERATION_MODEL_PARAMS_CHANGE",
  name: "transliterationmodelParamsChange"
};

export const MODEL_ACTION_PARAMS_CHANGE = {
  type: "TRANSLITERATION_MODEL_ACTION_PARAMS_CHANGE",
  name: "transliterationmodelActionParamsChange"
}

export const MODEL_DATA_CHANGE = {
  type: "TRANSLITERATION_MODEL_DATA_CHANGE",
  name: "transliterationmodelDataChange"
}

export const TRAIN_MODEL = {
  type: "TRANSLITERATION_TRAIN_MODEL",
  name: "transliterationtrainModel"
};

export const FETCH_MODELS = {
  type: "TRANSLITERATION_FETCH_MODELS",
  name: "transliterationfetchModels"
};

export const SINGLE_MODEL = {
  type: "TRANSLITERATION_SINGLE_MODEL",
  name: "transliterationsingleModel"
};

export const UPDATE_MODEL = {
  type: "TRANSLITERATION_UPDATE_MODEL",
  name: "transliterationupdateModel"
};

export const DELETE_MODEL={
  type: "TRANSLITERATION_DELETE_MODEL",
  name: "transliterationdeleteModel"
};

export const DEPLOY_MODEL={
  type: "TRANSLITERATION_DEPLOY_MODEL",
  name: "transliterationdeployModel"
};

export const PARSE_MODEL={
  type: "TRANSLITERATION_PARSE_MODEL",
  name: "transliterationparseModel"
};

export const GET_FEEDBACK_DATA={
  type: "TRANSLITERATION_GET_FEEDBACK_DATA",
  name: "transliterationgetFeedbackData"
}